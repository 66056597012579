/* eslint-disable @typescript-eslint/no-empty-function */
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import * as SentryVue from '@sentry/vue'
import equal from 'fast-deep-equal'
import { parse, stringify } from '@/helpers/uri'
import { router } from '@/plugins/router'
import { config } from '@/services/config'
import { store } from '@/store/vuex/index'

function clean(data, value) {
  if (!data) {
    return null
  }
  if (typeof data !== 'object') {
    return null
  }
  for (const p in data) {
    if (!data[p]) {
      continue
    }
    if (typeof data[p] === 'object') {
      try {
        data[p] = clean(data[p], value)
      } catch (error) {
        console.error(error)
      }
      continue
    }
    if (equal(data[p], value)) {
      delete data[p]
    }
    if (typeof data[p] === 'string' && equal(data[p].toLowerCase(), value)) {
      delete data[p]
    }
    if (typeof data[p] === 'string') {
      if (data[p].toLowerCase().includes(value)) {
        try {
          data[p] = data[p].toLowerCase().replaceAll(value, '[redacted]')
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
  return data
}

export function initSentryPlugin(app) {
  if (config('sentry.enabled')) {
    SentryVue.init({
      app,
      dsn: config('sentry.dns'),
      logErrors: true,
      environment: config('app.environment').toUpperCase(), // pour être iso avec le back
      integrations: [
        new BrowserTracing({
          routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
          // tracingOrigins: [config('app.hippo'), config('app.opossum')]
        }),
      ],
      beforeSend: (event, hint) => {
        if (hint?.originalException?.message?.startsWith('Redirected when going from')) {
          return false
        }
        if (hint?.originalException?.name === 'NavigationDuplicated') {
          return false
        }
        if (hint?.originalException === 'cancel') {
          return false
        }
        if (!store) {
          return false
        }
        const user = store.getters['user/user']
        if (user) {
          // clean
          const sites = store.getters['sites/sites']
          const sources = store.getters['sources/allSources']
          ;[
            user.email_address,
            user.first_name,
            user.last_name,
            user.city,
            user.address,
            ...sites.flatMap((site) => [site.address, site.lat, site.lng, site.name, site.siret]),
            ...sources.flatMap((source) => [source.name]),
          ]
            .filter((v) => v)
            .map((v) => (typeof v === 'string' ? v.toLowerCase() : v))
            .flatMap((v) => [v, encodeURIComponent(`${v}`)])
            .forEach((value) => {
              event = clean(event, value)
            })
        } else {
          event.breadcrumbs = [] // neeed to be eager
          const index = event.request.url.indexOf('?')
          if (~index) {
            const params = parse(event.request.url)
            delete params.user
            delete params.email
            event.request.url = event.request.url.substring(0, index + 1) + stringify(params)
            if (event.contexts?.trace?.data?.query) {
              delete event.contexts.trace.data.query.user
              delete event.contexts.trace.data.query.email
            }
          }
        }
        if (event.request?.headers?.Referer) {
          if (event.request.headers.Referer.includes('?')) {
            event.request.headers.Referer = event.request.headers.Referer.substring(
              0,
              event.request.headers.Referer.indexOf('?'),
            )
          }
        }
        return event
      },
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config('sentry.rate'),
    })
    Sentry.setContext('screen', {
      width: window.innerWidth + 'px',
      height: window.innerHeight + 'px',
      dpi: window.devicePixelRatio ?? 1,
    })
  }
}

const fake = {
  setContext() {},
  setUser() {},
  configureScope() {},
  captureException() {},
  captureMessage() {},
  withScope() {},
  logException() {},
  setTag() {},
}

export const sentry = config('sentry.enabled') ? Sentry : fake

export function logSentryException(e, label) {
  sentry.withScope(function (scope) {
    // group errors together based on their request and response
    if (label) {
      scope.setFingerprint([label])
    }

    let title
    if (typeof e === 'string') {
      title = e
    } else {
      title = e?.title ?? e?.message ?? 'Error'
    }
    sentry.captureMessage(title, { level: 'error' })
    sentry.captureException(title, e)
  })
}
