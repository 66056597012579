<script setup lang="ts">
import { computed, inject, onUnmounted, ref, watch } from 'vue'
import { kModel, kMultiple, kOptions, strictInject } from './store'

const options = inject(kOptions, ref([]))
const multiple = inject(kMultiple)
const model = strictInject(kModel)

const props = defineProps({
  value: {
    type: [Number, String, Array, Object, Symbol, Boolean],
    default: Symbol(),
  },
})

function select() {
  if (multiple?.value) {
    if (model.value.includes(props.value)) {
      model.value = model.value.filter((v) => v !== props.value)
    } else {
      model.value = [...model.value, props.value]
    }
  } else {
    model.value = [props.value]
  }
}

const isSelected = computed(() => model.value.includes(props.value))

watch(
  props,
  (currentProps: typeof props, oldProps?: typeof props) => {
    options.value = options.value.filter((option) => option !== oldProps?.value).concat(currentProps.value)
  },
  { immediate: true },
)
onUnmounted(() => {
  options.value = options.value.filter((option) => option !== props.value)
})
</script>

<template>
  <li class="u-select-item u-txt-md" :class="{ bold: isSelected }" @click.stop="select()">
    <slot :is-selected="isSelected"></slot>
  </li>
</template>

<style scoped>
.u-select-item:hover {
  background-color: var(--cream);
}
</style>
