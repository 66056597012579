<template>
  <UModal v-bind="$props" @close="$emit('close')" @validation="onImpersonate" width="800px">
    <template #title>Impersonate</template>
    <div class="search">
      <div>
        <UAutoComplete
          @search="onSearchThrottled"
          v-model="user"
          v-model:search="search"
          placeholder="search"
          autofocus
          :list="usersPaginate"
        >
          <template #item="{ value }">
            <p class="name">
              <span v-html="bold(value.name)" />
              <span v-html="bold(value.email)" />
            </p>
            <p v-if="sensor(value)" class="sensors">
              <span v-for="s in sensor(value)" :key="s" class="sensor">{{ s }}</span>
            </p>
            <p class="infos">
              <span v-if="territory(value)" class="territory">{{ territory(value) }}</span>
              <span v-if="type(value)" class="type">{{ type(value) }}</span>
              <span v-if="program(value)" class="programs">{{ program(value) }}</span>
            </p>
            <p class="data">
              <span class="sites">sites:{{ value.sites_nbr }}</span>
              <span class="sources">sources:{{ value.sources_nbr }}</span>
            </p>
            <IconClose v-if="value.count" :size="16" title="remove from history" @click.stop="remove(value)" />
          </template>
        </UAutoComplete>
      </div>
    </div>
  </UModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IconClose from '@/components/icons/IconClose.vue'
import UAutoComplete from '@/components/ui/UAutoComplete.vue'
import UModal from '@/components/ui/UModal.vue'
import { last, unique } from '@/helpers/arrays'
import { throttle } from '@/helpers/time'
import { config } from '@/services/config'

export default {
  name: 'UModalImpersonate',
  components: {
    UModal,
    UAutoComplete,
    IconClose,
  },
  model: {
    event: 'close',
    prop: 'visible',
  },
  emits: ['close'],
  data() {
    return {
      user: null,
      search: null,
      onSearchThrottled: null,
      searchTerritory: null,
      searchProgram: null,
      searchType: null,
    }
  },
  created() {
    this.onSearchThrottled = throttle(this.onSearch.bind(this), 300)
    if (!this.usersPaginate.length) {
      this.loadUsers()
    }
    this.loadTerritories()
    this.loadPrograms()
  },
  computed: {
    ...mapGetters('impersonate', ['users', 'impersonator', 'territories', 'programs', 'types']),
    ...mapGetters('user', ['email']),
    usersPaginate() {
      return this.users
        .slice(0, 7)
        .filter((user) => user.email !== this.email && user.email !== this.impersonator?.email_address)
    },
    searchObject() {
      let search = this.search?.toLowerCase() ?? ''
      let searches = search
        .split(' ')
        .map((e) => e.trim())
        .filter((e) => e)

      let type = this.searchType
      let program = this.searchProgram?.id
      let territory = this.searchTerritory?.id
      let sensor = null
      let email = null

      let matches = searches.filter((search) =>
        ['pro', 'profesionnal', 'private', 'particulier'].includes(search.toLowerCase()),
      )
      if (matches.length) {
        type = last(matches)
        if (['profesionnal', 'pro'].includes(type)) {
          type = 'professional'
        } else if (['private', 'particulier'].includes(type)) {
          type = 'private'
        }
        searches = searches.filter((search) => !matches.includes(search))
      }
      matches = searches.filter((search) => search.includes('@'))
      if (matches.length) {
        email = last(matches)
        searches = searches.filter((search) => !matches.includes(search))
      }
      matches = searches.filter((search) => this.territories.find((t) => t.name.toLowerCase() === search))
      if (matches.length) {
        territory = this.territories.find((p) => p.name.toLowerCase() === last(matches)).id
        searches = searches.filter((search) => !matches.includes(search))
      }
      matches = searches.filter((search) => search === 'france')
      if (matches.length) {
        territory = this.territories.find((t) => t.name.toLowerCase().includes('france'))?.id
        searches = searches.filter((search) => !matches.includes(search))
      }
      matches = searches.filter((search) =>
        ['epicee', 'épicée', 'épicé', 'epice', 'epicée', 'épice'].includes(search.toLowerCase()),
      )
      if (matches.length) {
        sensor = 'ecoco2'
        searches = searches.filter((search) => !matches.includes(search))
      }
      matches = searches.filter((search) => this.programs.find((t) => t.name.toLowerCase() === search))
      if (matches.length) {
        program = this.programs.find((p) => p.name.toLowerCase() === last(matches)).id
        searches = searches.filter((search) => !matches.includes(search))
      }
      matches = searches.filter((search) =>
        ['fludia', 'webdyn', 'archos', 'adict', 'ee_smart', 'enedis', 'd2l', 'gaz', 'linky'].includes(search),
      )
      if (matches.length) {
        sensor = last(matches)
        searches = searches.filter((search) => !matches.includes(search))
        if (sensor === 'linky') {
          sensor = 'enedis'
        } else if (sensor === 'gaz') {
          sensor = 'adict'
        } else if (sensor === 'd2l') {
          sensor = 'ee_smart'
        }
      }

      search = searches.join(' ')
      if (!search) {
        search = null
      }

      return {
        search,
        user_type: type,
        program,
        territory,
        sensor,
        email,
      }
    },
  },
  methods: {
    ...mapMutations('impersonate', ['addHistory', 'removeHistory']),
    ...mapActions('impersonate', ['loadUsers', 'loadPrograms', 'loadTerritories', 'impersonate']),

    onSearch() {
      this.loadUsers(this.searchObject)
    },
    bold(text) {
      const parts = Object.values(this.searchObject).filter((c) => c)
      for (const part of parts) {
        text = text.replaceAll(part, `<b>${part}</b>`)
      }
      return text
    },
    program(value) {
      if (!value.programs) {
        return null
      }
      const programs = value.programs.filter((p) => !['udwi', 'comptes_test'].includes(p))
      if (!programs.length) {
        return null
      }
      return programs.map((p) => p.toLowerCase()).join(', ')
    },
    sensor(value) {
      if (!value.manufacturers) {
        return null
      }
      const manufacturers = unique(value.manufacturers)
        .map((p) => p.toLowerCase())
        .map((m) => (m === 'ecoco2' ? 'epicee' : m))
      return manufacturers
    },
    territory(value) {
      if (!value.territory) {
        return null
      }
      const territory = value.territory
      if (territory.includes('France')) {
        return null
      }
      return territory.toLowerCase()
    },
    type(value) {
      if (!value.user_type) {
        return null
      }
      let type
      if (value.user_type === 'PROFESSIONAL') {
        type = 'pro'
      } else if (value.user_type === 'PRIVATE') {
        type = 'particulier'
      }
      return type?.toLowerCase()
    },
    onImpersonate(event) {
      if (!this.user) {
        event.cancel()
        return
      }
      this.addHistory(this.user)
      const route = this.$router.resolve({
        name: 'dashboard',
        query: {
          user: this.user.email,
        },
      })
      window.location.href = `${config('app.root')}${route.href}`
    },
    remove(user) {
      this.removeHistory(user)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/imports';
.search {
  display: flex;
  width: 100%;
  > div {
    width: 100%;
  }
  :deep(.u-autocomplete-item) {
    padding-right: 0.5em;
    display: block;
    > p {
      width: 100%;
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: $screen_s) {
      border-bottom: 1px solid $grey-light;
    }
  }
  .name {
    display: flex;
    flex: 1;
    flex-direction: column;
    @include u-txt-sm;
    @include PrimaryFontMedium;
    max-width: 200px;
  }
  .sensors {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    max-width: 200px;
    gap: 0.5em;
    @media screen and (max-width: $screen_s) {
      max-width: 100%;
    }
    span {
      color: $green-dark;
      font-size: 0.8em;
      padding: 0em 0.2em;
    }
  }
  .infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0 !important;
    flex-wrap: wrap;
    @media screen and (max-width: $screen_s) {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      > span {
        margin-left: 0 !important;
        margin-bottom: 0.25em;
      }
    }

    > span,
    > p {
      margin-left: 1em;
      padding: 0em 0.2em;

      &.programs {
        background-color: #badd4c;
      }
      &.territory {
        background-color: $blue-light;
        color: $grey-darken;
      }
      &.type {
        background-color: $orange-light;
        color: $grey-darken;
      }
    }
  }
  .data {
    margin-left: 1em;
    display: flex;
    margin-top: 0 !important;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    line-height: 1em;
    @media screen and (max-width: $screen_s) {
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 0;
    }
  }
  .sites,
  .sources {
    font-size: 0.6em;
    align-self: flex-end;
  }

  .u-autocomplete {
    width: 100%;
  }
  .icon {
    margin-left: 0.5em;
    @media screen and (max-width: $screen_s) {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
</style>
