import Highcharts from 'highcharts'
import accessibility from 'highcharts/modules/accessibility'
import highchartDebugger from 'highcharts/modules/debugger'
import PatternFill from 'highcharts/modules/pattern-fill'
import stockInit from 'highcharts/modules/stock'
import Timeline from 'highcharts/modules/timeline'
import { debug } from '@/helpers/env'

stockInit(Highcharts)
PatternFill(Highcharts)
Timeline(Highcharts)
accessibility(Highcharts)
if (debug) {
  highchartDebugger(Highcharts)
}
