<template>
  <transition name="slide" :duration="300" :appear="false">
    <nav v-show="layoutStore.navigationOpen" class="u-navigation" id="side-navigation" data-tour="menu">
      <div class="nav-content" data-cy="user-menu">
        <!-- Admin Navigation -->
        <NavAdmin ref="navAdmin" v-if="can('impersonate')" />

        <!-- Main User Navigation -->
        <section v-if="sites.length && can('dashboard')" class="nav-user" :style="navUserStyle">
          <div v-if="isMobile" class="site-container">
            <site-selector @change="onSiteSelectionChanged" />
          </div>

          <!-- Dashboard -->
          <ul>
            <li class="content-title" v-t="'navigation.home.label'" />
            <NavItem
              :to="{ name: 'dashboard', params: { siteId } }"
              :label="$t('navigation.home.dashboard')"
              icon="dashboard"
              :aria-label="$t('navigation.home.dashboard')"
            />
            <NavItem
              :to="{ name: 'notifications', params: { siteId }, query: { alert_type: 'analysis' } }"
              :label="$t('navigation.energy.report')"
              icon="bilan"
              data-tour="menu-electricity-report"
              :aria-label="$t('navigation.energy.report')"
              :has-new-content="reportCount > 0"
            />
          </ul>

          <!-- Energy -->
          <ul>
            <li class="content-title" v-t="'navigation.energy.label'" data-tour="menu-electricity" />

            <li
              v-if="hasGasSource"
              class="u-txt-sm c-grey-dark u-mb-2 px-5 list-inside list-item"
              v-t="'navigation.energy.label.elec'"
            />

            <NavItem
              :to="{ name: 'electricity.power', params: { siteId }, query: { tab: 'total' } }"
              :label="$t('navigation.energy.power')"
              icon="elec"
              data-tour="menu-electricity-power"
              :aria-label="$t('navigation.energy.power')"
            />
            <NavItem
              :to="{
                name: 'electricity.consumption',
                params: { siteId },
                query: { tab: 'total', mode: 'days7' },
              }"
              :label="$t('navigation.energy.consumption')"
              icon="rise"
              data-tour="menu-electricity-consumption"
              :aria-label="$t('navigation.energy.consumption')"
            />

            <li
              v-if="hasGasSource"
              class="u-txt-sm c-grey-dark u-mb-2 px-5 list-inside list-item"
              v-t="'navigation.energy.label.gas'"
            />
            <NavItem
              v-if="hasGasSource"
              :to="{
                name: 'gas.consumption',
                params: { siteId },
                query: { tab: 'total', mode: 'days7' },
              }"
              :label="$t('navigation.energy.consumption')"
              icon="gas-dashboard"
              data-tour="menu-electricity-consumption"
              :aria-label="$t('navigation.energy.consumption')"
            />
          </ul>

          <!-- Economie d'énergie -->
          <ul>
            <li class="content-title" v-t="'navigation.energy_saving.label'" data-tour="menu-saving" />
            <NavItem
              :to="{ name: 'campaign', params: { siteId } }"
              :label="campaignLabel"
              :aria-label="campaignLabel"
              icon="plan-action"
              data-tour="menu-saving-action"
              data-cy="menu-item-campaigns"
            />
            <NavItem
              :to="{ name: 'ecotips.subcategories', params: { siteId, category: 'consommation' } }"
              :label="$t('navigation.energy_saving.ecotips')"
              :aria-label="$t('navigation.energy_saving.ecotips')"
              icon="eclairage"
              data-tour="menu-saving-advice"
            />
            <NavItem
              v-if="isIntern && isCompany && userUpSince7days"
              :to="missionUdwiRoute"
              :label="$t('navigation.energy_saving.savings_objectives')"
              :aria-label="$t('navigation.energy_saving.savings_objectives')"
              icon="bilan"
              badge="intern"
              data-tour="menu-saving-report"
            />
          </ul>

          <!-- Environnement intérieur -->
          <ul v-if="can('comfort')">
            <li class="content-title" v-t="'navigation.indoor.label'" data-tour="menu-comfort" />
            <NavItem
              v-if="hasComfortSources"
              icon="comfort"
              data-tour="menu-comfort-advanced"
              :to="{ name: 'comfort.advanced', params: { siteId } }"
              :label="$t('navigation.indoor.advanced')"
              :aria-label="$t('navigation.indoor.advanced')"
            />
            <NavItem
              v-if="isCompany && isMetropole && userUpSince48h && isHeatSeasonAvailable"
              :to="{ name: 'heating', params: { siteId } }"
              :label="$t('navigation.heating.label')"
              icon="chauffage"
              :aria-label="$t('navigation.heating.label')"
              :tooltip-text="!sourcesUpSince48h.length ? $t('navigation.heating.tooltip') : undefined"
              :disabled="!sourcesUpSince48h.length"
            />
            <NavItem
              v-if="(isProInMetropole || isProInDrom) && (hasElectricitySources || hasComfortSources)"
              :to="{ name: 'air.conditioning', params: { siteId } }"
              :label="$t('navigation.air_conditioning.label')"
              :aria-label="$t('navigation.aria_label.air_conditioning')"
              icon="climatisation"
            />
            <NavItem
              :to="{ name: 'comfort.temperature', params: { siteId } }"
              :label="$t('navigation.indoor.temperature')"
              icon="temperature"
              data-tour="menu-comfort-temperature"
              :aria-label="$t('navigation.indoor.temperature')"
            />
            <NavItem
              :to="{ name: 'comfort.humidity', params: { siteId } }"
              :label="$t('navigation.indoor.humidity')"
              icon="hygrometrie"
              data-tour="menu-comfort-humidity"
              :aria-label="$t('navigation.indoor.humidity')"
            />
            <NavItem
              :to="{ name: 'ecotips.subcategories', params: { siteId, category: 'confort' } }"
              :label="$t('navigation.indoor.advice')"
              icon="eclairage"
              data-tour="menu-comfort-advice"
              :aria-label="$t('navigation.indoor.advice')"
            />
          </ul>
          <!-- Quiz de la semaine -->
          <ul v-if="quizData.length">
            <li class="content-title" v-t="titleContentQuiz" data-tour="menu-quiz" />
            <NavItem
              :to="linkToQuiz"
              :aria-label="$t('navigation.quiz.aria_label')"
              :label="$t('navigation.quiz.content')"
              icon="student"
              badge="new"
              data-tour="menu-quiz"
            />
          </ul>

          <!-- Parcours apprentissage -->
          <ul>
            <li class="content-title" v-t="'navigation.progess.label'" data-tour="menu-mes-quiz" />
            <!-- Tous les quiz -->
            <NavItem
              :to="{ name: 'tools.quiz.list' }"
              :label="$t('navigation.progess.quiz')"
              icon="student"
              data-tour="menu-mes-quiz"
              :aria-label="$t('navigation.progess.quiz')"
              badge="new"
            />
          </ul>
        </section>
        <!-- END Main User Navigation -->

        <!-- OnBoarding menu (without any site yet) -->
        <section v-else class="nav-user" :style="navUserStyle">
          <!-- Dashboard -->
          <ul>
            <li class="content-title" v-t="'navigation.setup.label'" />
            <NavItem
              :to="{ name: 'init' }"
              :label="$t('navigation.setup.wizard')"
              icon="bilan"
              @click.stop.prevent.capture="onWizard"
            />
          </ul>
        </section>
      </div>
    </nav>
  </transition>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import NavAdmin from '@/components/layout/NavAdmin.vue'
import NavItem from '@/components/layout/Navigation/NavItem.vue'
import siteSelector from '@/components/layout/SiteSelector.vue'
import { useResizeObserver } from '@/components/ui/uses/useResizeObserver'
import { get } from '@/helpers/http'
import { fetchNotificationCount } from '@/services/notificationCount'
import { useLayoutStore } from '@/store/pinia/layout'
import type { Source } from '@/types/api/ApiSite'
import { QuizSummary } from '@/types/quiz'

const store = useStore()
const layoutStore = useLayoutStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const isCompany = computed(() => store.getters['user/isCompany'])
const isIntern = computed(() => store.getters['user/isIntern'])
const isMetropole = computed(() => store.getters['user/isMetropole'])
const territory = computed(() => store.getters['user/territory'])
const user = computed(() => store.getters['user/user'])
const sites = computed(() => store.getters['sites/sites'])
const site = computed(() => store.getters['site/site'])
const siteId = computed(() => store.getters['site/siteId'])
const max = computed(() => store.getters['responsive/max'])
const can = computed(() => store.getters['acl/can'])
const sources = computed<Source[]>(() => store.getters['sources/sources'])
const missionData = computed(() => store.getters['missions/missionsData'])

const weekNumber = ref<number>()
const quizData = ref<QuizSummary[]>([])
const reportCount = ref<number>(0)
const totalCount = ref<number>(0)
const unreadCount = ref<number>(0)
const isMobile = computed(() => {
  return max.value('xs')
})
const selected = ref({
  route: null,
})
const navAdmin = ref(undefined)
const navAdminHeight = useResizeObserver(navAdmin).clientHeight
const navUserStyle = computed(() => {
  return { paddingTop: navAdminHeight.value + 'px' }
})
const campaignLabel = computed(() => {
  return t(isCompany.value ? 'navigation.energy_saving.campaigns.pro' : 'navigation.energy_saving.campaigns.private')
})

const hasGasSource = computed(() => {
  return site.value.sources.some((source: Source) => source.isGas())
})

const hasElectricitySources = computed(() => {
  return sources.value.some((source) => source.isElectricity())
})
const hasComfortSources = computed(() => {
  return sources.value.some((source) => source.isComfort())
})
const isProInMetropole = computed(() => {
  return isCompany.value && isMetropole.value && isClimSeasonAvailable.value
})
const isProInDrom = computed(() => {
  return isCompany.value && !isMetropole.value
})
const isClimSeasonAvailable = computed(() => {
  // Dayjs Month (January as 0, December as 11)
  // '[]' includes start and end date
  const firstJune = dayjs().month(5).startOf('month')
  const endOfSeptembre = dayjs().month(8).endOf('month')
  return dayjs().isBetween(firstJune, endOfSeptembre, 'day', '[]')
})

const isHeatSeasonAvailable = computed(() => {
  // Dayjs Month (January as 0, December as 11)
  // '[]' includes start and end date
  const firstOctober = dayjs().month(9).startOf('month')
  const endOfMarchNextYear = dayjs()
    .month(2)
    .endOf('month')
    .add(dayjs.duration({ years: 1 }))
  return dayjs().isBetween(firstOctober, endOfMarchNextYear, 'day', '[]')
})

const missionUdwiRoute = computed(() => {
  return hasMissionUdwiStarted.value
    ? { name: 'mission.preparation', params: { siteId: siteId.value } }
    : { name: 'mission.summary', params: { siteId: siteId.value } }
})
const linkToQuiz = computed<string>(() => {
  return `/tools/quiz/${quizData.value[0]?.uuid}`
})
const titleContentQuiz = computed<string>(() => {
  if (isMetropole.value) return 'navigation.quiz.label.france'
  return 'navigation.quiz.label.drom'
})
const sourcesUpSince48h = computed(() => {
  return sources.value.filter(
    (source) =>
      source.isComfort() && source.firstMeasureTimestamp && dayjs().diff(source.firstMeasureTimestamp, 'hour') > 48,
  )
})
const userUpSince48h = computed(() => {
  return user.value && user.value.creation_datetime && dayjs().diff(user.value.creation_datetime, 'hour') > 48
})

const userUpSince7days = computed(() => {
  return user.value && user.value.creation_datetime && dayjs().diff(user.value.creation_datetime, 'day') > 7
})

const hasMissionUdwiStarted = computed(() => {
  return missionData.value.length >= 1
})

async function loadSites() {
  await store.dispatch('sites/loadSites')
}

const loadMissions = async () => {
  try {
    await store.dispatch('missions/loadMissions', { siteId: siteId.value })
  } catch (error) {
    console.error('Error fetching data: ', error)
  }
}

async function fetchQuiz() {
  try {
    const data = (await get(
      `/quiz/?territories=${territory.value.toLocaleLowerCase()}&week_number=${weekNumber.value}`,
    )) as QuizSummary[]
    quizData.value = data
  } catch (error) {
    console.error(error)
  }
}
function onSiteSelectionChanged() {
  if (isMobile.value && layoutStore.navigationOpen) {
    layoutStore.toggle()
  }
}
function onWizard() {
  router.push({ name: 'init' }).catch((e) => e)
}
async function setNotificationCount() {
  const { total_count, unread_count, report_count } = await fetchNotificationCount(siteId.value)
  totalCount.value = total_count
  unreadCount.value = unread_count
  reportCount.value = report_count
}

onMounted(() => {
  const currentDate = dayjs(new Date()).format('YYYY-MM-DD')
  weekNumber.value = dayjs(currentDate).week()
  fetchQuiz()
  setNotificationCount()
  loadSites()
  loadMissions()
})
onBeforeUnmount(() => {
  document.body.classList.remove('nav-open')
})

watch(
  () => layoutStore.navigationOpen,
  (value) => {
    const body = document.body
    if (value) {
      if (!body.classList.contains('nav-open')) {
        body.classList.add('nav-open')
      }
    } else {
      body.classList.remove('nav-open')
    }
  },
  { immediate: true },
)
watch(
  siteId,
  (value) => {
    if (!value) return
    setNotificationCount()
    // loadMission()
  },
  { immediate: true },
)
watch(route, () => {
  selected.value.route = null
})
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

nav {
  position: sticky;
  top: $u-header-height;
  height: calc(100vh - #{$u-header-height});
  width: $u-nav-width;
  min-width: $u-nav-width;
  background-color: $white;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  border-right: $u-nav-border-width solid rgba($dark, 0.15);

  @media (max-width: $screen_md) {
    position: fixed;
    z-index: $zindex-u-nav;
    box-shadow: 2px 0px 4px rgba($dark, 0.12);
  }

  @media (max-width: $screen_xs) {
    width: 100vw;
    top: $u-header-height-s;
  }

  .nav-content {
    height: 100%;
    width: $u-nav-width;
    -ms-overflow-style: none;
    @media (max-width: $screen_xs) {
      width: 100vw;
    }

    section.nav-user {
      position: sticky;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;
      height: calc(100vh - #{$u-header-height});
      @media (max-width: $screen_xs) {
        // Trick pour afficher la totalité du menu en mobile lorsque si la barre de recherche du navigateur est affichée
        // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
        height: calc(100vh - calc(100vh - 100%));
      }

      .site-container {
        padding: 2em $u-nav-padding-x 1em $u-nav-padding-x;
      }

      ul.bottom {
        padding-top: 1em;
        margin-top: 0.5em;
        border-top: 1px solid $grey-light;
        border-bottom: 1px solid $grey-light;
        display: flex;
        flex-direction: column;
        flex: 1;
        .empty {
          flex: 1;
        }
      }
    }

    :deep(li.content-title) {
      @include u-txt-sm;
      @include PrimaryFontSemiBold;
      color: $grey;
      padding: 1em $u-nav-padding-x 0.4em $u-nav-padding-x;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.slide-enter-active,
  &.slide-leave-active {
    transition:
      width 300ms ease-in-out,
      min-width 300ms ease-in-out;
    .nav-admin {
      transition:
        width 300ms ease-in-out,
        min-width 300ms ease-in-out;
    }
  }
  &.slide-enter,
  &.slide-leave-to {
    width: 0;
    min-width: 0;
    .nav-admin {
      width: 0 !important;
      min-width: 0;
    }
  }

  &.u-navigation::-webkit-scrollbar {
    display: none;
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "navigation": {
      "energy": {
        "label": {
          "elec": "Électricité",
          "gas": "Gaz"
        }
      }
    }
  }
}
</i18n>
