import dayjs from 'dayjs'
import { post } from '@/helpers/http'
import { storage } from '@/helpers/storage'
import { random } from '@/helpers/strings'
import { stringify } from '@/helpers/uri'
import { config } from '@/services/config'
import { store } from '@/store/vuex/index'

export const authModule = {
  state: () => {
    let cache = storage.getItem('oauth.cache')
    if (!cache || typeof cache !== 'object') {
      cache = {}
    }
    return {
      error: null,
      token: null,
      refreshToken: null,
      expires: null,
      ...cache,
    }
  },

  getters: {
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    isConnected: (state) => !!state.token,
    error: (state) => state.error,
    expiresAt: (state) => dayjs(state.expires).format('DD/MM/YYYY à HH:mm'),
  },

  mutations: {
    set(state, { token, refreshToken, expires }) {
      state.token = token
      state.refreshToken = refreshToken
      state.expires = expires
      storage.setItem('oauth.cache', {
        token,
        refreshToken,
        expires,
      })
    },
    error(state, error) {
      state.error = error
    },
    clear(state) {
      state.token = null
      state.refreshToken = null
      state.expires = null
      storage.removeItem('oauth.cache')
      storage.removeItem('oauth.state')
    },
  },

  actions: {
    /**
     * Redirect current guest user to opossum
     */
    redirectToLogin(_context, { redirection }) {
      const data = {
        redirection: redirection ?? '/',
        state: random(32),
      }
      storage.setItem('oauth.state', data)
      const query = {
        client_id: config('auth.key'),
        redirect_uri: `${window.location.origin}/oauth/exchange`,
        response_type: 'code',
        state: data.state,
      }
      const uri = `${config('app.opossum')}/o/authorize/?${stringify(query)}`
      window.location.href = uri
    },
    /**
     * Handle opposum returning guest (after login credentials input)
     */
    async handleURI({ commit, dispatch }, { query }) {
      const { code, state } = query
      const { state: cookieState, redirection } = storage.getItem('oauth.state') ?? {}
      if (cookieState?.toString() !== state?.toString()) {
        return commit('error', {
          type: 'state_mismatch',
        })
      }
      storage.removeItem('oauth.state')
      await dispatch('user/clear', {}, { root: true })
      await dispatch('fetchAccessToken', { code, type: 'authorization_code' })
      return redirection
    },

    fetchAccessToken({ commit }, { code, type }) {
      const key = type === 'authorization_code' ? 'code' : 'refresh_token'
      const params = {
        [key]: code,
      }
      return post(
        'o/token',
        {
          client_id: config('auth.key'),
          redirect_uri: `${window.location.origin}/oauth/exchange`,
          grant_type: type,
          ...params,
        },
        undefined,
        { opossum: true, formData: true },
      )
        .then((data) => {
          const options = {
            token: data.access_token,
            expires: Date.now() + data.expires_in * 1000 /** given in seconds */,
            refreshToken: data.refresh_token,
          }
          commit('set', options)
          return options
        })
        .catch((error) => {
          commit('error', {
            type: 'state_mismatch',
          })
          return Promise.reject(error)
        })
    },
    refreshAccessToken({ dispatch, getters }) {
      return dispatch('fetchAccessToken', { code: getters.refreshToken, type: 'refresh_token' })
    },
    logout({ getters, commit, dispatch }) {
      if (!getters.token) {
        return // not connecteed
      }
      return post('accounts/api_logout', null, null, { opossum: true }).finally(() => {
        commit('clear')
        return dispatch('user/clear', {}, { root: true })
      })
    },
    async updateTokenFromOutside({ commit, rootGetters, dispatch }, options) {
      const user_id = rootGetters['user/userId']
      if (!options) {
        // disconnected from outside
        commit('clear')
        if (user_id) {
          window.location.href = '/home'
        }
        return // same state
      } else {
        commit('set', options)
      }
      if (!user_id) {
        // connected from outside
        window.location.href = '/dashboard'
        return
      }
      await dispatch('user/loadUser', { force: true }, { root: true })
      if (user_id?.toString() !== rootGetters['user/userId']?.toString()) window.location.reload()
    },
    listen() {
      storage.on('oauth.cache', (value) => {
        store.dispatch('auth/updateTokenFromOutside', value)
      })
    },
  },

  namespaced: true,
}
