/* eslint-disable eqeqeq */
import deepEquals from 'fast-deep-equal'
import { RouteRecordNormalized, createRouter, createWebHistory } from 'vue-router'
import { last } from '@/helpers/arrays'
import { local, production, support } from '@/helpers/env'
import routes from '@/routes'
import { can } from '@/services/acl'
import { config } from '@/services/config'
import { store } from '@/store/vuex/index'

export const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'navmenu-active',
})

function needs(parameter: string, route) {
  if (route.meta?.params?.[parameter] === false) return false
  if (route.meta?.params?.[parameter] === true) return true
  // if url wait for site id => always required
  const url = route.path.replaceAll(`:${parameter}?`, '')

  return !!~url.indexOf(`:${parameter}`)
}

function overrideTitle(titleOrigin: string) {
  const element = document.querySelector('title')
  if (!element) return

  const title = support ? 'Support' : titleOrigin ?? 'Udwi'

  element.innerText = production ? title : `[${config('app.environment').substring(0, 3)}] ${title}`
}

router.beforeEach((to, from, next) => {
  overrideTitle(to.meta.title)
  return next()
})

//! impersonate
router.beforeEach(async (to, from, next) => {
  if (from.fullPath !== '/' || (!support && !to.query?.user)) return next()
  // already set up
  if (store.getters['impersonate/impersonating']) return next()
  // not connected
  if (!store.getters['auth/token']) return next()
  const email = to.query.user
  // same person
  if (!support && email === store.getters['user/email']) return next()
  try {
    await store.dispatch('impersonate/loadImpersonator', { email })
  } catch (error) {
    delete to.query.user
    return next(to)
  }
  if (support) return next()
  router.beforeEach((to, from, next) => {
    if (!to.query?.user) {
      to.query.user = email
      return next(to)
    }
    return next()
  })
  return next()
})

//! oauth
router.beforeEach(async (to, from, next) => {
  if (!store.getters['user/user']) await store.dispatch('user/loadUser')
  const user = store.getters['user/user']
  if (to.name === 'oauth-exchange') {
    if (user) {
      // already connected
      return next({ name: 'main' })
    }
    // special route
    try {
      let redirection = await store.dispatch('auth/handleURI', {
        path: to.fullPath,
        query: to.query,
      })
      if (!redirection) {
        redirection = { name: 'main' }
      }
      return next(redirection)
    } catch (error) {
      console.error(error)
    }

    return next()
  }
  if (to.meta.external) return next()
  if (to.meta.guest && store.getters['user/user']) return next({ name: 'main' })

  // wizard_complete = false => redirect to init (sauf si sur une autre page pour le debug + pages type profil etc)
  if (!store.getters['user/isSetup']) {
    if (!from?.name) {
      if (to.name?.startsWith('init.') || to.name?.startsWith('kits.')) {
        // grdf + enedis has a specific setup page we need to go back to it
        if (!['kits.grdf', 'kits.enedis'].includes(to.name)) return next({ name: 'init' })
      }
    }
  }
  if (!store.getters['user/user'] && !to.meta.guest) {
    return next({
      name: 'home',
      params: {
        redirection: to.fullPath,
      },
    })
  }
  return next()
})

//! handle site_id/souce_id in uri
router.beforeEach(async (to, from, next) => {
  const matched = last(to.matched) as RouteRecordNormalized
  let rewrited = false
  if (!matched) return next()
  if (matched.meta.redirect) return next(matched.meta.redirect)
  const needsSiteId = needs('siteId', matched) || matched.meta.site
  const needsSourceId = needs('sourceId', matched) || matched.meta.source
  if (needsSiteId || needsSourceId || to.params.siteId || to.params.sourceId) {
    if (!store.getters['sites/hasSites']) await store.dispatch('sites/loadSites')
  }
  // if needs site but no current site => select site by url or first by default
  if (needsSiteId || needsSourceId) {
    if (!store.getters['site/site']) {
      await store.dispatch('site/setCurrentSite', to.params.siteId ?? store.getters['sites/sites'][0])
    }
  }
  // force to get siteid
  if (needsSiteId && !to.params.siteId && !to.params.sourceId) {
    to.params.siteId = store.getters['site/siteId']
    rewrited = true
  }
  // set site id from source
  if (to.params.sourceId && !to.params.siteId) {
    let site = store.getters['sites/getSiteFromSource'](to.params.sourceId)
    if (site && site.id != store.getters['site/siteId']) {
      await store.dispatch('site/setCurrentSite', site.id)
    }
    if (!site) {
      await store.dispatch('site/setCurrentSite', store.getters['sites/sites'][0])
      site = store.getters['site/site']
      to.params.sourceId = null
    }
    to.params.siteId = site?.id ?? null
    rewrited = true
  }

  if ((needsSiteId || needsSourceId) && !to.params.siteId) {
    if (can('dashboard')) {
      return next({ name: 'init.site' })
    } else {
      return next({ name: 'init' })
    }
  }

  if (to.params.siteId) {
    if (to.params.siteId != store.getters['site/siteId']) {
      await store.dispatch('site/setCurrentSite', to.params.siteId)
    }
    if (to.params.siteId != store.getters['site/siteId']) {
      await store.dispatch('site/setCurrentSite', store.getters['sites/sites'][0])
      to.params.siteId = store.getters['site/siteId'] ?? null
      rewrited = true
    }
    // means: bad siteid
    if ((!to.params.siteId && needsSiteId) || (to.params.siteId && to.params.siteId != store.getters['site/siteId'])) {
      return next({ name: 'main' })
    }
  }
  if (to.params.sourceId) {
    if (!store.getters['sources/hasSource'](to.params.sourceId)) {
      to.params.sourceId = null
      rewrited = true
    }
  }

  if (rewrited) return next({ name: to.name, params: to.params, query: to.query, hash: to.hash })
  return next()
})

//! ACL
router.beforeEach((to, from, next) => {
  if (!to.meta?.acl) return next()
  const allowed = can(to.meta.acl)
  if (allowed) return next()
  if (to.meta.acl.support) {
    window.location.href = config('app.root')
    return
  }
  if (!can('dashboard')) return next({ name: 'init' })
  return next({ name: 'main' })
})

// Add admin email on query params
router.beforeEach(async (to, from, next) => {
  if (!store.getters['user/email']) return next()
  if (!can('impersonate')) return next()
  if (store.getters['impersonate/impersonating']) return next()
  if (to.query?.user) return next()
  if (support) return next()
  const email = store.getters['user/email']
  to.query.user = email

  return next(to)
})

// Add support filter on query params
if (support) {
  router.beforeEach(async (to, from, next) => {
    if (!store.getters['user/user']) return next()
    await Promise.all([store.dispatch('filters/loadPrograms'), store.dispatch('filters/loadTerritories')])
    // not loaded yet
    if (!store.getters['filters/selectedTerritories'].length && to.query.territories) {
      store.commit(
        'filters/selectTerritories',
        to.query.territories.split(',').map((t: string) => parseInt(t, 10)),
      )
    }
    if (!store.getters['filters/selectedPrograms'].length && to.query.programs) {
      store.commit(
        'filters/selectPrograms',
        to.query.programs.split(',').map((t: string) => parseInt(t, 10)),
      )
    }
    const query = store.getters['filters/queryUrl']
    if (deepEquals(to.query.territories, query.territories) && deepEquals(to.query.programs, query.programs)) {
      return next()
    }
    to.query.programs = query.programs
    if (!query.programs) delete to.query.programs
    to.query.territories = query.territories
    if (!query.territories) delete to.query.territories

    return next(to)
  })
}

if (local) {
  router.beforeEach((to, from, next) => {
    if (import.meta.hot) {
      import.meta.hot.send('udwi:routes', { name: to.name, path: to.matched.length ? last(to.matched).path : to.path })
    }
    return next()
  })
}
