<template>
  <div class="flex">
    <USelect
      align="stretch"
      :disabled="disabled"
      :aria-label="t('aria_label.sites_list')"
      :model-value="site"
      @update:model-value="setSite"
      @update:opened="opened = $event"
    >
      <template #title>
        <div
          class="site-select flex py-2 pr-3 pl-4"
          :class="{ opened, disabled }"
          :aria-label="t('aria_label.sites_list')"
        >
          <template v-if="site">
            {{ site.name }}
            <IconChevron :size="24" :rotate="opened ? -90 : 90" class="ml-auto" />
          </template>
          <template v-else-if="isCompany">{{ t('main-header.sites.placeholder.pro') }}</template>
          <template v-else>{{ t('main-header.sites.placeholder.private') }}</template>
        </div>
      </template>
      <UOption v-for="s of sites" :value="s" :key="s.id" class="py-3 px-3">
        {{ s.name }}
      </UOption>
      <li class="pt-4 pb-4 px-3 txt-nowrap flex justify-center" @click.stop>
        <UButton
          @click="addSite"
          icon="fab-add"
          type="ghost"
          size="small"
          role="button"
          :aria-label="t('aria_label.add_site')"
        >
          <template v-if="isCompany">
            {{ t('add_site_pro') }}
          </template>
          <template v-else>
            {{ t('add_site') }}
          </template>
        </UButton>
      </li>
    </USelect>
    <RouterLink
      :to="{ name: 'parameters' }"
      data-cy="openParameters"
      class="icon-container m-0"
      :class="{ opened }"
      :aria-label="t('aria_label.open_parameters')"
      @click="toggleNavigation"
    >
      <IconSettings data-cy="menu-item-settings" role="button" aria-hidden="true" />
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import UOption from '@/components/Select/UOption.vue'
import USelect from '@/components/Select/USelect.vue'
import IconChevron from '@/components/icons/IconChevron.vue'
import IconSettings from '@/components/icons/IconSettings.vue'
import UButton from '@/components/ui/UButton.vue'
import { useLayoutStore } from '@/store/pinia/layout'

const { t } = useI18n()

type Site = { name: string; id: string }

defineProps({ disabled: Boolean })
const emit = defineEmits<{
  (e: 'change', v?: string): void
}>()
const opened = ref(false)
const store = useStore()
const layoutStore = useLayoutStore()
const router = useRouter()
const route = useRoute()
const sites = computed(() => store.getters['sites/sites'] as Site[])
const site = computed(() => store.getters['site/site'] as Site)
const user = computed(() => store.getters['user/user'])
const isCompany = computed(() => store.getters['user/isCompany'])
const max = computed(() => store.getters['responsive/max'])
const sScreenSize = computed(() => max.value('s'))

const closeNavigation = () => {
  layoutStore.toggle()
}

const toggleNavigation = () => {
  if (sScreenSize.value) {
    closeNavigation()
  }
}

async function setSite(s: unknown) {
  store.dispatch('site/setCurrentSite', s)
  await router.push({ params: { siteId: site.value.id }, query: route.query })
  emit('change', site.value.id)
}

function addSite() {
  if (sScreenSize.value) {
    closeNavigation()
  }
  router.push({ name: 'init.site', query: { user: user.value.email_address, new: 'true' } })
}
</script>

<style scoped>
.justify-center {
  justify-content: center;
}
.site-select {
  min-width: 270px;
  align-items: center;
  border: solid 2px var(--grey-light);
  border-radius: 12px 0 0 12px;
}
.site-select.opened:not(.disabled) {
  border-radius: 12px 0 0 0;
  border-color: var(--grey);
}
:deep(.u-select-list) {
  border: solid 2px var(--grey);
  border-top: none;
  border-radius: 0 0 12px 12px;
}

.icon-container {
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--grey-light);
  border-left: none;
  border-radius: 0 12px 12px 0;
  .icon {
    cursor: pointer;
  }
}
.disabled {
  opacity: 0.5;
}
</style>

<i18n lang="json">
{
  "fr": {
    "aria_label": {
      "sites_list": "Liste des sites",
      "add_site": "Ajouter un nouveau site",
      "open_parameters": "Se rendre à la page paramètres"
    },
    "main-header": {
      "sites": {
        "placeholder": {
          "pro": "Choix de l'établissement",
          "private": "Choix du logement"
        }
      }
    },
    "add_site_pro": "Ajouter un établissement",
    "add_site": "Ajouter un logement"
  }
}
</i18n>
