import { isMobile } from '@/helpers/Device'
import { keyBy, last } from '@/helpers/arrays'
import { debug } from '@/helpers/env'
import { sentry } from '@/plugins/yrtnes'
import { store } from '@/store/vuex/index'

function css(name) {
  return {
    max: parseFloat(getComputedStyle(document.documentElement).getPropertyValue(`--screen_${name}`)) + 1,
    name,
  }
}
window.addEventListener('resize', () => {
  if (!store?.hasModule('responsive')) {
    return
  }
  store.commit('responsive/update', { width: window.innerWidth, height: window.innerHeight })
})

export const responsiveModule = {
  state: {
    device: isMobile() ? 'mobile' : 'desktop',
    breakpoints: [],
    helper: debug,
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
      dpi: window.devicePixelRatio ?? 1,
    },
  },

  getters: {
    breakpoint(state) {
      for (let i = 0; i < state.breakpoints.length - 1; i++) {
        if (state.screen.width < state.breakpoints[i].max) {
          return state.breakpoints[i]
        }
      }
      return last(state.breakpoints)
    },
    min(state, getters) {
      return (breakpoint) => {
        const index = state.breakpoints.findIndex((b) => b.name === breakpoint)
        const indexCurrent = state.breakpoints.indexOf(getters.breakpoint)
        return indexCurrent >= index
      }
    },
    max(state, getters) {
      return (breakpoint) => {
        const index = state.breakpoints.findIndex((b) => b.name === breakpoint)
        const indexCurrent = state.breakpoints.indexOf(getters.breakpoint)
        return indexCurrent <= index
      }
    },
    breakpoints(state) {
      return keyBy('name', state.breakpoints)
    },
    screen(state) {
      return state.screen
    },
    device(state) {
      return state.device
    },
    isMobile(state) {
      return state.device === 'mobile'
    },
    helper(state) {
      return state.helper
    },
  },

  mutations: {
    initBreakpoints(state) {
      let breakpoints = [css('xxs'), css('xs'), css('s'), css('sm'), css('md'), css('lg'), { name: 'xl' }]
      breakpoints = breakpoints.map((size, index) => {
        size.index = index
        if (index === 0) {
          return size
        }
        size.min = breakpoints[index - 1].max
        return size
      })
      state.breakpoints = breakpoints
    },
    update(state, { width, height }) {
      state.screen.width = width
      state.screen.height = height
      state.screen.dpi = window.devicePixelRatio ?? 1

      sentry.setContext('screen', {
        resized: true,
        dpi: state.screen.dpi,
        height: state.screen.height + 'px',
        width: state.screen.width + 'px',
      })
    },
  },

  namespaced: true,
}
