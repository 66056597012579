<template>
  <div class="u-local-toast-container" :class="{ remote: remotePortal }" v-if="visible">
    <Teleport :to="'#u-toasts-' + remotePortal" v-if="remotePortal">
      <u-toast v-bind="$props" :remote="false" @close="close" :key="keyId()">
        <template v-for="(index, name) in $slots" #[name]>
          <slot :name="name" />
        </template>
      </u-toast>
    </Teleport>
    <div
      v-else
      :class="['u-' + type, { delay, pointer: to, autoFit: autoFit }]"
      :style="style"
      class="u-toast"
      @animationend="onAnimationEnd"
      @click="onClick"
      @dblclick="onDblClick"
    >
      <AsyncIcon
        v-if="iconType"
        :name="iconType"
        color="white"
        secondary-color="transparent"
        secondary="transparent"
        :size="24"
      />
      <p v-if="$slots.default">
        <slot />
      </p>
      <p v-else v-html="text" class="bold" />
      <IconClose v-if="dismissible" @click="close($event)" :size="16" color="white" class="pointer" />
    </div>
  </div>
</template>

<script>
import AsyncIcon from '@/components/icons/AsyncIcon.vue'
import IconClose from '@/components/icons/IconClose.vue'
import { openBlank } from '@/helpers/misc'
import { parse } from '../utils/colors'

let keyId = 1
export default {
  name: 'UToast',
  components: {
    IconClose,
    AsyncIcon,
  },
  emits: ['close'],
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    /**
     * Toast test. Ignored if slot is provided
     */
    text: {
      type: String,
      required: false,
    },
    /**
     * Toast icon. Override default type icon
     */
    icon: {
      type: String,
      required: false,
    },
    /**
     * Toast type. Will set default color, background color and icon
     */
    type: {
      type: String,
      default: 'notice',
      validator(value) {
        return ['notice', 'success', 'error', 'warning'].includes(value)
      },
    },
    /**
     * Route to give to $router on click
     */
    to: {
      type: [String, Object],
      required: false,
    },
    /**
     * Absolute x position. Forces remote toast mode
     */
    x: {
      type: [Number, String],
      required: false,
    },
    /**
     * Absolute y position. Forces remote toast mode
     */
    y: {
      type: [Number, String],
      required: false,
    },
    /**
     * Toast color. Can be name any CSS format (red, #FF0000, rgba(255, 255, 255,0) ...) or css variable.
     * If color starts with -- it is converted to var(--color)
     * /!\ You cannot put SCSS variable
     */
    backgroundColor: {
      type: String,
      required: false,
    },
    /**
     * Toast color. Can be name any CSS format (red, #FF0000, rgba(255, 255, 255,0) ...) or css variable.
     * If color starts with -- it is converted to var(--color)
     * /!\ You cannot put SCSS variable
     */
    color: {
      type: String,
      required: false,
    },
    /**
     * Show cloe icon
     */
    dismissible: {
      type: Boolean,
      default: true,
    },
    /**
     * Delay before toast is autoremoved
     */
    delay: {
      type: Number,
      required: false,
    },
    /**
     * v-model controls toast visibility. works for in component and remote toast
     */
    visible: {
      type: Boolean,
      default: true,
    },
    /**
     * @private Used for internal purpose
     */
    remote: {
      type: Boolean,
      default: () => undefined,
      private: true,
    },
    /**
     * HTML/Vue component element to be positionned on. x and y props are used as offset
     */
    relative: {
      type: Object,
    },
    /**
     * Display toast at the bottom left (remote)
     */
    bottomLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * Display toast at the bottom (remote)
     */
    bottom: {
      type: Boolean,
      default: false,
    },
    /**
     * Display toast at the bottom (remote)
     */
    bottomCenter: {
      type: Boolean,
      default: false,
    },
    /**
     * Display toast at the bottom right (remote)
     */
    bottomRight: {
      type: Boolean,
      default: false,
    },
    /**
     * Display toast at the top left (remote)
     */
    topLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * Display toast at the top (remote)
     */
    top: {
      type: Boolean,
      default: false,
    },
    /**
     * Display toast at the top (remote)
     */
    topCenter: {
      type: Boolean,
      default: false,
    },
    /**
     * Display toast at the top right (remote)
     */
    topRight: {
      type: Boolean,
      default: false,
    },
    /**
     * Auto fit toast content
     */
    autoFit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toastTextColor() {
      return parse(this.color)
    },
    toastBackgroundColor() {
      return parse(this.backgroundColor)
    },
    remotePortal() {
      if (this.remote === false) {
        return null
      }
      if (this.x !== undefined && this.y !== undefined) {
        return 'bottom-right'
      }
      if (this.bottomLeft) {
        return 'bottom-left'
      }
      if (this.bottom || this.bottomCenter) {
        return 'bottom-center'
      }
      if (this.topLeft) {
        return 'top-left'
      }
      if (this.top || this.topCenter) {
        return 'top-center'
      }
      if (this.topRight) {
        return 'top-right'
      }
      if (this.bottomRight || this.remote) {
        return 'bottom-right'
      }
      return null
    },
    iconType() {
      if (this.icon) {
        return this.icon
      }
      return this.type
    },
    style() {
      const value = {}
      if (this.toastBackgroundColor) {
        value.backgroundColor = this.toastBackgroundColor
      }
      if (this.toastTextColor) {
        value.color = this.toastTextColor
      }
      if (this.relative || this.x !== undefined || this.y !== undefined) {
        const x = parseFloat(this.x ?? 0)
        const y = parseFloat(this.y ?? 0)
        let offsetX = 0
        let offsetY = 0
        if (this.relative) {
          let relative = this.relative
          if (!relative.getBoundingClientRect) {
            relative = relative.$el // handle vue components
          }
          const bounding = relative.getBoundingClientRect()
          offsetX = bounding.left
          offsetY = bounding.top
        }
        value.left = `${x + offsetX}px`
        value.top = `${y + offsetY}px`
        value.position = 'fixed'
      }
      if (this.delay) {
        value.animationDuration = `${this.delay}ms`
      }
      return value
    },
  },
  methods: {
    keyId() {
      return keyId++
    },
    close() {
      this.$emit('close', false)
    },
    onTimeout() {
      this.close()
    },
    onAnimationEnd() {
      this.onTimeout()
    },
    onClick(event) {
      // if to property => priority
      if (this.to) {
        event.stopPropagation()
        event.preventDefault()
        if (event.ctrlKey || event.metaKey) {
          let to = this.to
          if (typeof to === 'object') {
            to = this.$router.resolve(to).href
          }
          openBlank(to)
        } else {
          this.$router.push(this.to).catch((e) => e)
        }
        this.close()
        return
      }
      const paths = event.path || (event.composedPath && event.composedPath())
      if (!paths) {
        return
      }
      const a = paths.find((element) => element.tagName === 'A')
      if (!a) {
        return
      }
      if (a.getAttribute('target') === '_blank') {
        return
      }
      const href = a.getAttribute('href')
      if (!href) {
        return
      }
      if (!href.startsWith('http:') && !href.startsWith('#')) {
        event.stopPropagation()
        event.preventDefault()
        this.$router.push(href).catch((e) => e)
        this.close()
      }
    },
    onDblClick() {
      if (this.dismissible) {
        this.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

$toast-padding: 16px;

.u-toast {
  display: flex;
  align-items: center;
  width: 600px;
  padding: 9px $toast-padding;
  border-radius: 0.65em;
  margin-bottom: 0.4em;
  max-width: calc(100vw - 2 *#{$toast-padding});

  &.autoFit {
    width: max-content;
  }

  &.u-notice {
    background: $blue;
    color: $white;
  }

  &.u-success {
    background: $green-darken;
    color: $white;
  }

  &.u-warning {
    background: $orange;
    color: $dark;
  }

  &.u-error {
    background: $red;
    color: $white;
  }

  &.delay {
    animation-name: toast-delay;
    animation-iteration-count: 1;

    &:hover {
      animation-play-state: paused;
    }
  }

  p {
    flex: 1;
    margin: 0 1em;
    color: currentColor;
    @include u-txt-sm;

    :deep(a) {
      color: currentColor;
    }

    &.bold {
      @include PrimaryFontSemiBold;
    }
  }
}

.u-local-toast-container.remote {
  display: none;
}

@keyframes toast-delay {
  /**
   * Ne pas supprimer les opacity
   */
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}
</style>
