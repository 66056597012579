export function includes(partial: unknown, object: unknown): boolean {
  if (typeof partial !== 'object') {
    return false
  }
  if (typeof object !== 'object') {
    return false
  }
  if (!partial) {
    return true
  }
  if (!object) {
    return false
  }
  return Object.keys(partial).every((key) => {
    const k = key as keyof typeof partial
    if (partial[k] && typeof partial[k] === 'object') {
      return includes(partial[k], object[k])
    }
    return partial[k] === object[k]
  })
}

/**
 * @deprecated use copy with clean argument to true instead
 * @param object
 * @returns
 */
export function clean<T extends object>(object: T): T {
  for (const [key, value] of Object.entries(object)) {
    const k = key as keyof T
    if ([undefined, null, ''].includes(value)) {
      delete object[k]
    } else if (typeof object[k] === 'object') {
      object[k] = clean(object[k] as T) as T[keyof T]
    }
  }
  return object
}

export function partial<T extends object>(keys: string[], object: T) {
  return keys.reduce((r, key) => {
    const k = key as keyof T
    r[k] = object[k]
    return r
  }, {} as Partial<T>)
}

export function equal(a: unknown, b: unknown): boolean {
  if (a === b) return true
  if (typeof a !== typeof b) return false
  if (a === null || b === null) return false
  if (Number.isNaN(a) && Number.isNaN(b)) return true
  if (typeof a !== 'object' || typeof b !== 'object') return false
  if ((a as object).constructor !== (b as object).constructor) return false
  if (a instanceof Date) return a.getTime() === (b as Date).getTime()
  if (a instanceof Map || a instanceof Set) return equal(Array.from(a.entries()), Array.from((b as typeof a).entries()))
  const keys = Object.keys(a) as (keyof typeof a)[]
  if (keys.length !== Object.keys(b).length) return false
  return keys.every((k) => equal(a[k], b[k]))
}

export function copy<T>(a: T, clean = false): T {
  if (['number', 'string', 'symbol', 'boolean', 'bigint', 'undefined'].includes(typeof a)) return a
  if (a === null) return a
  if (a instanceof Date) return new Date(a) as unknown as T
  if (a instanceof Map) return new Map(a) as unknown as T
  if (a instanceof Set) return new Set(a) as unknown as T
  if (typeof a === 'object') {
    const b = (Array.isArray(a) ? [] : {}) as T
    for (const k in a) {
      // type to never to avoid any
      if (!clean || ![undefined, null, NaN, ''].includes(a[k] as never)) b[k] = copy(a[k], clean)
    }
    return b
  }
  if (typeof a === 'function') throw new Error('[deepCopy] Cannot copy function')
  throw new Error('[deepCopy] Cannot copy object construct by ' + (a as object).constructor)
}
