import { RouteLocation } from 'vue-router'
import { getRoutePattern } from '@/helpers/router'

type Anonymized = { params?: string[]; query?: string[] }

export function anonymize(route: RouteLocation, anonymized: Anonymized): string {
  const pattern = getRoutePattern(route)
  let formattedUri = pattern || ''
  for (const [name, value] of Object.entries(route.params)) {
    const v = !anonymized.params || !anonymized.params.includes(name) ? (value as string) : `<${name}>`
    formattedUri = formattedUri?.replace(`:${name}`, v)
  }
  const formattedQuery: string = Object.entries(route.query)
    .reduce((q: string[], [name, value]) => {
      const v = !anonymized.query || !anonymized.query.includes(name) ? value : `<${name}>`
      q.push(`${name}=${v}`)
      return q
    }, [])
    .join('&')
  return `${formattedUri}?${formattedQuery}`
}
