import { ComputedRef, computed } from 'vue'
import { Store } from 'vuex'
import { useGDPR } from '@/services/gdpr'
import { ApiCompanyCategory } from '@/types/api/ApiSite'
import { MatomoTrackingData } from '@/types/tracking'

const gdpr = useGDPR()

type TrackingData = {
  matomo: ComputedRef<MatomoTrackingData>
}

export function useTrackingData(store: Store<string>): TrackingData {
  const apiUser = computed(() => store.getters['user/user'])
  const apiSite = computed(() => store.getters['site/site'])
  const apiCompanyCategories = computed(() => store.getters['sites/categories'])
  const impersonating = computed(() => store.getters['impersonate/impersonating'])
  const matomo = computed<MatomoTrackingData>(() => ({
    user: gdpr.hasConsent('matomo')
      ? {
          id: apiUser.value?.user_id,
          type: apiUser.value?.user_type,
          programs: apiUser.value?.programs,
          territory: apiUser.value?.territory,
          role: apiUser.value?.role,
          creation_datetime: apiUser.value?.creation_datetime,
        }
      : undefined,
    site: gdpr.hasConsent('matomo')
      ? {
          name: apiSite.value?.name,
          device_manufacturers: apiSite.value?.device_manufacturers,
          first_data_datetime: apiSite.value?.first_elec_source_installed,
          company_category: apiCompanyCategories.value.reduce((cat: string, currentCat: ApiCompanyCategory) => {
            if (currentCat.id === apiSite.value?.entreprise_category) cat = currentCat.name
            return cat
          }, undefined),
        }
      : undefined,
    impersonate: gdpr.hasConsent('matomo') ? impersonating.value : undefined,
    cookie_consent: gdpr.isReviewed('matomo') ? gdpr.hasConsent('matomo') : undefined,
  }))

  return { matomo }
}
