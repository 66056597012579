<script setup lang="ts">
import { computed, ref } from 'vue'
import USelect from '@/components/Select/USelect.vue'
import IconArrowDown from './icons/IconArrowDown.vue'
import IconArrowUp from './icons/IconArrowUp.vue'

const props = defineProps<{ modelValue: unknown; multiple?: boolean; disabled?: boolean }>()
const emit = defineEmits<{
  (e: 'update:modelValue', s: unknown): void
}>()
const model = computed({
  get() {
    return props.modelValue
  },
  set(v: unknown) {
    if (!props.disabled) emit('update:modelValue', v)
  },
})
const isOpened = ref(false)
</script>

<template>
  <USelect v-model="model" align="stretch" :multiple="multiple" @update:opened="isOpened = $event" :disabled="disabled">
    <template #title>
      <div class="u-dropdown py-2 px-4 s-px-8" :class="{ isOpened, disabled }">
        <span>
          <slot name="title">{{ model }}</slot>
        </span>
        <IconArrowUp v-if="isOpened" :size="24" class="ml-2 s-ml-8" />
        <IconArrowDown v-else :size="24" class="ml-2 s-ml-8" />
      </div>
    </template>
    <slot></slot>
  </USelect>
</template>

<style lang="scss" scoped>
.u-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 2px var(--grey-medium);
  border-radius: 12px;

  &.disabled {
    background-color: var(--grey-lighten);
    color: var(--grey-dark);
  }
}

.u-dropdown.isOpened {
  border-radius: 12px 12px 0 0;
  border-color: var(--grey);
}
:deep(.u-select-list) {
  border: solid 2px var(--grey);
  border-top: none;
  border-radius: 0 0 12px 12px;
}
:deep(.u-select-list li:last-child) {
  border-radius: 0 0 12px 12px;
}
</style>
