import { self } from '@/helpers/promises'

/**
 * Returns promise that resolves later
 * @param {number} time to resolve
 */
export function delay(time = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export function throttle(callback, delay, options = {}) {
  let last = null
  let timeoutTimer = null
  let lastResult = null
  let lastParameters = null
  options = Object.assign({ immediate: false }, options)
  return function (...params) {
    if (options.immediate && (!last || (last && last < Date.now() - delay))) {
      if (timeoutTimer) {
        clearTimeout(timeoutTimer)
        timeoutTimer = null
      }
      last = Date.now()
      return (lastResult = callback(...params))
    } else if (!timeoutTimer) {
      timeoutTimer = setTimeout(
        function () {
          last = Date.now()
          timeoutTimer = null
          lastResult = callback(...lastParameters)
        },
        delay - (last && options.immediate ? Date.now() - last : 0),
      )
    }
    lastParameters = params

    return lastResult
  }
}
/**
 * Allows to throttle and promisify a method
 * @param {Function} method to be called each delay
 * @param {Number} delay Time in milliseconds between 2 calls
 * @param {Object} options {immediate:boolean, alwaysResolve:boolean}
 * if immediate is set to true it will call the method immediately on first call or if the last one is > delay. default false
 * if alwaysResolve=true, all call to the throttled method will received the promise result otherwise only the last one before an execution will be resolved. default false
 *
 * Examples:
 * let throttled = throttlePromise(()=>{}, 1000, {alwaysResolve:false})
 * throttle().then(()=>console.log('a'))
 * throttle().then(()=>console.log('b'))
 * - 1 second elapsed
 * throttle().then(()=>console.log('c'))
 * will print:
 * 'b' and 1 second later 'c'
 *
 *
 * let throttled = throttlePromise(()=>{}, 1000, {alwaysResolve:true})
 * throttle().then(()=>console.log('a'))
 * throttle().then(()=>console.log('b'))
 * - 1 second elapsed
 * throttle().then(()=>console.log('c'))
 * will print:
 * 'a', 'b' and 1 second later 'c'
 * @returns {Function} Throttled and promisified method
 */
export function throttlePromise(callback, delay, options = {}) {
  let last = 0
  let timeoutTimer = null
  let lastPromise = null
  let lastParameters
  options = Object.assign({ immediate: false, alwaysResolve: false }, options)
  return async function (...params) {
    if (options.immediate && (!last || (last && last < Date.now() - delay))) {
      if (timeoutTimer) {
        clearTimeout(timeoutTimer)
        timeoutTimer = null
      }
      last = Date.now()
      return (lastPromise = Promise.resolve().then(() => callback(...params)))
    } else if (!timeoutTimer) {
      lastPromise = self()
      timeoutTimer = setTimeout(
        async function () {
          last = Date.now()
          timeoutTimer = null
          lastPromise.resolve(Promise.resolve().then(() => callback(...lastParameters)))
        },
        delay - (last && options.immediate ? Date.now() - last : 0),
      )
    }
    if (!options.alwaysResolve) {
      lastPromise = self() // ignore previous promises
    }
    lastParameters = params
    return lastPromise
  }
}

export function debounce(callback, delay, options = {}) {
  options = Object.assign({ immediate: false }, options)

  let timeout
  return function (...params) {
    if (options.immediate && !timeout) {
      callback(...params)
    }
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      timeout = null
      callback(...params)
    }, delay)
  }
}

/**
 * Debounce and promisify a method
 * @param {Function} method to be called each delay
 * @param {Number} delay Time in milliseconds between 2 calls
 * @param {Object} options {immediate:boolean}
 * if immediate is set to true it will call the method immediately on first call or if the last one is > delay. default false
 *
 * Examples:
 * let debounce = debouncePromise(()=>{}, 1000)
 * debounce().then(()=>console.log('a'))
 * debounce().then(()=>console.log('b'))
 * - 1 second elapsed
 * debounce().then(()=>console.log('c'))
 * will print:
 * 'b' and 1 second later + delay 'c'
 *
 * @returns {Function} Debounced and promisified method
 */
export function debouncePromise(callback, delay, options = {}) {
  let promise = self()
  options = Object.assign({ immediate: false }, options)

  let timeout
  return async function (...params) {
    if (options.immediate && !timeout) {
      callback(...params)
    }
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
      promise = self()
    }
    timeout = setTimeout(() => {
      timeout = null
      try {
        promise.resolve(callback(...params))
      } catch (e) {
        promise.reject(e)
      } finally {
        promise = self()
      }
    }, delay)
    return promise
  }
}
