import constants from '@/constants'

/**
 * @deprecated
 */
export default class Source {
  id = 0
  site = 0
  name = null
  battery = null
  dataTypes = []
  indoor = false
  connected = null
  paired = false
  sensorID = null
  sensorManufacturer = null
  sensorReference = null
  firstMeasureTimestamp = 0
  lastMeasureTimestamp = null
  state = null
  last_24h_measures = null
  icon = null
  installed_timestamp = null
  installed_datetime = null
  elec_meter = null
  created = null
  current_comfort = null
  phone_number = null
  constructor({
    sensor_manufacturer,
    sensor_reference,
    sensor_id,
    id,
    site,
    battery_state,
    last_data_battery,
    name,
    data_types,
    indoor,
    connected,
    paired,
    first_measure_timestamp,
    main,
    last_measure_timestamp,
    last_24h_measures,
    installed_timestamp,
    installed_datetime,
    created,
    state,
    elec_meter,
    current_comfort,
    phone_number,
  } = {}) {
    this.id = id
    this.site = site
    this.name = name
    this.phone_number = phone_number
    this.dataTypes = data_types ?? []
    this.indoor = indoor
    this.connected = connected
    this.paired = paired
    this.sensorID = sensor_id
    this.sensorManufacturer = sensor_manufacturer
    this.sensorReference = sensor_reference
    this.firstMeasureTimestamp = first_measure_timestamp
    this.main = main
    this.lastMeasureTimestamp = last_measure_timestamp
    this.last_24h_measures = last_24h_measures
    this.installed_timestamp = installed_timestamp
    this.installed_datetime = installed_datetime
    this.created = created
    this.state = state
    this.current_comfort = current_comfort
    this.icon = this._getIconType()
    this.type = this.getType()
    this.elec_meter = elec_meter
    this.setBattery(battery_state, last_data_battery)
  }

  /**
   * -
   *
   * @return {[type]} [description]
   */
  _getSourceMeta() {
    const source = constants.SOURCES_META[this.sensorReference]
    return source || null
  }

  setBattery(state, last_data) {
    this.battery = {
      state,
      last_data,
    }
  }

  _getIconType() {
    if (this.isGateway()) {
      return 'network'
    }
    if (this.isElectricity()) {
      return 'elec'
    }
    if (this.isComfort()) {
      return 'confort'
    }
    if (this.isWeather()) {
      return 'confort'
    }
    return 'equipment'
  }

  isConnected() {
    return this.state !== 'uninstalled'
  }

  getSensorID() {
    return this.sensorID
  }

  isPaired() {
    return this.paired
  }

  update(data) {
    for (const key in data) {
      this[key] = data[key]
    }
  }

  // TODO: remove this
  getIconUrl() {
    const source = this._getSourceMeta()
    return source ? source.iconUrl : {}
  }

  // TODO: remove this
  getLabel() {
    const source = this._getSourceMeta()
    if (source) {
      if (source.indoor && source.outdoor) {
        const key = this.indoor ? 'indoor' : 'outdoor'
        return source[key].label
      }
      return source.label
    }
    return null
  }

  getType() {
    if (this.isGateway()) {
      return 'gateway'
    }
    if (this.isElectricity()) {
      return 'electricity'
    }
    if (this.isComfort()) {
      return 'comfort'
    }
    if (this.isWeather()) {
      return 'weather'
    }
    return this.getPrefix()?.toLowerCase()
  }

  /**
   * -
   *
   * @return {[type]} [description]
   */
  getPrefix() {
    const source = this._getSourceMeta()
    if (source) {
      if (source.indoor && source.outdoor) {
        const key = this.indoor ? 'indoor' : 'outdoor'
        return source[key].componentPrefix
      }
      return source.componentPrefix
    }
    return null
  }

  isIndoor() {
    return !!this.indoor
  }

  /**
   * -
   *
   * @return {Boolean} [description]
   */
  isVirtual() {
    return this.isVirtualWeather()
  }

  isElectricity() {
    return (
      this.isType('power') ||
      this.isType('electricity') ||
      this.isType('consumption') ||
      this.isType('hot_water_electricity')
    )
  }

  isPower() {
    return this.isType('power')
  }

  isConsumption() {
    return this.isType('consumption')
  }

  isGas() {
    return this.isType('gas')
  }

  isTemperature() {
    return this.isType('temperature')
  }

  isHumidity() {
    return this.isType('humidity')
  }

  isComfort() {
    if (this.indoor && this.isType('humidity') && this.isType('temperature')) {
      return true
    }
    return false
  }

  isExternalComfort() {
    if (!this.indoor && this.isType('temperature')) {
      return true
    }
    return false
  }

  isWeather() {
    if (!this.indoor && (this.isType('humidity') || this.isType('temperature'))) {
      return true
    }
    return false
  }

  isVirtualWeather() {
    return this.isWeather() && this.name === 'External weather API'
  }

  // TODO: remove this
  isPicowattySensor() {
    return this.isComfort() || this.isWeather() || this.isTIC() || this.isVOC() || this.isLuminosity() || this.isUV()
  }

  isGateway() {
    return this.isType('gateway')
  }

  isFludia() {
    return this.sensorManufacturer === 'fludia'
  }

  isWebdyn() {
    return this.sensorManufacturer === 'webdyn'
  }

  isD2l() {
    return this.sensorManufacturer === 'ee_smart' && this.sensorReference === 'd2l'
  }

  isEnedis() {
    return this.sensorManufacturer === 'enedis' && this.sensorReference === 'enedis'
  }

  hasPmePmi() {
    return this.elec_meter?.meter_type === 'pme_pmi'
  }

  // TODO: remove
  isTIC() {
    return this.sensorManufacturer === 'webdyn' && this.sensorReference === 'tic'
  }

  // TODO: remove
  isVOC() {
    return this.sensorManufacturer === 'webdyn' && this.sensorReference === 'voc'
  }

  // TODO remove
  isLuminosity() {
    return this.sensorManufacturer === 'webdyn' && this.sensorReference === 'luminosity' && this.indoor
  }

  // TODO remove
  isUV() {
    return this.sensorManufacturer === 'webdyn' && this.sensorReference === 'luminosity' && !this.indoor
  }

  isMain() {
    return this.main === true
  }

  // TODO: remove
  areFludiaSensors() {
    return this.sensorManufacturer === 'fludia' && (this.sensorReference === 'fm400' || this.sensorReference === 'th')
  }

  getTypes() {
    return this.dataTypes
  }

  isType(type) {
    if (!this.dataTypes) {
      return false
    }
    if (!type) {
      return false
    }
    type = type.toUpperCase()
    return this.dataTypes.includes(type)
  }
}
