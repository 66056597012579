import { get, patch, post } from '@/helpers/http'
import { ApiCompanyCategory, SitePostPayload, SitePutPayload } from '@/types/api/ApiSite'

export async function fetchCompanyCategories() {
  return get(`/help/public/entreprise-categories`) as Promise<ApiCompanyCategory[]>
}

export async function createSite(data: SitePostPayload): Promise<void> {
  await post(`/sites/`, data, {})
}

export async function updateSite(siteId: number | string, data: SitePutPayload): Promise<void> {
  await patch(`/sites/${siteId}`, data, {})
}
