import { createMission, fetchMissions, partialUpdateMission } from '@/services/missions'

export const missionsModule = {
  state: {
    missionsData: [],
  },

  getters: {
    missionsData: (state) => state.missionsData,
  },

  mutations: {
    setMissionsData(state, missionsData) {
      state.missionsData = missionsData
    },
    addMissionData(state, missionData) {
      state.missionsData.push(missionData)
    },
    partialUpdateMissionData(state, missions) {
      const index = state.missionsData.findIndex((mission) => mission.id === missions.id)
      if (index > -1) {
        state.missionsData.splice(index, 1, Object.assign({}, missions))
      }
    },
  },

  actions: {
    async loadMissions({ commit }, { siteId }) {
      const missions = await fetchMissions(siteId)
      commit('setMissionsData', missions)
    },
    async createNewMission({ commit }, { siteId, params }) {
      const mission = await createMission(siteId, params)
      commit('addMissionData', mission)
    },
    async updateMission({ commit }, { missionId, params }) {
      const missions = await partialUpdateMission(missionId, params)
      commit('partialUpdateMissionData', missions)
    },
  },

  namespaced: true,
}
