import clone from 'clone'
import { get } from '@/helpers/http'

export const comfortModule = {
  state: {
    comfort: [],
    isLoading: false,
  },

  getters: {
    comfortDataInActivity: (state) => state.comfort.filter((el) => el.is_open),
    firstMeasureComfort: (state) => state.comfort.filter((el) => el.is_open && el.zone)[0],
    firstAllMeasureComfort: (state) => state.comfort.filter((el) => el.zone)[0],
    lastMeasureComfort: (state) => state.comfort.filter((el) => el.is_open && el.zone !== 'UNKNOWN').slice(-1)[0],
    lastAllMeasureComfort: (state) => state.comfort.filter((el) => el.zone && el.zone !== 'UNKNOWN').slice(-1)[0],
    comfortData: (state) => {
      const firstIndex = state.comfort.findIndex((el) => el.is_open)
      const lastIndex = state.comfort.findLastIndex((el) => el.is_open && el.zone !== 'UNKNOWN') + 1
      // This will change because we gonna show inactivity data in the future, for now we just replace by CLOSED
      const comfortData = state.comfort.slice(firstIndex, lastIndex)
      // Display UNKNOWN (DATA GAPS) properly for active and inactive periods
      comfortData.map((el, index) => {
        if (!el.temperature && !el.humidity) {
          const previousDataBeforeDataGap =
            comfortData[index].zone === 'UNKNOWN' &&
            comfortData[index - 1] &&
            comfortData[index - 1].zone !== 'UNKNOWN' &&
            comfortData[index - 1].temperature &&
            comfortData[index - 1].humidity
          if (previousDataBeforeDataGap) {
            return (el.zone = comfortData[index - 1].zone)
          }
          const nextDataAfterDataGap =
            comfortData[index].zone === 'UNKNOWN' &&
            comfortData[index + 1] &&
            comfortData[index + 1].zone !== 'UNKNOWN' &&
            comfortData[index + 1].temperature &&
            comfortData[index + 1].humidity
          if (nextDataAfterDataGap) {
            return (
              (el.zone = 'UNKNOWN') &&
              (comfortData[index + 1].zone = 'UNKNOWN') &&
              (comfortData[index + 1].temperature = null) &&
              (comfortData[index + 1].humidity = null)
            )
          }
        }
        return el
      })
      const copy = clone(comfortData)
      // Find zone is_open false and replace zone name by CLOSED for active periods only
      copy.map((el, index) => {
        if (!el.is_open && el.temperature && el.humidity) {
          if (copy[index - 1] && copy[index - 1].is_open) {
            return el
          }
          if (copy[index + 1] && copy[index + 1].is_open) {
            return (copy[index + 1].zone = 'CLOSED') && (el.zone = 'CLOSED')
          }
          return (el.zone = 'CLOSED')
        }
        return el
      })
      return copy
    },
    // display inactive and active periods
    allComfortData: (state) => {
      const firstIndex = state.comfort.findIndex((el) => el.is_open || !el.is_open)
      const lastIndex = state.comfort.findLastIndex((el) => el.zone !== 'UNKNOWN') + 1
      const comfortData = state.comfort.slice(firstIndex, lastIndex)
      return comfortData
    },
    isInactiveDay: (state) => state.comfort.every((el) => !el.is_open),
    isLoading: (state) => state.isLoading,
  },

  mutations: {
    setComfortData(state, data) {
      state.comfort = data
    },

    clear(state) {
      state.comfort = []
    },
    setLoading(state, payload) {
      state.isLoading = payload
    },
  },

  actions: {
    async loadComfort({ commit }, { sourceId, date }) {
      commit('setLoading', true)
      commit('clear')
      const data = await get(`/comfort/source/${sourceId}`, { date })
      commit('setComfortData', data)
      commit('setLoading', false)
    },
    clear({ commit }) {
      commit('setComfortData', [])
    },
  },

  namespaced: true,
}
