<script lang="ts" setup>
import { ref, watch } from 'vue'
import CookieConsentSettings from '@/components/layout/CookieConsentSettings.vue'
import UButton from '@/components/ui/UButton.vue'
import { env } from '@/helpers/env'
import { useGDPR } from '@/services/gdpr'
import { TrackingServiceConsent } from '@/types/tracking'

const ENV_TRACKING_SERVICES = env('VUE_APP_ENABLED_TRACKING_SERVICES') as string
const ENABLED_SERVICES = ENV_TRACKING_SERVICES ? ENV_TRACKING_SERVICES.split(',').map((s) => s.trim()) : []

const gdpr = useGDPR()

const showBanner = ref<boolean>(!gdpr.isReviewed())
const showSettings = ref<boolean>(false)
const services = ref<TrackingServiceConsent[]>(
  ENABLED_SERVICES.map((srvName) => ({
    name: srvName,
    consent: gdpr.hasConsent(srvName),
    reviewed: gdpr.isReviewed(srvName),
  })),
)

watch(
  services,
  (value) => {
    for (const s of value) {
      gdpr.setConsent(s.consent, s.name)
      gdpr.setReviewed(s.reviewed, s.name)
    }
    gdpr.save()
    services.value = gdpr.getConsents()
  },
  { deep: true },
)
watch(showSettings, (value) => {
  if (!value && gdpr.isReviewed()) showBanner.value = false
})

const setGlobalConsent = (value: boolean) => {
  services.value = services.value.map((service) => {
    service.consent = value
    service.reviewed = true
    return service
  })
  showBanner.value = false
}
</script>

<template>
  <div class="cookie-banner-root">
    <div v-if="showBanner" class="banner px-4 py-4">
      <p class="banner__text" data-test="banner-text">
        Ce site utilise des cookies afin de vous proposer des contenus adaptés et réaliser des statistiques.
      </p>

      <UButton
        @click="setGlobalConsent(false)"
        text="Refuser les cookies"
        type="tertiary"
        class="banner__button"
        data-test="deny-cookies-button"
      />

      <UButton
        text="Paramètres"
        type="tertiary"
        class="banner__button"
        @click="showSettings = true"
        data-test="know-more-button"
      />

      <UButton
        @click="setGlobalConsent(true)"
        text="Autoriser les cookies"
        class="banner__button"
        data-test="accept-cookies-button"
        data-cy="accept-cookies-button"
      />
    </div>

    <CookieConsentSettings :visible="showSettings" v-model="services" @update:visible="showSettings = $event" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';
.banner {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $screen_md) {
    flex-direction: column;

    &__text {
      text-align: center;
    }
    &__button {
      margin-top: 25px;
    }
  }
}
</style>
