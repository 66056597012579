import { ComputedRef, InjectionKey, Ref, WritableComputedRef, inject, ref } from 'vue'

// opened is used to allow only 1 dropdown opened
export const opened: Ref<symbol> = ref(Symbol('opened'))
export const kOptions: InjectionKey<Ref<unknown[]>> = Symbol('options')
export const kMultiple: InjectionKey<ComputedRef<boolean>> = Symbol('multiple')
export const kModel: InjectionKey<WritableComputedRef<unknown[]>> = Symbol('model')

export function strictInject<T>(k: InjectionKey<T>) {
  const r = inject(k)
  if (r === undefined) throw new Error(`strictInject: failed to inject key ${k}`)
  return r
}
