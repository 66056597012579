<script lang="ts" setup>
import { CSSProperties, PropType, StyleValue, computed, onMounted, onUnmounted, ref, useSlots } from 'vue'

const COLORS = ['primary', 'secondary', 'ghost', 'highlighted', 'gray']

const slots = useSlots()

const props = defineProps({
  content: {
    type: String,
    default: '',
  },
  bold: Boolean,
  italic: Boolean,
  color: {
    type: String as PropType<'primary' | 'secondary' | 'ghost' | 'highlighted' | 'gray' | string>,
    default: 'primary',
  },
  backgroundColor: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
    default: 'md',
    validator: (v: string) => ['xs', 'sm', 'md', 'lg'].includes(v),
  },
  mode: {
    type: String as PropType<'html' | 'svg'>,
    default: 'html',
    validator: (v: string) => ['html', 'svg'].includes(v),
  },
})
const svgElmt = ref<SVGGElement>()
const svgBGElmt = ref<SVGRectElement>()
const svgTextElmt = ref<SVGTextElement>()
const svgWidth = ref<number>(0)
const svgHeight = ref<number>(0)
const isDefaultColors = computed(() => COLORS.includes(props.color))
const svgPadding = computed<{ x: number; y: number }>(() => {
  const paddings = {
    xs: { x: 5, y: 2 },
    sm: { x: 6, y: 3 },
    md: { x: 7, y: 4 },
    lg: { x: 8, y: 5 },
  }
  return paddings[props.size]
})
const svgStyles = computed<CSSProperties>(() => {
  const style = {
    width: `${svgWidth.value + svgPadding.value.x * 2}px`,
    height: `${svgHeight.value + svgPadding.value.y * 2}px`,
    fill: '',
  }

  if (props.mode === 'svg' && !isDefaultColors.value) style.fill = props.backgroundColor
  return style
})
const svgTextStyle = computed(() => {
  if (props.mode !== 'svg' || isDefaultColors.value) return {}
  return {
    fill: props.color,
  }
})
const svgBorderRadius = computed<number>(() => {
  const radiuses = {
    xs: 10,
    sm: 12,
    md: 15,
    lg: 17,
  }
  return radiuses[props.size]
})
const cssClasses = computed<Record<string, boolean>>(() => ({
  [props.size]: true,
}))
const htmlStyles = computed<StyleValue>(() => {
  if (isDefaultColors.value) return {}

  return {
    backgroundColor: props.backgroundColor,
    color: props.color,
  }
})

const computeSizes = (): void => {
  const box = svgTextElmt.value?.getBBox()
  svgWidth.value = box?.width || 0
  svgHeight.value = box?.height || 0
}

const obs = new MutationObserver(computeSizes)
onMounted(() => {
  if (!slots.default && !props.content) {
    console.error(`[Badge.vue] 'content' prop is required if <slot> is empty.`)
    return
  }
  if (props.mode === 'svg') computeSizes()
  if (!svgElmt.value || !svgBGElmt.value || !svgTextElmt.value) return
  obs.observe(svgElmt.value, { attributes: true })
  obs.observe(svgBGElmt.value, { attributes: true })
  obs.observe(svgTextElmt.value, { attributes: true })
})
onUnmounted(() => {
  obs.disconnect()
})
</script>

<template>
  <template v-if="mode === 'html'">
    <span
      class="badge-html"
      :class="[props.color, cssClasses, { bold: props.bold, italic: props.italic }]"
      :style="htmlStyles"
      data-unit-test="badge_html"
    >
      <slot>{{ props.content }}</slot>
    </span>
  </template>
  <svg v-if="mode === 'svg'" :style="svgStyles" class="badge-svg" :class="cssClasses" data-unit-test="badge_svg">
    <g ref="svgElmt" :style="svgStyles">
      <rect ref="svgBGElmt" :style="svgStyles" :rx="svgBorderRadius" :class="props.color"></rect>
      <text
        ref="svgTextElmt"
        :x="svgWidth / 2 + 5"
        :y="svgPadding.y + 2"
        text-anchor="middle"
        :class="[props.color, { bold: props.bold, italic: props.italic }]"
        :style="svgTextStyle"
        data-unit-test="badge_content_svg"
      >
        <slot>{{ props.content }}</slot>
      </text>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@import '@/scss/global.scss';

.italic {
  font-style: italic;
}

// HTML
.badge-html {
  &.xs {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 10px;
  }
  &.sm {
    padding: 3px 8px;
    font-size: 12px;
    border-radius: 14px;
  }
  &.md {
    padding: 3px 8px;
    font-size: 14px;
    border-radius: 16px;
  }
  &.lg {
    padding: 4px 9px;
    font-size: 16px;
    border-radius: 18px;
  }
  &.primary {
    background-color: var(--black);
  }
  &.secondary {
    background-color: var(--cream);
  }
  &.ghost {
    background-color: var(--white);
    border: 1px solid var(--black);
  }
  &.highlighted {
    background-color: var(--green-darken);
  }
  &.gray {
    background-color: var(--grey-light);
  }
  &.primary,
  &.highlighted {
    color: var(--white);
  }
  &.secondary,
  &.ghost {
    color: var(--black);
  }
  &.gray {
    color: var(--grey-dark);
  }
}

// SVG
svg.badge-svg {
  text-rendering: optimizeLegibility;
  &.xs text {
    font-size: 10px;
  }
  &.sm text {
    font-size: 12px;
  }
  &.md text {
    font-size: 14px;
  }
  &.lg text {
    font-size: 16px;
  }
  rect {
    &.primary {
      fill: var(--black);
    }
    &.secondary {
      fill: var(--cream);
    }
    &.ghost {
      fill: var(--white);
      stroke: var(--black);
      stroke-width: 1px;
    }
    &.highlighted {
      fill: var(--green-darken);
    }
    &.gray {
      fill: var(--grey-light);
    }
  }
  text {
    dominant-baseline: hanging;
    &.primary,
    &.highlighted {
      fill: var(--white);
    }
    &.secondary,
    &.ghost {
      fill: var(--black);
    }
    &.gray {
      fill: var(--grey-dark);
    }
  }
}
</style>
