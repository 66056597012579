import i18n from '@/plugins/VueI18n.js'

const SOURCES_META = {
  tic: {
    // webdyn tic
    label: 'misc.sources.tic',
    text: i18n.global.t('misc.sources.card_name.elec'),
    title: i18n.global.t('misc.sources.new_card_name.elec'),
    computed_name: i18n.global.t('misc.sources.card_subtitle.elec'),
    manufacturer: 'webdyn',
    reference: 'tic',
    name: 'settings.sensors_pairing.source_name.tic',
    componentPrefix: 'Electricity',
    virtual: false,
    iconUrl: {
      standard: '/assets/img/sensors/icn-capteur-generique.png',
      seize: '/assets/svg/Settings/udwi_seize_tic.svg',
      tile: '/assets/svg/icons/elec-4x.svg',
      error: '/assets/svg/dashboard/webdyn_elec_error.svg',
      warning: '/assets/svg/dashboard/webdyn_elec_error.svg',
    },
    alert: {
      error: i18n.global.t('misc.sources.alert.error', { type: 'conso' }),
      warning: i18n.global.t('misc.sources.alert.warning', { type: 'conso' }),
      info: i18n.global.t('misc.sources.alert.info'),
    },
    colors: {
      mainColor: '#C675A7',
    },
  },
  thp: {
    // webdyn th
    manufacturer: 'webdyn',
    text: i18n.global.t('misc.sources.card_name.th_thp'),
    title: i18n.global.t('misc.sources.new_card_name.th_thp'),
    computed_name: i18n.global.t('misc.sources.card_subtitle.comfort'),
    reference: 'thp',
    udwi: {
      name: 'settings.sensors_pairing.source_name.thp',
    },
    seize: {
      name: 'settings.seize_sensors_pairing.source_name.thp',
    },
    virtual: false,
    iconUrl: {
      standard: '/assets/img/sensors/icn-capteur-generique.png',
      tile: '/assets/svg/icons/comfort-4x.svg',
      error: '/assets/svg/dashboard/webdyn_thp_error.svg',
      warning: '/assets/svg/dashboard/webdyn_thp_error.svg',
    },
    alert: {
      error: i18n.global.t('misc.sources.alert.error', { type: 'confort' }),
      warning: i18n.global.t('misc.sources.alert.warning', { type: 'confort' }),
      info: i18n.global.t('misc.sources.alert.info'),
    },
    indoor: {
      label: 'misc.sources.thp',
      componentPrefix: 'Comfort',
    },
    outdoor: {
      label: 'misc.sources.thp_outdoor',
      componentPrefix: 'Weather',
    },
  },
  voc: {
    label: 'misc.sources.voc',
    manufacturer: 'webdyn',
    reference: 'voc',
    name: 'settings.sensors_pairing.source_name.cov',
    componentPrefix: 'VOC',
    virtual: false,
    iconUrl: {
      standard: '/assets/img/sensors/icn-capteur-generique.png',
    },
  },
  luminosity: {
    manufacturer: 'webdyn',
    reference: 'luminosity',
    name: 'settings.sensors_pairing.source_name.luminosity',
    virtual: false,
    iconUrl: {
      standard: '/assets/img/sensors/icn-capteur-solar.png',
    },
    indoor: {
      label: 'misc.sources.luminosity',
      componentPrefix: 'Luminosity',
    },
    outdoor: {
      label: 'misc.sources.uv',
      componentPrefix: 'UV',
    },
  },
  d2l: {
    manufacturer: 'ee_smart',
    text: i18n.global.t('misc.sources.card_name.elec'),
    title: i18n.global.t('misc.sources.new_card_name.elec'),
    computed_name: i18n.global.t('misc.sources.card_subtitle.elec'),
    reference: 'd2l',
    label: 'misc.sources.ee_smart',
    componentPrefix: 'Electricity',
    virtual: false,
    iconUrl: {
      standard: '/assets/img/sensors/icn-eesmart.png',
      tile: '/assets/svg/icons/elec-4x.svg',
      error: '/assets/svg/dashboard/d2l_error.svg',
      warning: '/assets/svg/dashboard/d2l_error.svg',
    },
    alert: {
      error: i18n.global.t('misc.sources.alert.error', { type: 'conso' }),
      warning: i18n.global.t('misc.sources.alert.warning', { type: 'conso' }),
      info: i18n.global.t('misc.sources.alert.info'),
    },
    colors: {
      mainColor: '#C675A7',
    },
  },
  picowan_gateway: {
    label: 'misc.sources.picowan_gateway',
    manufacturer: 'archos',
    reference: 'picowan_gateway',
    componentPrefix: 'Gateway',
    virtual: false,
    iconUrl: {
      standard: '/assets/img/sensors/icn-gateway.png',
    },
  },
  lorawan_gateway: {
    manufacturer: 'webdyn',
    reference: 'lorawan_gateway',
    componentPrefix: 'Gateway',
    virtual: false,
    iconUrl: {
      standard: '/assets/svg/gateways/4g/gw4g-circle-green.svg',
    },
  },
  picowan_gateway_4g: {
    label: 'misc.sources.picowan_gateway',
    manufacturer: 'archos',
    reference: 'picowan_gateway_4g',
    componentPrefix: 'Gateway',
    virtual: false,
    iconUrl: {
      standard: '/assets/svg/gateways/4g/gw4g-circle-green.svg',
    },
  },
  // virtual sources
  enedis: {
    label: 'misc.sources.enedis',
    text: i18n.global.t('misc.sources.card_name.elec'),
    title: i18n.global.t('misc.sources.new_card_name.elec'),
    computed_name: i18n.global.t('misc.sources.card_subtitle.elec'),
    manufacturer: 'enedis',
    reference: 'enedis',
    componentPrefix: 'Electricity',
    virtual: true,
    iconUrl: {
      standard: '/assets/img/sensors/icn-linky.png',
      tile: '/assets/svg/icons/elec-4x.svg',
      error: '/assets/svg/fludia/sensor/elec/error.svg',
      warning: '/assets/svg/fludia/sensor/elec/error.svg',
    },
    alert: {
      error: i18n.global.t('misc.sources.alert.error', { type: 'conso' }),
      warning: i18n.global.t('misc.sources.alert.warning', { type: 'conso' }),
      info: i18n.global.t('misc.sources.alert.info'),
    },
    colors: {
      mainColor: '#C675A7',
    },
  },
  adict: {
    label: 'misc.sources.adict',
    manufacturer: 'adict',
    reference: 'adict',
    componentPrefix: 'Gas',
    indoor: true,
    positionSwitch: false,
    virtual: true,
    iconUrl: {
      standard: '/assets/img/sensors/icn-gazpar.png',
    },
    colors: {
      mainColor: '#59ABD4',
    },
  },
  fm400: {
    // fludia tic
    label: 'misc.sources.fm400',
    text: i18n.global.t('misc.sources.card_name.elec'),
    title: i18n.global.t('misc.sources.new_card_name.elec'),
    computed_name: i18n.global.t('misc.sources.card_subtitle.elec'),
    manufacturer: 'fludia',
    reference: 'fm400',
    componentPrefix: 'Electricity',
    indoor: true,
    positionSwitch: false,
    virtual: false,
    iconUrl: {
      standard: '/assets/img/sensors/icn-capteur-generique.png',
      tile: '/assets/svg/icons/elec-4x.svg',
      error: '/assets/svg/dashboard/fludia_elec_error.svg',
      warning: '/assets/svg/dashboard/fludia_elec_error.svg',
    },
    alert: {
      error: i18n.global.t('misc.sources.alert.error', { type: 'conso' }),
      warning: i18n.global.t('misc.sources.alert.warning', { type: 'conso' }),
      info: i18n.global.t('misc.sources.alert.info'),
    },
    colors: {
      mainColor: '#C675A7',
    },
  },
  th: {
    // fludia th
    label: 'misc.sources.fm400',
    text: i18n.global.t('misc.sources.card_name.th_thp'),
    title: i18n.global.t('misc.sources.new_card_name.th_thp'),
    computed_name: i18n.global.t('misc.sources.card_subtitle.comfort'),
    manufacturer: 'fludia',
    reference: 'th',
    componentPrefix: 'Comfort',
    iconUrl: {
      standard: '/assets/svg/icons/comfort-4x.svg',
      tile: '/assets/svg/icons/comfort-3x.svg',
      error: '/assets/svg/dashboard/fludia_th_error.svg',
      warning: '/assets/svg/dashboard/fludia_th_error.svg',
    },
    alert: {
      error: i18n.global.t('misc.sources.alert.error', { type: 'confort' }),
      warning: i18n.global.t('misc.sources.alert.warning', { type: 'confort' }),
      info: i18n.global.t('misc.sources.alert.info'),
    },
    colors: {
      mainColor: '#C675A7',
    },
  },
  // default for unpaired sources
  default: {
    label: '',
    manufacturer: '',
    reference: '',
    componentPrefix: '',
    virtual: null,
    iconUrl: {
      standard: '/assets/img/sensors/icn-capteur-generique.png',
    },
  },
} as const

const DEFAULTS = {
  SOURCES_META,

  // Cookies, LocalStorage

  COOKIE_NAME_OAUTH_ACCESS_TOKEN: 'auth-token',
  COOKIE_NAME_OAUTH_STATE: 'state',
  COOKIE_NAME_REDIRECT_TO: 'redirect-to',
  LS_IMPERSONATE_USER_EMAIL: 'impersonated_user_email',
  LS_IMPERSONATE_ADMIN_EMAIL: 'impersonate_admin_email',
  LS_NAME_OAUTH_ACCESS_TOKEN: 'oauth_access_token',
  LS_NAME_ACCESS_TOKEN_DURATION: 'oauth_access_token_expiration',
  LS_NAME_OAUTH_REFRESH_TOKEN: 'oauth_refresh_token',
  LS_NAME_WS_TOKEN: 'ws_token',
  LS_NAME_WS_TOKEN_EXPIRES: 'ws_token_expiration',
  LS_NAME_WIZARD_FINISHED: 'wizard_finished',
  LS_NAME_CHART_HELP_VIEWED: 'chart_help_viewed',
  LS_CHANGE_NAME_MODAL_VIEWED: 'change_name_modal_viewed',
  COOKIE_PRINCIPALE_SOURCE_ID: 'principale_source_id',

  // Permissions
  GLOBAL_PERMISSION_NAME: 'api-v1',
  DASHBOARD_PERMISSION_NAME: 'front/dashboard',
  SETUP_PERMISSION_NAME: 'setup',
  ELEC_PERMISSION_NAME: 'electricity',
  GAS_PERMISSION_NAME: 'gas',
  LUMINOSITY_PERMISSION_NAME: 'luminosity',
  TEMP_PERMISSION_NAME: 'temperature',
  HUMIDITY_PERMISSION_NAME: 'humidity',
  AIRQUALITY_PERMISSION_NAME: 'air_quality',
  SEIZE_PERMISSION_NAME: 'seize',

  // Programs
  SEIZE_PROGRAM: 'seize',
  ZESTE_PROGRAM: 'zeste',
  COMMERCIALISATION_UDWI_PROGRAM: 'Commercialisation_Udwi',
  UDWI_PROGRAM: 'udwi',

  // User

  USER_TYPE_PROFESSIONAL: 'PROFESSIONAL',
  USER_TYPE_PRIVATE: 'PRIVATE',

  // Sites

  SITE_TYPE_HOUSE: 'HOUSE',
  SITE_TYPE_APARTMENT: 'APARTMENT',
  SITE_TYPE_SPORT: 'SPORT',
  SITE_TYPE_TERTIARY: 'TERTIARY',
  SITE_TYPE_CRECHE: 'CRECHE',
  SITE_TYPE_SCHOOL: 'SCHOOL',

  // Global design color
  // TODO update color according to variable scss when new design system will be ready

  BLACK: '#3A4B58',
  GREEN: '#B6CB36',

  // Alerts

  ALERT_UNHEALTHY_SENSOR: 'unhealthy_sensor',
  ALERT_GW_DISCONNECTED: 'disconnected_gateway',
  ALERT_ENEDIS_DISCONNECTED: 'disconnected_enedis_account',

  // Data, Charts

  POWER_DEFAULT_COLOR: '#00497C',
  POWER_GRADIENT_COLORS: ['#81BC28', '#BDE036', '#EEEF29', '#DD5C25', '#DD202D'],
  CHART_FIRST_DATE_FALLBACK: '2015-01-01',

  // Comfort colors
  FINE: '#B6CB36',
  WARM: '#FAC710', // chaud
  COLD: '#2C9BF0', // froid
  MOLD: '#1D58CA', // champignons
  MITES: '#F2640A', // acariens
  DROUGHT: '#C18850', // sec
  UNKNOWN: '#808080',
  NOT_FINE: '#DE5421',

  // Sensors, Gateways

  SENSOR_ID_REQUIRED_LEN: 10,
  EESMART_ID_REQUIRED_LEN: 12,
  FLUDIA_ID_REQUIRED_LEN: 8,

  EESMART_MANUFACTURER: 'eesmart',
  EESMART_REFERENCE: 'd2l',
  EESMART_SERVER_HOST: 'eesmart.udwi.fr',
  EESMART_SERVER_PORT: 10000,

  ARCHOS_MANUFACTURER: 'archos',
  ARCHOS_REFERENCE_GATEWAY_B2C: 'picowan_gateway',
  ARCHOS_REFERENCE_GATEWAY_B2B: 'picowan_gateway_b2b',

  GW_ID_DETECT_REGEX: '^[A-F0-9]{12}$',
  SENSOR_ID_DETECT_REGEX: '^[A-F0-9]{10}$',

  DATA_TYPE_ELECTRICITY: 'ELECTRICITY',
  DATA_TYPE_HOT_WATER_ELECTRICITY: 'HOT_WATER_ELECTRICITY',
  DATA_TYPE_GAS: 'GAS',
  DATA_TYPE_PRESENCE: 'PRESENCE',
  DATA_TYPE_GATEWAY: 'GATEWAY',
  DATA_TYPE_POWER: 'POWER',
  DATA_TYPE_CONSUMPTION: 'CONSUMPTION',

  // Company

  COMPANY_SIRET_NUM: '51164460100037',

  // Label
  CONSUMPTION: 'CONSUMPTION',

  // Misc

  HOUR_TO_SECONDS: 3600,
  KILO: 1000,

  NOTIFICATIONS_REFRESH_RATE_MILLISECONDS: 120000,
} as const

export default DEFAULTS
