<script setup lang="ts">
import { computed, onMounted, onUnmounted, provide, ref, watch, withDefaults } from 'vue'
import { kModel, kMultiple, kOptions, opened } from './store'

const props = withDefaults(
  defineProps<{
    ariaLabel?: string
    modelValue?: unknown
    disabled?: boolean
    multiple?: boolean
    align?: 'left' | 'center' | 'right' | 'stretch'
  }>(),
  { align: 'stretch', ariaLabel: 'label' },
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: unknown | unknown[]): void
  (e: 'update:opened', opened: boolean): void
}>()

const model = computed({
  get() {
    return props.multiple && Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
  },
  set(v: unknown[]) {
    if (props.multiple) {
      emit('update:modelValue', v)
    } else {
      emit('update:modelValue', v[0])
      close()
    }
  },
})

const id = Symbol('USelect')
const isOpened = ref(false)
const mult = computed(() => props.multiple)
provide(kOptions, ref<unknown[]>([]))
provide(kMultiple, mult)
provide(kModel, model)

function close() {
  isOpened.value = false
  emit('update:opened', isOpened.value)
}
function toggle() {
  isOpened.value = !isOpened.value
  emit('update:opened', isOpened.value)
  if (isOpened.value) {
    opened.value = id
  }
}

watch(opened, () => {
  if (opened.value !== id) close()
})

onMounted(() => {
  window.addEventListener('click', close)
})
onUnmounted(() => {
  window.removeEventListener('click', close)
})
</script>

<template>
  <div class="u-select" @click.stop="toggle" :aria-label="ariaLabel">
    <slot name="title" :is-opened="isOpened">{{ modelValue }}</slot>
    <ul v-if="isOpened && !disabled" class="u-select-list" :class="align">
      <slot :is-opened="isOpened"></slot>
    </ul>
  </div>
</template>

<style scoped>
.u-select {
  position: relative;
  display: inline-block;
  user-select: none;
}
.u-select-list {
  cursor: pointer;
  position: absolute;
  z-index: 3;
  top: 100%;
  margin: 0;
  padding: 0;
  min-width: 100%;
  background-color: #fff;
}
.stretch {
  width: 100%;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.center {
  left: 50%;
  translate: -50%;
}
</style>
