<script lang="ts" setup>
import { PropType, computed, ref, watch } from 'vue'
import UDialog from '@/components/UDialog.vue'
import UButton from '@/components/ui/UButton.vue'
import { SERVICES } from '@/const/tracking'
import { TrackingService, TrackingServiceConsent } from '@/types/tracking'

const COOKIES_POLICY_URL = 'https://accounts.ecoco2.com/accounts/privacy-policy/669a19b2eb2e4adb81078d84077ca21c'

const emits = defineEmits(['update:modelValue', 'update:visible'])
const props = defineProps({
  modelValue: {
    type: Array as PropType<TrackingServiceConsent[]>,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
})

const localValue = ref<TrackingServiceConsent[]>(props.modelValue)
const localVisible = ref<boolean>(props.visible)
const trackingServices = computed<TrackingService[]>(() =>
  localValue.value.map((s) => {
    const label = SERVICES.some((srv) => srv.service === s.name)
      ? SERVICES.filter((srv) => srv.service === s.name)[0].label
      : s.name
    return {
      label,
      name: s.name,
      consent: s.consent,
      reviewed: s.reviewed,
    }
  }),
)

watch(
  props,
  (p) => {
    localVisible.value = p.visible
  },
  { deep: true },
)
watch(localValue, (value) => emits('update:modelValue', value), { deep: true })
watch(localVisible, (value) => emits('update:visible', value))

const setConsent = (value: boolean, service = '') => {
  // global consent
  if (!service)
    localValue.value = localValue.value.map((service) => {
      service.consent = value
      service.reviewed = true
      return service
    })
  // by-service consent
  else
    localValue.value = localValue.value.map((s) => {
      if (s.name === service) {
        s.consent = value
        s.reviewed = true
      }
      return s
    })
}
</script>

<template>
  <UDialog v-model="localVisible">
    <h2 class="mt-6 mb-10 txt-center bold u-txt-3xl">Gestion des cookies</h2>
    <div class="panel-notice py-4 mb-3">
      En autorisant ces services tiers, vous acceptez le dépôt et la lecture de cookies et l'utilisation de technologies
      de suivi nécessaires à leur bon fonctionnement.
    </div>
    <div class="panel-preferences py-4 mb-4 flex flex-column items-center">
      <div class="bold mb-2">Préférences pour tous les services</div>
      <div class="flex gap-2">
        <UButton @click="setConsent(false)" text="Refuser" type="ghost" size="small" data-test="deny-cookies-button" />
        <UButton @click="setConsent(true)" text="Autoriser" size="small" data-test="accept-cookies-button" />
      </div>
    </div>
    <div class="services-preferences flex flex-column items-center">
      <div class="service-row w-full flex content-between">
        <div class="service-name flex content-center items-center py-4 px-4 u-txt-sm bold">
          <p>Cookies obligatoires</p>
        </div>
        <div class="service-content py-4 px-4 u-txt-sm">
          <p>Ce site utilise des cookies nécessaires à son bon fonctionnement. Ils ne peuvent pas être désactivés.</p>
        </div>
      </div>
      <div class="service-row w-full flex content-between">
        <div class="service-name flex content-center items-center py-4 px-4 u-txt-sm bold">
          <p>Mesure d'audience</p>
        </div>
        <div class="service-content u-txt-sm">
          <div
            v-for="(service, i) in trackingServices"
            :key="i"
            class="service-card py-4 px-4 flex items-center content-between"
          >
            <div class="service-info">
              <p class="u-txt-lg bold underline mb-1">{{ service.label }}</p>
              <p>
                <span>{{ service.reviewed && service.consent ? 'Autorisé' : 'Interdit' }}</span>
                -
                <a :href="COOKIES_POLICY_URL" target="_blank">En savoir plus</a>
              </p>
            </div>
            <div class="service-consent flex gap-2">
              <UButton
                @click="setConsent(false, service.name)"
                text="Refuser"
                size="small"
                data-test="deny-mtm-cookies-button"
                :class="{ active: service.reviewed && !service.consent }"
              />
              <UButton
                @click="setConsent(true, service.name)"
                text="Autoriser"
                size="small"
                data-test="accept-mtm-cookies-button"
                :class="{ active: service.reviewed && service.consent }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </UDialog>
</template>

<style lang="scss" scoped>
.panel-notice {
  border-top: 1px solid var(--grey-light);
  border-bottom: 1px solid var(--grey-light);
}
.services-preferences {
  border-top: 1px solid var(--grey-light);

  .service-row {
    border-bottom: 1px solid var(--grey-light);
    border-right: 1px solid var(--grey-light);
  }
  .service-name {
    flex: 0 0 20%;
    background-color: var(--dark);
    color: var(--white);
  }
  .service-content {
    flex: 0 0 80%;
  }
  .service-card {
    border-bottom: 1px solid var(--grey-extralight);
  }
  .service-consent button {
    height: 40px;
    color: var(--white);
    background-color: var(--grey-dark);

    &.active,
    &.active:hover {
      background-color: var(--green) !important;
    }
  }
  .service-consent button:hover {
    color: var(--white) !important;
    background-color: var(--grey) !important;
  }
}
</style>
