import { get, patch, post } from '@/helpers/http'
import { APIMission, MissionPatchPayload, MissionPostPayload } from '@/types/api/ApiMission'

export async function createMission(siteID: number, params: MissionPostPayload): Promise<APIMission[]> {
  return (await post(`action-plan/mission/site/${siteID}/`, params, {})) as APIMission[]
}

export async function fetchMissions(siteID: number): Promise<APIMission[]> {
  return (await get(`action-plan/mission/site/${siteID}/`)) as APIMission[]
}

export async function partialUpdateMission(missionID: number, params: MissionPatchPayload): Promise<APIMission[]> {
  return (await patch(`action-plan/mission/${missionID}/`, params, {})) as APIMission[]
}
