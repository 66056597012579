<template>
  <div class="filter-selector">
    <UDropdown v-model="currentPrograms" multiple class="min-w-300" @update:model-value="onUpdate">
      <template #title>
        <div class="ellipsis max-w-200">
          {{ currentPrograms.length ? currentPrograms.map((p) => p.name).join(', ') : $t('support.filters.programs') }}
        </div>
      </template>
      <UOption
        v-for="program in programs"
        v-slot="{ isSelected }"
        :value="program"
        :key="program.id"
        class="py-2 px-4 ellipsis flex-gap items-center"
      >
        <span class="square" :class="{ isSelected }">
          <IconChecked v-if="isSelected" :size="24" color="var(--white)" />
        </span>
        {{ program.name }}
      </UOption>
    </UDropdown>
    <UDropdown v-model="currentTerritories" multiple class="min-w-300" @update:model-value="onUpdate">
      <template #title>
        <div class="ellipsis max-w-200">
          {{
            currentTerritories.length
              ? currentTerritories.map((p) => p.name).join(', ')
              : $t('support.filters.territories')
          }}
        </div>
      </template>
      <UOption
        v-for="territory in territories"
        :value="territory"
        :key="territory.id"
        v-slot="{ isSelected }"
        class="py-2 px-4 ellipsis flex-gap items-center"
      >
        <span class="square" :class="{ isSelected }">
          <IconChecked v-if="isSelected" :size="24" color="var(--white)" />
        </span>
        {{ territory.name }}
      </UOption>
    </UDropdown>
  </div>
</template>

<script>
import deepEquals from 'fast-deep-equal'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import UOption from '@/components/Select/UOption.vue'
import UDropdown from '@/components/UDropdown.vue'
import IconChecked from '@/components/icons/IconChecked.vue'
import { clean } from '@/helpers/objects'

export default {
  name: 'FilterSelector',
  components: {
    UDropdown,
    UOption,
    IconChecked,
  },
  computed: {
    ...mapGetters('filters', [
      'loadingTerritories',
      'loadingPrograms',
      'filters',
      'programs',
      'territories',
      'queryFilter',
    ]),
    ...mapGetters('filters', ['queryUrl']),
    currentPrograms: {
      get() {
        return this.filters.programs
      },
      set(value) {
        this.selectPrograms(value)
      },
    },
    currentTerritories: {
      get() {
        return this.filters.territories
      },
      set(value) {
        this.selectTerritories(value)
      },
    },
  },
  methods: {
    ...mapActions('filters', ['loadTerritories', 'loadPrograms']),
    ...mapMutations('filters', ['selectPrograms', 'selectTerritories']),
    onUpdate() {
      const query = this.queryUrl
      if (
        deepEquals(this.$route.query.territories, query.territories) &&
        deepEquals(this.$route.query.programs, query.programs)
      ) {
        return
      }
      this.$router
        .replace({
          query: clean({
            ...this.$route.query,
            territories: null,
            programs: null,
            ...this.queryUrl,
          }),
        })
        .catch((e) => e)
    },
  },
  created() {
    this.loadTerritories()
    this.loadPrograms()
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';
.flex-gap,
.filter-selector {
  display: flex;
  gap: 1em;
}

.items-center {
  align-items: center;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.max-w-200 {
  max-width: 96px;
}
.min-w-300 {
  min-width: 128px;
}
@media screen and (min-width: $screen_s) {
  .max-w-200 {
    max-width: 200px;
  }
  .min-w-300 {
    min-width: 300px;
  }
}
.square {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--black);
  border-radius: 2px;
}
.square.isSelected {
  background-color: var(--green-dark);
  border: none;
}
</style>
