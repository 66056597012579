import { createStore } from 'vuex'
import { production } from '@/helpers/env'
import { aclModule } from '@/store/vuex/acl'
import { alertsModule } from '@/store/vuex/alerts'
import { authModule } from '@/store/vuex/auth'
import { campaignsModule } from '@/store/vuex/campaigns'
import { comfortModule } from '@/store/vuex/comfort'
import { contractsModule } from '@/store/vuex/contracts'
import { ecotipsModule } from '@/store/vuex/ecotips'
import { filtersModule } from '@/store/vuex/filters'
import { impersonateModule } from '@/store/vuex/impersonate'
import { kitsModule } from '@/store/vuex/kits'
import { missionsModule } from '@/store/vuex/missions'
import { notificationsModule } from '@/store/vuex/notifications'
import { responsiveModule } from '@/store/vuex/responsive'
import { schedulesModule } from '@/store/vuex/schedules'
import { siteModule } from '@/store/vuex/site'
import { sitesModule } from '@/store/vuex/sites'
import { sourcesModule } from '@/store/vuex/sources'
import { userModule } from '@/store/vuex/user'
import { wrap } from './wrap'

export const store = createStore({
  strict: production,
  modules: {
    responsive: responsiveModule,
    site: siteModule,
    auth: authModule,
    acl: aclModule,
    missions: missionsModule,
    alerts: alertsModule,
    sources: sourcesModule,
    comfort: comfortModule,
    impersonate: impersonateModule,
    ecotips: ecotipsModule,
    notifications: notificationsModule,
    campaigns: campaignsModule,
    kits: kitsModule,
    schedules: schedulesModule,
    user: userModule,

    ...wrap('contracts', contractsModule),
    ...wrap('filters', filtersModule),
    ...wrap('sites', sitesModule),
  },
})
