import { router } from '@/plugins/router'
import { store } from '@/store/vuex/index'

export async function logout() {
  // impersonating: get back to real user
  if (store.getters['impersonate/impersonating']) {
    window.location.href = '/'
    return
  }
  // real user: logout
  if (store.getters['user/user']) await store.dispatch('auth/logout')
  router.push({ name: 'home' })
}
