<template>
  <div class="u-input" :class="classes">
    <label v-if="$slots.default" :for="uniqueId" class="u-txt-base bold">
      <slot />
    </label>
    <label v-else-if="label" :for="uniqueId" class="u-txt-base bold">
      {{ label }}
      <UTooltip v-if="tooltip" content-icon="notice" :text="tooltip" />
    </label>

    <div class="input-wrapper">
      <AsyncIcon v-if="hasIcon" :name="iconType" :size="24" :color="iconColor" @click="focus" />
      <input
        ref="input"
        :aria-label="ariaLabel"
        :value="localValue"
        :type="inputType"
        :inputmode="inputMode"
        :id="uniqueId"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="name"
        :minlength="minlength"
        :pattern="pattern"
        :max="max"
        :min="min"
        :autocomplete="localAutoComplete"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange"
        @keyup.enter="onSubmit"
      />
      <p v-if="unit" class="unit" @click="focus">{{ unit }}</p>
      <UButton v-if="button" :text="button" @click="onSubmit" :disabled="disabledBtn" />

      <slot name="date-picker" />
    </div>

    <div v-if="message && status" class="message u-txt-sm bold">
      <p v-html="message" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AsyncIcon from '@/components/icons/AsyncIcon.vue'
import UButton from '@/components/ui/UButton.vue'
import UTooltip from '@/components/ui/UTooltip.vue'

let id = 0

export default {
  name: 'UInput',
  components: {
    AsyncIcon,
    UButton,
    UTooltip,
  },
  emits: ['update:modelValue', 'submit', 'focus', 'blur', 'change'],
  props: {
    modelValue: {
      type: [String, Number],
    },
    type: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    icon: {
      type: String,
    },
    status: {
      type: String,

      validator: (value) => ['success', 'error', 'info', 'warning'].includes(value),
    },
    message: {
      type: String,
    },
    unit: {
      type: String,
    },
    maxlength: {
      type: [Number, String],
    },
    minlength: {
      type: Number,
    },
    max: {
      type: [String, Number],
    },
    min: {
      type: [String, Number],
    },
    pattern: {
      type: String,
    },
    /**
     * Only this characters are allowed
     */
    restrict: {
      type: [String, RegExp],
    },
    // input + cta button
    button: {
      type: String,
    },
    // Only for input with cta
    disabledBtn: {
      type: Boolean,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    inputmode: {
      type: String,

      validator: (v) => ['none', 'text', 'decimal', 'numeric', 'tel', 'search', 'email', 'url'].includes(v),
    },
    tooltip: {
      type: String,
    },
    /* Browers autocomplete */
    autocomplete: {
      type: [String, Boolean],
      default: 'on',
    },
    ariaLabel: {
      type: String,
    },
  },
  data() {
    return {
      focused: false,
      localValue: null,
      uniqueId: null,
    }
  },
  created() {
    this.uniqueId = 'auto-' + this.$options.name + '-' + id++
  },
  mounted() {
    if (this.autofocus && this.$refs.input && !this.isMobile) {
      // check for parent running animation => focus can break the animation forcing to scroll
      this.$refs.input.focus({
        preventScroll: true,
      })
    }
  },
  computed: {
    ...mapGetters('responsive', ['isMobile']),
    inputType() {
      return this.type !== 'number' ? this.type : 'text'
    },
    inputMode() {
      if (this.inputmode) {
        return this.inputmode
      }
      return this.type === 'number' ? 'decimal' : null
    },

    hasIcon() {
      return this.icon || this.type === 'search'
    },
    iconType() {
      if (this.icon) {
        return this.icon
      }
      if (this.type === 'search') {
        return 'search'
      }
      return null
    },
    iconColor() {
      if (this.disabled) {
        return '--grey'
      }
      return null
    },
    classes() {
      const classes = []
      if (this.status) {
        classes.push('u-' + this.status)
      }
      if (this.disabled) {
        classes.push('u-disabled')
      }
      if (this.unit) {
        classes.push('u-unit')
      }
      if (this.focused) {
        classes.push('u-focus')
      }
      if (this.button) {
        classes.push('u-button')
      }
      return classes
    },
    maxLengthInteger() {
      if (!this.maxlength) return null
      return parseInt(this.maxlength)
    },
    localAutoComplete() {
      if (
        this.autocomplete.toLowerCase() === 'true' ||
        this.autocomplete.toLowerCase() === 'on' ||
        this.autocomplete === true
      ) {
        return 'on'
      }
      if (
        this.autocomplete.toLowerCase() === 'false' ||
        this.autocomplete.toLowerCase() === 'off' ||
        this.autocomplete === false
      ) {
        return 'off'
      }
      return this.autocomplete
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.localValue = this.modelValue
      },
      immediate: true,
    },
  },
  methods: {
    onInput(event) {
      if (!this.$refs.input) {
        return
      }
      const value = this.valueFormatted(event.target.value)
      if (value !== event.target.value) {
        this.$refs.input.value = value
      }
      if (value === this.modelValue) return
      this.localValue = value
      this.$emit(
        'update:modelValue',
        this.type === 'number' && this.localValue !== '-' ? Number(this.localValue) : this.localValue,
      )
    },
    onFocus(event) {
      this.focused = true
      this.$emit('focus', event)
    },
    onBlur(event) {
      this.focused = false
      this.$emit('blur', event)
    },
    onSubmit() {
      this.$emit('submit', this.localValue)
    },
    onChange(event) {
      this.onInput(event)
      this.$emit('change', event)
    },
    focus() {
      if (!this.$refs.input) return
      this.$refs.input.focus({ preventScroll: true })
    },
    valueFormatted(value) {
      if (this.inputMode === 'decimal') {
        value = value.replace(',', '.')
      }
      if (this.restrict) {
        if (typeof this.restrict === 'string') {
          const reg = new RegExp(`[^${this.restrict}$]`, 'g')
          value = value.replace(reg, '')
        } else {
          value = value.replace(this.restrict, '')
        }
      }
      if (this.maxLengthInteger && value && `${value}`.length > this.maxLengthInteger) {
        // Handle maxlenght for input type number
        return value.slice(0, this.maxLengthInteger)
      }
      return value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

$input-padding: 32px;
$input-padding-s: 5vw;
$input-radius: 12px;

.u-input {
  display: grid;
  gap: 0.15em;
  width: 390px;
  @media screen and (max-width: $screen_s) {
    width: 100%;
  }

  /* Contains input, icon and unit */
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $u-input-height;
    border: $u-input-border-width solid $grey-light;
    border-radius: $input-radius;
    padding: 0px $input-padding;
    transition: border-color ease 300ms;
    background-color: $white;
    @media screen and (max-width: $screen_s) {
      padding: 0px $input-padding-s;
    }
  }
  &:not(.u-disabled):not(.u-success):not(.u-error):not(.u-info):not(.u-warning) .input-wrapper {
    &:hover,
    &:focus {
      border-color: $grey;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 0.15em;
  }
  input {
    flex: 1;
    padding: 10px 0;
    @include u-txt-base;
    color: $grey-darken;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  ::placeholder {
    color: $grey;
  }
  .unit {
    color: $grey-dark-low;
    @include u-txt-base;
    margin: 0 0 0 18px;
  }
  .message {
    min-height: calc(0.875rem * 1.5); // Fontsize * line-height
  }
  &.u-focus .input-wrapper {
    border-color: $grey;
  }
  &.u-disabled {
    opacity: 1;
    .input-wrapper {
      background-color: $grey-lighten;
    }
  }
  .u-icon {
    margin-right: 18px;
  }
  &.u-unit {
    input,
    .message {
      text-align: right;
    }
  }
  &.u-error {
    &:not(.u-button) .input-wrapper {
      border-color: $red;
    }
    .message {
      color: $red;
    }
  }
  &.u-success {
    &:not(.u-button) .input-wrapper {
      border-color: $green-darken;
    }
    .message {
      color: $green-darken;
    }
  }
  &.u-info {
    .message {
      color: $grey-dark;
      :deep(a) {
        color: $grey-dark;
      }
    }
  }
  &.u-warning {
    &:not(.u-button) .input-wrapper {
      border-color: $orange;
    }
    .message {
      color: $orange;
      :deep(a) {
        color: $orange;
      }
    }
  }
  &.u-button {
    .input-wrapper {
      padding-right: 0;
      input {
        padding-right: 0.5em;
      }
      button {
        border-radius: 0 $input-radius $input-radius 0;
        height: calc(100% + 2 *#{$u-input-border-width});
        transform: translateX($u-input-border-width);
      }
    }
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1.3em;
    width: 1.3em;
    border-radius: 50em;
    background: url('~@/assets/svg/icons/close.svg') no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }
  input[type='search']:focus::-webkit-search-cancel-button {
    opacity: 0.3;
    pointer-events: all;
    cursor: pointer;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-datetime-edit-day-field:focus,
  input::-webkit-datetime-edit-month-field:focus,
  input::-webkit-datetime-edit-year-field:focus {
    background-color: $green;
    color: $dark;
    outline: none;
  }
}
</style>
