<template>
  <div class="u-autocomplete" @mousedown="onOpen" :class="classes">
    <label v-if="label" class="u-txt-base bold label-title ellipsis">
      {{ label }}
      <UTooltip v-if="tooltip" content-icon="notice" :text="tooltip" />
    </label>
    <div class="u-autocomplete-container" :class="{ opened: opened, hasData: hasData }" ref="container">
      <UInput
        v-if="opened"
        v-model="localSearch"
        @update:model-value="onSearchInput"
        @focus="clearInputStatus"
        @blur="onBlur"
        :autofocus="autofocus"
        ref="input"
        :message="inputMessage"
        :status="inputStatus"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="off"
      />
      <label v-else ref="label" class="u-txt-base ellipsis" :class="{ hasValue: selectedLabel }">
        {{ placeholderValue }}
      </label>
      <template v-if="opened && hasData">
        <!-- Fixed -->
        <Teleport v-if="fixed" to="body">
          <ul ref="list" :style="style" class="u-list-autocomplete u-list-autocomplete-fixed">
            <UAutoCompleteItem
              v-for="(item, index) in formatedList"
              :key="index"
              :icon="item.icon"
              :selected="equals(modelValue, item.value)"
              :disabled="item.disabled || disabled"
              :text="item.label"
              @click.stop="onInput(item.value, index)"
              :key-selected="indexSelected == index"
            >
              <slot v-bind="item" name="item">{{ item.label }}</slot>
            </UAutoCompleteItem>
          </ul>
        </Teleport>

        <ul v-else ref="list" :style="style" class="u-list-autocomplete">
          <UAutoCompleteItem
            v-for="(item, index) in formatedList"
            :key="index"
            :icon="item.icon"
            :selected="equals(modelValue, item.value)"
            :disabled="item.disabled || disabled"
            :text="item.label"
            @click.stop="onInput(item.value, index)"
            :key-selected="indexSelected == index"
          >
            <slot v-bind="item" name="item">{{ item.label }}</slot>
          </UAutoCompleteItem>
        </ul>
      </template>
      <UCircleLoader v-if="loading" :size="24" class="overlay" />
    </div>
    <div class="message u-txt-sm bold">
      <template v-if="inputMessage && inputStatus">
        <p v-html="inputMessage" />
      </template>
    </div>
  </div>
</template>

<script>
import deepEquals from 'fast-deep-equal'
import hotkeys from 'hotkeys-js'
import UTooltip from '@/components/ui/UTooltip.vue'
import UAutoCompleteItem from '@/components/ui/autocompletes/UAutoCompleteItem.vue'
import UInput from '@/components/ui/form/UInput.vue'
import { includes } from '@/helpers/objects'
import UCircleLoader from './UCircleLoader.vue'

export default {
  name: 'UAutocomplete',
  emits: ['update:modelValue', 'search', 'update:search', 'blur', 'focus'],
  components: { UAutoCompleteItem, UInput, UTooltip, UCircleLoader },
  props: {
    list: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: ' ',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOnSelect: {
      type: Boolean,
      default: true,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      required: false,
      validator: (value) => ['success', 'error', 'info'].includes(value),
    },
    message: {
      type: String,
      required: false,
    },
    comparison: {
      type: String,
      default: 'strict',
      validator: (value) => ['strict', 'full', 'partial'].includes(value),
    },
    maxHeight: {
      type: String,
      required: false,
      default: null,
    },
    tooltip: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowCustom: {
      doc: 'allow custom user value',
      type: Boolean,
      default: false,
    },
    labelItem: {
      type: String,
      default: 'label',
    },
  },
  data() {
    return {
      opened: false,
      x: 0,
      y: 0,
      localSearch: null,
      inputMessage: null,
      inputStatus: null,
      indexSelected: null,
    }
  },
  created() {
    this.localSearch = this.search
    this.updateStatus()
  },
  mounted() {
    if (this.autofocus) {
      this.onOpen()
    }
  },
  computed: {
    formatedList() {
      return this.list.map((item) => ({ label: item.name || item.label, value: item }))
    },
    selectedLabel() {
      const selected = this.formatedList.find((e) => e.value === this.modelValue)
      if (selected) {
        return selected.label
      }
      if (this.modelValue) {
        if (this.labelItem) {
          if (this.modelValue[this.labelItem]) {
            return this.modelValue[this.labelItem]
          }
        }
        if (this.modelValue?.name) {
          return this.modelValue.name
        } else {
          return this.modelValue
        }
      }
      if (this.allowCustom && this.localSearch) {
        return this.localSearch
      }
      return ''
    },
    placeholderValue() {
      if (this.selectedLabel) {
        return this.selectedLabel
      }
      return this.placeholder
    },
    style() {
      if (!this.fixed) {
        return {
          maxHeight: this.maxHeight,
        }
      }
      return {
        maxHeight: this.maxHeight,
        left: `${this.x}px`,
        top: `${this.y}px`,
      }
    },
    classes() {
      const classes = []
      if (this.status) {
        classes.push('u-' + this.status)
      }
      if (this.disabled) {
        classes.push('u-disabled')
      }
      return classes
    },
    hasData() {
      return !!this.formatedList?.length
    },
  },
  methods: {
    equals(value1, value2) {
      if (this.comparison === 'strict') {
        return value1 === value2
      }
      if (this.comparison === 'full') {
        return deepEquals(value1, value2)
      }
      if (this.comparison === 'partial') {
        return includes(value1, value2)
      }
      return value1 === value2
    },
    onMouseDown(event) {
      if (event.target && event.target !== window) {
        if (this.$refs.container && this.$refs.container.contains(event.target)) {
          return (this.clickingOutside = false)
        }
        if (this.$refs.list && this.$refs.list.contains(event.target)) {
          return (this.clickingOutside = false)
        }
      }
      this.clickingOutside = true
    },
    onMouseUp(event) {
      if (!this.clickingOutside) {
        this.refocus()
        return
      }
      if (event.target && event.target !== window) {
        if (this.$refs.container && this.$refs.container.contains(event.target)) {
          this.refocus()
          return (this.clickingOutside = false)
        }
        if (this.$refs.list && this.$refs.list.contains(event.target)) {
          this.refocus()
          return (this.clickingOutside = false)
        }
      }
      this.clickingOutside = true
    },
    refocus() {
      if (this.$refs?.input?.$refs?.input) {
        this.$refs.input.$refs.input.focus()
      }
    },
    onOpen() {
      if (this.opened || this.disabled) {
        return
      }
      this.opened = true
      this.listenKeys()
      this.listenOutside()
      this.listenScroll()
    },
    onInput(value, index) {
      this.onValue(value, index)
    },
    onSearchInput() {
      this.onOpen()
      this.localValue = null
      this.$emit('update:search', this.localSearch)
      this.$emit('search', this.localSearch)
    },
    onValue(value, index) {
      if (this.hideOnSelect) {
        this.hide()
      }
      if (this.disabled) {
        return
      }
      if (value === this.modelValue) {
        return // don't emit input if v-model has already same value
      }
      this.$emit('update:modelValue', value, index)
    },
    onClickOutside(event) {
      if (!this.clickingOutside) {
        return // mousedown was not outside
      }
      if (event.target && event.target !== window) {
        if (this.$refs.container && this.$refs.container.contains(event.target)) {
          return
        }
        if (this.$refs.list && this.$refs.list.contains(event.target)) {
          return
        }
      }
      if (!this.localValue) {
        this.$emit('update:modelValue', null)
      }
      this.hide()
    },
    onScroll() {
      if (!this.$refs?.label) {
        this.x = 0
        this.y = 0
        return
      }
      const bounding = this.$refs.label.getBoundingClientRect()
      const leftBounding = this.$el.getBoundingClientRect()
      this.x = leftBounding.left
      this.y = bounding.top + bounding.height
    },
    onKeyDown(event) {
      if (!['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
        return
      }
      // arrows
      if (event.key !== 'Enter') {
        if (event.key === 'ArrowDown') {
          this.indexSelected++
        } else {
          this.indexSelected--
        }
        if (this.indexSelected >= this.formatedList.length) {
          this.indexSelected = 0
        }
        if (this.indexSelected < 0) {
          this.indexSelected = this.formatedList.length - 1
        }
      } else {
        if (this.formatedList[this.indexSelected]) {
          // enter key
          this.onInput(this.formatedList[this.indexSelected].value, this.indexSelected)
        }
      }
      event.preventDefault()
      return false
    },
    hide() {
      this.opened = false
      this.clearListeners()
      this.clearKeysListeners()
      this.updateStatus()
      this.localSearch = ''
    },
    listenOutside() {
      this.clearListeners()
      if (this.opened) {
        window.addEventListener('mousedown', (this.mousedownListener = this.onMouseDown.bind(this)), { capture: true })
        window.addEventListener('mouseup', (this.mouseupListener = this.onMouseUp.bind(this)), { capture: true })
        window.addEventListener('click', (this.clickListener = this.onClickOutside.bind(this)), { capture: true })
        hotkeys('escape', (this.keyListener = this.hide.bind(this)))
      }
    },
    listenKeys() {
      this.clearKeysListeners()
      if (this.opened && !this.isMobile) {
        this.indexSelected = this.formatedList.findIndex((e) => this.equals(this.modelValue, e.value))
        document.addEventListener('keydown', (this.keysListener = this.onKeyDown.bind(this)), { capture: true })
      }
    },
    listenScroll() {
      this.clearScrollListeners()
      if (this.fixed && this.opened) {
        window.addEventListener('scroll', (this.scrollListener = this.onScroll.bind(this)), {
          capture: true,
          passive: true,
        })
        window.addEventListener('resize', (this.resizeListener = this.onScroll.bind(this)), {
          capture: true,
          passive: true,
        })
        this.onScroll()
      }
    },
    clearScrollListeners() {
      if (this.scrollListener) {
        window.removeEventListener('scroll', this.scrollListener, { capture: true, passive: true })
        this.scrollListener = null
      }
      if (this.resizeListener) {
        window.removeEventListener('resize', this.resizeListener, { capture: true, passive: true })
        this.resizeListener = null
      }
    },
    clearKeysListeners() {
      if (this.keysListener) {
        document.removeEventListener('keydown', this.keysListener, { capture: true })
        this.keysListener = null
      }
    },
    clearListeners() {
      if (this.clickListener) {
        window.removeEventListener('click', this.clickListener, { capture: true })
        this.clickListener = null
      }
      if (this.mousedownListener) {
        window.removeEventListener('mousedown', this.mousedownListener, { capture: true })
        this.mousedownListener = null
      }
      if (this.mouseupListener) {
        window.removeEventListener('mouseup', this.mouseupListener, { capture: true })
        this.mouseupListener = null
      }
      if (this.keyListener) {
        hotkeys.unbind('escape', this.keyListener)
        this.keyListener = null
      }
      this.clearScrollListeners()
    },
    clearInputStatus() {
      this.inputMessage = null
      this.inputStatus = null
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    },
    updateStatus() {
      this.inputMessage = this.message
      this.inputStatus = this.status
    },
  },
  beforeUnmount() {
    this.clearListeners()
    this.clearKeysListeners()
  },
  watch: {
    fixed() {
      this.listenScroll()
    },
    search() {
      this.localSearch = this.search
    },
    localSearch(value) {
      this.$emit('update:search', value)
      this.$emit('search', value)
    },
    modelValue: {
      handler() {
        this.localValue = this.modelValue
      },
      immediate: true,
    },
    message() {
      this.updateStatus()
    },
    status() {
      this.updateStatus()
    },
    hasData() {
      if (!this.hasData) {
        this.inputMessage = this.$t('shared.generic.no_results')
        this.inputStatus = 'info'
        return
      }
      this.updateStatus()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

.u-autocomplete {
  width: $u-autocomplete-width;
  display: grid;
  gap: 0.15em;
  @media screen and (max-width: $screen_s) {
    width: 100%;
    .u-list-autocomplete {
      width: 100%;
    }
  }

  .message {
    min-height: calc(0.875rem * 1.5);
  }

  &.u-error {
    .u-autocomplete-container:not(.opened) {
      border-color: $red;
    }
    .message {
      color: $red;
    }
  }

  &.u-disabled {
    .u-autocomplete-container {
      background-color: $grey-lighten;
      &:hover {
        border: $u-autocomplete-border-width solid $grey-light;
        cursor: default;
      }
      label:hover {
        cursor: default;
      }
    }
  }
  label.label-title {
    margin-bottom: 0.25em;
    display: block;
  }

  .u-autocomplete-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $u-autocomplete-height;
    label.u-txt-base {
      color: $grey;
      &.hasValue {
        color: $grey-darken;
      }
    }
    &:not(.opened) {
      border: $u-autocomplete-border-width solid $grey-light;
      border-radius: $u-autocomplete-radius;
    }
    transition: border-color ease 300ms;
    background-color: $white;
    cursor: pointer;
    .u-input {
      flex: 1;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &:hover {
      border-color: $grey;
    }
    &.opened {
      border-color: $grey;
      border-radius: $u-autocomplete-radius $u-autocomplete-radius 0 0;
      label {
        font-weight: 500;
      }
      &.hasData {
        :deep() {
          .u-input {
            .input-wrapper {
              border-color: $grey;
              border-radius: $u-dropdown-radius $u-dropdown-radius 0 0;
            }
          }
        }
      }
    }

    label {
      // flex: 1;
      padding: 12px 0;
      color: $grey-darken;
      width: 100%;
      cursor: pointer;
      margin-right: 2em;
      margin-left: 2em;
      white-space: nowrap;
      @media screen and (max-width: $screen_s) {
        margin-right: 1.15em;
        margin-left: 1.15em;
        flex: 1;
        width: 150px;
      }
      &:focus {
        outline: none;
      }
    }

    .u-list-autocomplete {
      position: absolute;
      z-index: 5;
      top: calc(#{$u-autocomplete-height});
      width: calc(100%);
      overflow-y: auto;
      max-height: 500px;
    }

    .u-icon.overlay {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-50%, 50%);
    }
  }
}
</style>
<style lang="scss">
@import '@/scss/_imports.scss';

.u-list-autocomplete {
  background-color: $white;
  border: $u-autocomplete-border-width solid $grey;
  border-top: none;
  border-radius: 0 0 $u-autocomplete-radius $u-autocomplete-radius;
  width: $u-autocomplete-width;
  overflow: hidden;

  &.u-list-autocomplete-fixed {
    position: fixed;
  }
}
</style>
