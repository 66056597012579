import dayjs from 'dayjs'
import { RouteLocation } from 'vue-router'
import { anonymize } from '@/helpers/url'
import { AccountType } from '@/types/api/User'
import { MatomoDataLayer, MatomoTrackingData } from '@/types/tracking'

type DataLayerItem = Record<string, unknown> | string[]

declare global {
  interface Window {
    Matomo: unknown
    _mtm: DataLayerItem[]
  }
}

function formatDataLayer(data: MatomoTrackingData, route: RouteLocation): MatomoDataLayer {
  const anonymized = {
    params: ['siteId'],
    query: ['user'],
  }
  const url = `${window.location.origin}${anonymize(route, anonymized)}`
  const creationDt = data.user?.creation_datetime ? dayjs(data.user.creation_datetime).format('DD/MM/YYYY') : null
  const firstDataDt = data.site?.first_data_datetime ? dayjs(data.site.first_data_datetime).format('DD/MM/YYYY') : null
  const role = data.user?.role
    ? {
        USER_ROLE_NAME: 'Utilisateur',
        PARTNER_ACCESS_ROLE_NAME: 'Partenaire',
        ASSOCIATION_ROLE_NAME: 'Association',
        PROGRAM_MANAGER_ROLE_NAME: 'Responsable Programme',
        API_ACCESS_ROLE_NAME: 'Accès API export',
        SUPPORT_ROLE_NAME: 'Support Eco CO2',
      }[data.user.role]
    : null
  const userType = data.user?.type
    ? ({
        PRIVATE: 'particulier',
        PROFESSIONAL: 'professionnel',
      }[data.user.type] as AccountType)
    : null
  const programs = data.user?.programs
    ? data.user.programs.map((prg) => `${prg.charAt(0).toUpperCase()}${prg.substring(1)}`).join(', ')
    : null
  let territory = data.user?.territory ? data.user.territory.replace('_', ' ') : null
  territory = territory ? `${territory.charAt(0).toUpperCase()}${territory.substring(1)}` : null
  const impersonate =
    data.impersonate !== null && data.impersonate !== undefined ? (data.impersonate ? 'oui' : 'non') : null
  const consent = data.cookie_consent !== undefined ? (data.cookie_consent ? 'oui' : 'non') : null
  return {
    // global
    page_url: url,
    page_title: document.title,
    // user
    visiteur_id: data.user?.id || null,
    role: role || null,
    type: userType,
    date_creation_compte: creationDt,
    programme: programs,
    territoire: territory,
    impersonate,
    // site
    site: data.site?.name || null,
    date_premiere_donnee: firstDataDt,
    secteur_activite: data.site?.company_category || null,
    materiel: data.site?.device_manufacturers?.replace(/\|/gi, ', ') || null,
    cookie_consent: consent,
  }
}
function tag(dataLayer: DataLayerItem): void {
  window._mtm = window._mtm ?? []
  window._mtm.push(dataLayer)
}
export function triggerEvent(name: string): void {
  tag({ event: name })
}
export function setDataLayer(data: MatomoTrackingData, route: RouteLocation) {
  tag(formatDataLayer(data, route))
}

const matomo: Record<string, (...args: any[]) => void> = {
  triggerEvent,
  setDataLayer,
}

export default matomo
