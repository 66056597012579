<template>
  <div class="modals">
    <component
      v-bind="modal.options"
      v-for="modal in modals"
      :is="modal.component"
      :key="modal.$id"
      @action="onAction(modal, $event)"
      @close="onClose(modal, $event)"
    />
  </div>
</template>

<script>
import { self } from '@/helpers/promises'
import { events } from './utils/events'

export default {
  name: 'UModals',
  data() {
    return {
      modals: [],
    }
  },
  props: {
    components: {
      type: Object,
    },
  },
  created() {
    this.id = 1
    events.on('modal', (this.listener = this.add.bind(this)))
  },
  methods: {
    add(modal) {
      Object.defineProperty(modal, '$id', {
        enumerable: false,
        writable: false,
        value: this.id++,
      })
      Object.defineProperty(modal, 'componentName', {
        enumerable: false,
        writable: false,
        value: modal.component,
      })
      Object.defineProperty(modal, 'promise', {
        enumerable: false,
        writable: false,
        value: self(),
      })
      const component = this.components[modal.component]
      if (!component) {
        console.error(`${modal.component} is not found - did you registered it correclty ?`)
        return
      }
      modal.component = component
      if (modal.options?.unique) {
        this.modals
          .filter((m) => m.componentName === modal.componentName)
          .forEach((modal) => {
            this.onAction(modal, { type: 'cancel' })
          })
      }
      this.modals.push(modal)
    },
    remove(modal) {
      const index = this.modals.indexOf(modal)
      if (~index) {
        this.modals.splice(index, 1)
      }
    },
    onAction(modal, { type, event }) {
      if (event?.defaultPrevented) {
        return
      }
      if (type === 'cancel') {
        modal.promise.reject(type)
        return this.remove(modal)
      }
      modal.promise.resolve(type)
      this.remove(modal)
    },
    onClose(modal) {
      modal.promise.resolve('close')
      return this.remove(modal)
    },
  },
  beforeUnmount() {
    if (!this.listener) {
      return
    }
    events.off('modal', this.listener)
  },
}
</script>

<style lang="scss" scoped>
.modals {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  &:empty {
    display: none;
  }
}
</style>
