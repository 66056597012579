import { DELETE, get, post, put } from '@/helpers/http'
import { minDigits, parseDecimal, sum } from '@/helpers/maths'
import { clean, partial } from '@/helpers/objects'

function totalMinutes(timeslots) {
  const minutes = sum(
    timeslots.map((t) => {
      const start = t.start.hours * 60 + t.start.minutes
      let end = t.end.hours * 60 + t.end.minutes
      if (end <= start) {
        end += 24 * 60
      }
      return end - start
    }),
  )
  return minutes
}

function sortByDate(a, b) {
  if (a.subscriptionDate === b.subscriptionDate) return 0
  return a.subscriptionDate < b.subscriptionDate ? 1 : -1
}

function format(contract) {
  contract.time_slots = contract.time_slots.map((c) => {
    if (c.rate) {
      c.rate = parseFloat(c.rate)
    }
    if (c.rate_capacity_certificate) {
      c.rate_capacity_certificate = parseFloat(c.rate_capacity_certificate)
    }
    return c
  })

  const data = {
    id: contract.id,
    providerId: contract.provider_id,
    contractType: contract.contract_type,
    name: contract.name,
    fulfilled: contract.is_fulfilled,
    sources: contract.sources,
    subscriptionPrice: parseFloat(contract.subscription_price),
    subscriptionDate: contract.subscription_date,
    invoice: {
      url: contract.invoice.url,
      name: contract.invoice.name,
      id: contract.invoice.id,
    },
    rates: {
      // base & hphc
      hp: contract.time_slots.find((c) => c.index_type === 'HP')?.rate,
      hc: contract.time_slots.find((c) => c.index_type === 'HC')?.rate,
      base: contract.time_slots.find((c) => c.index_type === 'base')?.rate,
      // pme_pmi
      hpe: contract.time_slots.find((c) => c.index_type === 'HPE')?.rate,
      hce: contract.time_slots.find((c) => c.index_type === 'HCE')?.rate,
      hph: contract.time_slots.find((c) => c.index_type === 'HPH')?.rate,
      hch: contract.time_slots.find((c) => c.index_type === 'HCH')?.rate,
      peak: contract.time_slots.find((c) => c.index_type === 'peak')?.rate,
    },
    ratesCapacity: {
      // pme_pmi
      hpe: contract.time_slots.find((c) => c.index_type === 'HPE')?.rate_capacity_certificate,
      hce: contract.time_slots.find((c) => c.index_type === 'HCE')?.rate_capacity_certificate,
      hph: contract.time_slots.find((c) => c.index_type === 'HPH')?.rate_capacity_certificate,
      hch: contract.time_slots.find((c) => c.index_type === 'HCH')?.rate_capacity_certificate,
      peak: contract.time_slots.find((c) => c.index_type === 'peak')?.rate_capacity_certificate,
    },
    powers: {
      // base & hphc
      hp: contract.time_slots.find((c) => c.index_type === 'HP')?.subscribed_power,
      hc: contract.time_slots.find((c) => c.index_type === 'HC')?.subscribed_power,
      base: contract.time_slots.find((c) => c.index_type === 'base')?.subscribed_power,
      // pme_pmi
      hpe: contract.time_slots.find((c) => c.index_type === 'HPE')?.subscribed_power,
      hce: contract.time_slots.find((c) => c.index_type === 'HCE')?.subscribed_power,
      hph: contract.time_slots.find((c) => c.index_type === 'HPH')?.subscribed_power,
      hch: contract.time_slots.find((c) => c.index_type === 'HCH')?.subscribed_power,
      peak: contract.time_slots.find((c) => c.index_type === 'peak')?.subscribed_power,
    },
    timeslots: contract.time_slots
      .filter((t) => {
        // Only HC time ranges are displayed to the user
        // HP are only for backend to have 24h of time ranges
        if (contract.contract_type === 'HPHC') {
          return t.index_type === 'HC'
        }
        return t
      })
      .map((t) => {
        const start = t.start_time.split(':').map((i) => parseInt(i))
        const end = t.end_time.split(':').map((i) => parseInt(i))
        if (end[0] === 0 && end[1] === 0) {
          end[0] = 24
        }
        return {
          start: { hours: start[0], minutes: start[1], isValid: true },
          end: { hours: end[0], minutes: end[1], isValid: true },
          subscribedPower: t.subscribed_power,
        }
      }),
  }

  let keys = []
  if (data.contractType === 'base' || data.contractType === 'HPHC') {
    keys = ['base', 'hp', 'hc']
  } else if (data.contractType === 'PME_PMI') {
    keys = ['hpe', 'hce', 'hph', 'hch', 'peak']
  }
  data.rates = partial(keys, data.rates)
  data.ratesCapacity = partial(keys, data.ratesCapacity)
  data.powers = partial(keys, data.powers)

  return data
}

export const contractsModule = {
  state: {
    contracts: [],
    providers: [],
    power: null,
  },

  getters: {
    contracts(state) {
      return state.contracts
    },
    providers(state) {
      return state.providers
    },
    getPower(state) {
      return state.power
    },
  },

  mutations: {
    set(state, contracts) {
      state.contracts = contracts.sort(sortByDate)
    },
    add(state, contract) {
      state.contracts.push(contract)
      state.contracts = state.contracts.sort(sortByDate)
    },
    update(state, contract) {
      const index = state.contracts.findIndex((c) => c.id?.toString() === contract.id?.toString())
      if (index > -1) {
        state.contracts.splice(index, 1, Object.assign({}, contract))
        state.contracts = state.contracts.sort(sortByDate)
      }
    },
    delete(state, contractId) {
      const index = state.contracts.findIndex((c) => c.id?.toString() === contractId?.toString())
      if (index > -1) {
        state.contracts.splice(index, 1)
      }
    },
    setProviders(state, providers) {
      state.providers = providers
    },
    addInvoice(state, invoice) {
      const index = state.contracts.findIndex((c) => c.id?.toString() === invoice.contract_id?.toString())
      if (index > -1) {
        const contract = state.contracts[index]
        contract.invoice.url = invoice.url
        contract.invoice.id = invoice.id
        contract.invoice.name = invoice.name
      }
    },
    deleteInvoice(state, fileId) {
      const index = state.contracts.findIndex((c) => c.invoice.id?.toString() === fileId?.toString())
      if (index > -1) {
        const contract = state.contracts[index]
        contract.invoice.url = null
        contract.invoice.id = null
        contract.invoice.name = null
      }
    },
    clear(state) {
      state.contracts = []
      state.providers = []
    },
    setPower(state, power) {
      state.power = power
    },
  },

  actions: {
    async loadContracts({ commit }, { siteId, params }) {
      let contracts = await get(`electricity/contracts/site/${siteId}`, { params })
      contracts = contracts.map(format)
      commit('set', contracts)
    },

    async loadProviders({ commit }) {
      const providers = await get('electricity/providers')
      commit('setProviders', providers)
    },

    /**
     * Ajoute/Édition d'un contract d'électicité | Offre BASE
     * - Un seul prix : base
     * - Une seule puissance souscrite : base
     *
     * @param {string|number} siteId
     * @param {string|number} contractId
     * @param {number} providerId
     * @param {string} subscriptionDate YYYY-MM-DD
     * @param {string|number} subscriptionPrice
     * @param {object} rates Coût du Kilowatt-heure en fonction des horaires/saisons
     * @param {object} powers Puissance souscrite en fonction des horaires/saisons
     *
     * @returns {object} contract formatted
     */
    async saveBaseContract(
      { commit, rootGetters },
      { siteId, contractId, providerId, subscriptionDate, subscriptionPrice, powers, rates },
    ) {
      const slots = [
        // One slot of 24h
        { start: { hours: 0, minutes: 0 }, end: { hours: 24, minutes: 0 } },
      ]

      const timeslots = slots.map((t) => {
        return {
          index_type: 'base',
          rate: rates.base ? parseDecimal(rates.base, 4) : null,
          subscribed_power: parseFloat(powers.base),
          start_time: minDigits(t.start.hours, 2) + ':' + minDigits(t.start.minutes, 2) + ':' + '00',
          end_time: minDigits(t.end.hours === 24 ? 0 : t.end.hours, 2) + ':' + minDigits(t.end.minutes, 2) + ':' + '00',
        }
      })

      const params = {
        provider_id: providerId,
        contract_type: 'base',
        subscription_date: subscriptionDate,
        subscription_price: subscriptionPrice ? parseDecimal(subscriptionPrice, 2) : null,
        sources: rootGetters['sources/sources'].filter((s) => s.isElectricity()).map((s) => s.id),
        time_slots: timeslots,
      }

      if (contractId) {
        let contract = await put(`electricity/contracts/${contractId}`, params)
        contract = format(contract)
        commit('update', contract)
        return contract
      } else {
        let contract = await post(`electricity/contracts/site/${siteId}`, params)
        contract = format(contract)
        commit('add', contract)
        return contract
      }
    },

    /**
     * Ajoute/Édition d'un contract d'électicité | Offre HPHC (Heure pleines / Heures creuses)
     * - 2 prix : hp, hc (heures pleines + heures creuses)
     * - Une seule puissance souscrite : hp, hc
     *
     * @param {string|number} siteId
     * @param {string|number} contractId
     * @param {number} providerId
     * @param {string} subscriptionDate YYYY-MM-DD
     * @param {string|number} subscriptionPrice
     * @param {object} rates Coût du Kilowatt-heure en fonction des horaires/saisons
     * @param {object} powers Puissance souscrite en fonction des horaires/saisons
     * @param {array} timeslots Plages d'heures creuses
     *
     * @returns {object} contract formatted
     */
    async saveHphcContract(
      { commit, rootGetters },
      { siteId, contractId, providerId, subscriptionDate, subscriptionPrice, powers, rates, timeslots },
    ) {
      const totalHours = parseFloat((totalMinutes(timeslots) / 60).toFixed(2))

      if (totalHours > 24) {
        return
      }
      if (totalHours <= 24) {
        timeslots = timeslots
          .filter((t) => t.start.isValid !== false && t.end.isValid !== false)
          .flatMap((t) => {
            // heure début > heure de fin => on sépare en 2 slots
            if (t.end.hours + t.end.minutes / 100 <= t.start.hours + t.start.minutes / 100) {
              return [
                { start: { hours: t.start.hours, minutes: t.start.minutes }, end: { hours: 0, minutes: 0 } },
                { start: { hours: 0, minutes: 0 }, end: { hours: t.end.hours, minutes: t.end.minutes } },
              ]
            }
            return t
          })
          .sort((a, b) => {
            if (a.start.hours !== b.start.hours) {
              return b.start.hours - a.start.hours > 0 ? -1 : 1
            }
            if (a.start.minutes !== b.start.minutes) {
              return b.start.minutes - a.start.minutes > 0 ? -1 : 1
            }
            return a.start.hours - b.start.hours > 0 ? 1 : -1
          })
          .map((t) => {
            return {
              index_type: 'HC',
              rate: rates.hc ? parseDecimal(rates.hc, 4) : null,
              subscribed_power: parseFloat(powers.hc),
              start: { hours: t.start.hours, minutes: t.start.minutes },
              end: { hours: t.end.hours, minutes: t.end.minutes },
            }
          })

        // Add HP timeslots (HC already definied by user)
        let i = 0
        while (i < timeslots.length - 1) {
          const curr = timeslots[i]
          const next = timeslots[i + 1]

          if (
            curr.end.hours < next.start.hours ||
            (curr.end.hours === next.start.hours && curr.end.minutes < next.start.minutes)
          ) {
            timeslots.splice(i + 1, 0, {
              index_type: 'HP',
              rate: parseDecimal(rates.hp, 4),
              subscribed_power: parseFloat(powers.hp),
              start: { hours: curr.end.hours, minutes: curr.end.minutes },
              end: { hours: next.start.hours, minutes: next.start.minutes },
            })
            i++
          }
          i++
        }

        if (
          Number(timeslots[0].start.hours) !== 0 ||
          (Number(timeslots[0].start.hours) === 0 && Number(timeslots[0].start.minutes) !== 0)
        ) {
          // Add first HP timeslots from midnight
          timeslots.unshift({
            index_type: 'HP',
            rate: parseDecimal(rates.hp, 4),
            subscribed_power: parseFloat(powers.hp),
            start: { hours: 0, minutes: 0 },
            end: { hours: timeslots[0].start.hours, minutes: timeslots[0].start.minutes },
          })
        }
        if (Number(timeslots[timeslots.length - 1].end.hours) !== 24) {
          // Add last HP timeslots to midnight
          timeslots.push({
            index_type: 'HP',
            rate: parseDecimal(rates.hp, 4),
            subscribed_power: parseFloat(powers.hp),
            start: {
              hours: timeslots[timeslots.length - 1].end.hours,
              minutes: timeslots[timeslots.length - 1].end.minutes,
            },
            end: { hours: 24, minutes: 0 },
          })
        }
      }

      timeslots = timeslots.map((t) => {
        return clean({
          ...t,
          start_time: minDigits(t.start.hours, 2) + ':' + minDigits(t.start.minutes, 2) + ':' + '00',
          end_time:
            minDigits(Number(t.end.hours) === 24 ? 0 : t.end.hours, 2) + ':' + minDigits(t.end.minutes, 2) + ':' + '00',
          start: null,
          end: null,
        })
      })

      const params = {
        provider_id: providerId,
        contract_type: 'HPHC',
        subscription_date: subscriptionDate,
        subscription_price: subscriptionPrice ? parseDecimal(subscriptionPrice, 2) : null,
        sources: rootGetters['sources/sources'].filter((s) => s.isElectricity()).map((s) => s.id),
        time_slots: timeslots,
      }

      if (contractId) {
        let contract = await put(`electricity/contracts/${contractId}`, params)
        contract = format(contract)
        commit('update', contract)
        return contract
      } else {
        let contract = await post(`electricity/contracts/site/${siteId}`, params)
        contract = format(contract)
        commit('add', contract)
        return contract
      }
    },

    /**
     * Ajoute/Édition d'un contract d'électicité | Offre PME/PMI (petites et moyennes entreprises)
     * - 5 prix : hph, hch, hpe, hce, peak (heures pleines hiver + heures creuses hiver + heures pleines été + heures creuses été + heures de pointe)
     * - 5 certificats de capacité : hph, hch, hpe, hce, peak (heures pleines hiver + heures creuses hiver + heures pleines été + heures creuses été + heures de pointe)
     * - Une ou 5 puissances sourscrites : hph, hch, hpe, hce, peak (powers)
     *
     * @param {string|number} siteId
     * @param {string|number} contractId
     * @param {number} providerId
     * @param {string} subscriptionDate YYYY-MM-DD
     * @param {string|number} subscriptionPrice
     * @param {object} rates Coût du Kilowatt-heure en fonction des horaires/saisons
     * @param {object|null} ratesCapacity Certificats de capacité
     * @param {object} powers Puissance souscrite en fonction des horaires/saisons
     *
     * @returns {object} contract formatted
     */
    async savePmePmiContract(
      { commit, rootGetters },
      { siteId, contractId, providerId, subscriptionDate, subscriptionPrice, powers, rates, ratesCapacity },
    ) {
      const slots = [
        // été (hc = 22h-6h par défault)
        { start: { hours: 22, minutes: 0 }, end: { hours: 24, minutes: 0 }, type: 'hce' },
        { start: { hours: 0, minutes: 0 }, end: { hours: 6, minutes: 0 }, type: 'hce' },
        { start: { hours: 6, minutes: 0 }, end: { hours: 22, minutes: 0 }, type: 'hpe' },
        // hiver (hc = 22h-6h par défault)
        { start: { hours: 22, minutes: 0 }, end: { hours: 24, minutes: 0 }, type: 'hch' },
        { start: { hours: 0, minutes: 0 }, end: { hours: 6, minutes: 0 }, type: 'hch' },
        { start: { hours: 6, minutes: 0 }, end: { hours: 22, minutes: 0 }, type: 'hph' },
        // Peak (Fake data to save peaks values in DB)
        { start: { hours: 0, minutes: 0 }, end: { hours: 24, minutes: 0 }, type: 'peak' },
      ]
      const timeslots = slots
        .map((t) => {
          rates[t.type] === '' ? (rates[t.type] = null) : rates[t.type]
          return {
            index_type: t.type !== 'peak' ? t.type.toUpperCase() : t.type,
            rate: rates[t.type] !== null ? parseDecimal(rates[t.type], 4) : null,
            rate_capacity_certificate:
              ratesCapacity && rates[t.type] !== null ? parseDecimal(ratesCapacity[t.type], 4) : null,
            subscribed_power: powers !== null ? parseFloat(powers[t.type]) : null,
            start_time: minDigits(t.start.hours, 2) + ':' + minDigits(t.start.minutes, 2) + ':' + '00',
            end_time:
              minDigits(Number(t.end.hours) === 24 ? 0 : t.end.hours, 2) +
              ':' +
              minDigits(t.end.minutes, 2) +
              ':' +
              '00',
          }
        })
        .filter((t) => t.subscribed_power)

      const params = {
        provider_id: providerId,
        contract_type: 'PME_PMI',
        subscription_date: subscriptionDate,
        subscription_price: subscriptionPrice ? parseDecimal(subscriptionPrice, 2) : null,
        sources: rootGetters['sources/sources'].filter((s) => s.isElectricity()).map((s) => s.id),
        time_slots: timeslots,
      }

      if (contractId) {
        let contract = await put(`electricity/contracts/${contractId}`, params)
        contract = format(contract)
        commit('update', contract)
        return contract
      } else {
        let contract = await post(`electricity/contracts/site/${siteId}`, params)
        contract = format(contract)
        commit('add', contract)
        return contract
      }
    },

    async saveInvoice({ commit }, { siteId, contractId, file, comments }) {
      if (contractId) {
        const params = clean({
          contract_id: contractId,
          file,
          comments,
        })
        const invoice = await post('electricity/upload-invoice', params, undefined, { formData: true })
        commit('addInvoice', invoice)
      } else {
        const params = clean({
          site_id: siteId,
          file,
          comments,
        })
        await post('electricity/upload-invoice', params, undefined, { formData: true })
      }
    },

    async deleteInvoice({ commit }, { fileId }) {
      await DELETE(`electricity/delete-invoice/${fileId}`)
      commit('deleteInvoice', fileId)
    },

    clear({ commit }) {
      commit('clearLoading')
      commit('clear')
    },
  },

  namespaced: true,
}
