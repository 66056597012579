import deepmerge from 'deepmerge'
import { createI18n } from 'vue-i18n'
import { locales } from '@/locales'

const lang = 'fr'
// Uncomment the next line when 'en' locale will be available
// const lang = navigator.language ? navigator.language.split('-')[0] : 'fr'
const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages: locales,
  fallbackLocale: 'fr',
  warnHtmlMessage: false,
  missing: (_, key) => {
    const msg = `[VueI18n] Missing translation: ${key}`
    console.warn(msg)
  },
})
if (import.meta.hot) {
  // reload i18n
  import.meta.hot.on('i18n-reload', ({ lang, key, json }) => {
    const keys = key.split('.')
    const changeLocale = {}
    let current = changeLocale
    for (const key of keys) {
      current[key] = {}
      current = current[key]
    }
    const currentLocales = locales
    Object.assign(current, json)
    currentLocales[lang] = deepmerge(currentLocales[lang], changeLocale, {
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    })
    i18n.setLocaleMessage(lang, currentLocales[lang])
  })

  // display i18n error
  import.meta.hot.on('i18n-error', ({ error }) => {
    console.error(error)
  })
}
export default i18n

export const t = i18n.global.t
