// Usage documentation >>> See README/GDPR.md
import { env } from '@/helpers/env'
import ls from '@/helpers/localStorage'
import { setDataLayer, triggerEvent } from '@/services/mtm'
import { TrackingServiceConsent } from '@/types/tracking'
import mtmInit from './init/mtm'

declare global {
  interface Window {
    GDPR?: GDPRService
  }
}

type TrackingFunction = (...args: unknown[]) => void
type TrackerInits = {
  matomo: { init: typeof mtmInit; bypass: boolean }
}
type TrackingInit = {
  service: string
  fn: () => void
  bypass: boolean // bypass the user consent (run directly)
  ran: boolean
}
type TrackingAction = {
  service: string
  fn: TrackingFunction
  args: unknown[]
  bypass: boolean // bypass the user consent (run directly)
  ran: boolean
}

const LS_ITEM_NAME = 'udwi.consents'
const ENV_TRACKING_SERVICES = env('VUE_APP_ENABLED_TRACKING_SERVICES') as string
const ENABLED_SERVICES: string[] = ENV_TRACKING_SERVICES ? ENV_TRACKING_SERVICES.split(',').map((s) => s.trim()) : []
// Trackers-related init scripts
const TRACKERS_INITS: TrackerInits = {
  matomo: { init: mtmInit, bypass: true },
}
// Trackers-related bound functions, exposed in `.service('<tracker>')`
const TRACKERS_MODULES = {
  matomo: {
    triggerEvent,
    setDataLayer,
  },
}

export class GDPRService {
  _consents: TrackingServiceConsent[] = []
  _initQueue: TrackingInit[] = []
  _actionsQueue: TrackingAction[] = []

  constructor() {
    // init consents
    const browserConsents: TrackingServiceConsent[] = (ls.getItem(LS_ITEM_NAME) as TrackingServiceConsent[]) || []
    const consents: TrackingServiceConsent[] = [...browserConsents.filter((c) => ENABLED_SERVICES.includes(c.name))]
    ENABLED_SERVICES.forEach((srv) => {
      if (browserConsents.every((s) => s.name !== srv)) consents.push({ name: srv, consent: false, reviewed: false })
    })
    this._consents = consents
    this.save()
    // build trackers-init queue
    this._initQueue = Object.entries(TRACKERS_INITS).map(([key, conf]) => ({
      service: key,
      fn: conf.init,
      bypass: conf.bypass,
      ran: false,
    }))
    this._runInitQueue()
    this._runActionsQueue()
  }
  _isServiceEnabled(name: string): boolean {
    return ENABLED_SERVICES.some((s) => s === name)
  }
  _runInitQueue(): void {
    this._initQueue = this._initQueue
      .map((init) => {
        if (init.ran) return init
        if (init.bypass || this.hasConsent(init.service)) {
          init.fn()
          init.ran = true
        }
        return init
      })
      .filter((init) => !init.ran)
  }
  _runActionsQueue(): void {
    this._actionsQueue = this._actionsQueue
      .map((action) => {
        const needsInit = this._initQueue.some((init) => init.service === action.service)
        const ranInit = needsInit ? this._initQueue.some((init) => init.service === action.service && init.ran) : true
        if (ranInit && (action.bypass || this.hasConsent(action.service))) {
          action.fn(...action.args)
          action.ran = true
        }
        return action
      })
      .filter((action) => !action.ran)
  }
  service(name: string, bypassConsent = false): Record<string, TrackingFunction> {
    const service: Record<string, TrackingFunction> = {}
    const serviceModule = TRACKERS_MODULES[name as keyof typeof TRACKERS_MODULES]
    for (const [propName, propValue] of Object.entries(serviceModule))
      if (typeof propValue === 'function') {
        const wrapFn = (...args: unknown[]): void => {
          this._actionsQueue.push({
            service: name,
            fn: propValue as TrackingFunction,
            args,
            bypass: bypassConsent,
            ran: false,
          })
          this._runInitQueue()
          this._runActionsQueue()
        }
        service[propName as keyof typeof service] = wrapFn
      } else service[propName] = propValue
    return service
  }
  setConsent(value: boolean, service: string): void {
    const existing: TrackingServiceConsent[] = this._consents.filter((s) => s.name === service)
    if (!existing.length) throw new Error(`GDPR: '${service}' service is not enabled.`)
    existing[0].consent = value
    this._runInitQueue()
    this._runActionsQueue()
  }
  setReviewed(value: boolean, service: string): void {
    const existing: TrackingServiceConsent[] = this._consents.filter((s) => s.name === service)
    if (!existing.length) throw new Error(`GDPR: '${service}' service is not enabled.`)
    existing[0].reviewed = value
    this._runInitQueue()
    this._runActionsQueue()
  }
  hasConsent(service: string): boolean {
    if (this._isServiceEnabled(service)) return !!this._consents.filter((c) => c.name === service && c.consent).length
    return true
  }
  isReviewed(service = '') {
    if (!service) return this._consents.every((c) => c.reviewed)
    return !!this._consents.filter((c) => c.name === service && c.reviewed).length
  }
  getConsents(): TrackingServiceConsent[] {
    return this._consents
  }
  save(): void {
    ls.setItem(LS_ITEM_NAME, this._consents)
  }
}

export const useGDPR = () => (window.GDPR = window.GDPR || new GDPRService())
