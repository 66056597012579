<script setup lang="ts">
import { PropType } from 'vue'
import { setDefaultProps } from 'vue-tippy'
import AsyncIcon from '@/components/icons/AsyncIcon.vue'

setDefaultProps({
  trigger: 'mouseenter focus click',
  interactive: true,
  role: 'tooltip',
  aria: {
    content: 'describedby',
  },
  appendTo: () => document.body,
})

const props = defineProps({
  text: {
    type: String as PropType<string>,
    default: '',
  },
  // if placecement is used, tippy auto positionning will not work
  placement: {
    type: String as PropType<'left' | 'right' | 'top' | 'bottom'>,
  },
  triggerIcon: {
    type: String as PropType<string>,
    default: 'notice',
  },
  contentIcon: {
    type: String as PropType<string>,
    default: '',
  },
})
</script>

<template>
  <tippy class="tippy" :placement="placement" data-unit-test="tooltip">
    <slot v-if="$slots.default"></slot>
    <AsyncIcon
      v-else-if="triggerIcon"
      :name="triggerIcon"
      :size="24"
      data-unit-test="tooltip-trigger"
      color="#2196f3"
    />

    <template #content>
      <div class="tooltip-box p-4" ref="tooltip-box">
        <AsyncIcon
          v-if="contentIcon"
          :name="contentIcon"
          color="white"
          secondary-color="#2196f3"
          :size="24"
          data-unit-test="tooltip-box-content-icon"
        />
        <slot name="content">
          <p v-if="props.text" v-html="text" class="u-txt-sm pl-7"></p>
        </slot>
      </div>
    </template>
  </tippy>
</template>

<style scoped lang="scss">
@import '@/scss/_imports.scss';
.tippy {
  cursor: pointer;
}
.tooltip-box {
  align-items: flex-end;
  background-color: $blue;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 100%;
  position: relative;
  width: 400px;

  @media screen and (min-width: $screen_smartphone_min) and (max-width: 400px) {
    margin-left: 18%;
    max-width: 80%;
  }

  p {
    color: white;
    font-weight: bold;
  }

  svg {
    left: 10px;
    position: absolute;
  }
}
</style>
