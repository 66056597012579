<template>
  <div>
    <div ref="app" class="app-wrapper">
      <template v-if="hasHeader">
        <AppHeader :has-navigation="hasNavigation" :is-support="isSupport" />
      </template>

      <div class="main-wrapper">
        <template v-if="hasNavigation">
          <UNavigationSupport v-if="isSupport" />
          <UNavigation v-else />
        </template>
        <main>
          <router-view />
        </main>
        <template v-if="!max('s')">
          <div v-if="arrowOpacity" class="arrow-container" @click="goToEndPage">
            <IconArrowDown :size="32" :style="{ opacity: arrowOpacity }" />
          </div>
        </template>
        <div class="u-organic-shape">
          <img src="@/assets/img/organic-background.svg" alt="Udwi organic shape" />
        </div>
      </div>

      <UFooter v-if="hasFooter" />
    </div>

    <UModals :components="modals" />
    <UToasts />
    <CookieConsentBanner />
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { defineAsyncComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import UFooter from '@/components/UFooter.vue'
import AppHeader from '@/components/app/AppHeader.vue'
import IconArrowDown from '@/components/icons/IconArrowDown.vue'
import CookieConsentBanner from '@/components/layout/CookieConsentBanner.vue'
import UNavigation from '@/components/layout/UNavigation.vue'
import UModals from '@/components/ui/UModals.vue'
import { modal } from '@/components/ui/modals'
import UToasts from '@/components/ui/toasts/UToasts.vue'
import { useTrackingData } from '@/composables/useTrackingData'
import { storage } from '@/helpers/storage'
import { useGDPR } from '@/services/gdpr'
import { useLayoutStore } from '@/store/pinia/layout'
import UNavigationSupport from '@/support/components/layout/UNavigation.vue'
import { equal } from './helpers/objects'

const gdpr = useGDPR()

export default {
  components: {
    UToasts,
    AppHeader,
    UNavigation,
    UNavigationSupport,
    UFooter,
    CookieConsentBanner,
    IconArrowDown,
    UModals,
  },

  data: function () {
    return {
      installGuidePreloadSensor: null,
      showInstallationGuide: false,
      isWizardContext: false,
      isDataSources: false,
      arrowOpacity: 0,
      resizeObserver: null,
    }
  },

  computed: {
    ...mapStores(useLayoutStore),
    ...mapState('user', ['isLoadingUser']),
    ...mapState('sites', ['isLoadingSites', 'isLoadingCategories']),
    ...mapGetters('responsive', ['breakpoint', 'max']),
    ...mapGetters('responsive', { responsiveHelper: 'helper' }),
    ...mapGetters('impersonate', ['impersonating']),
    ...mapGetters('user', ['user', 'isCompany', 'isSetup']),
    ...mapGetters('site', ['siteId', 'site', 'isSchedulesDefined']),
    ...mapGetters('sites', ['categories']),
    ...mapGetters('acl', ['can']),
    isDevelopers() {
      return this.can('developers')
    },
    isSupport() {
      return this.$route.path.includes('/support')
    },
    hasNavigation() {
      if (!this.$route.matched.length) {
        return false
      }
      return this.$route.meta.navigation !== false
    },
    hasHeader() {
      if (!this.user) {
        return false
      }
      if (!this.$route.matched.length) {
        return false
      }
      return this.$route.meta.header !== false
    },
    hasFooter() {
      if (!this.$route.matched.length) {
        return false
      }
      return this.$route.meta.footer !== false
    },
    showSchedulesModal() {
      if (!this.siteId) return false
      if (!this.isSetup) return false
      if (!this.isCompany) return false
      if (this.impersonating) return false
      if (this.$route.name === 'init.site') return false
      if (this.$route.name === 'init.periods') return false
      return !this.isSchedulesDefined
    },
    modals() {
      return {
        confirm: defineAsyncComponent(() => import('@/components/ui/modals/UConfirm.vue').then((d) => d.default)),
        'contact-support': defineAsyncComponent(() =>
          import('@/components/modals/ContactSupport.vue').then((d) => d.default),
        ),
        schedules: defineAsyncComponent(() =>
          import('@/components/schedules/modals/Schedules.vue').then((d) => d.default),
        ),
      }
    },
  },

  watch: {
    $route: {
      handler(current, old) {
        if (this.showSchedulesModal) modal('schedules', { unique: true })
        if (!equal(current, old)) {
          const { matomo } = useTrackingData(this.$store)
          gdpr.service('matomo', true).setDataLayer(matomo.value, this.$route)
          gdpr.service('matomo', true).triggerEvent('custom-pageview')
        }
      },
      deep: true,
    },
    site: {
      handler() {
        if (this.showSchedulesModal) {
          modal('schedules', { unique: true })
        }
      },
      immediate: true,
      deep: true,
    },
    isDevelopers: {
      handler() {
        const meta = document.querySelector('[rel="icon"][type="image/svg+xml"]')
        if (!meta) {
          return
        }
        if (this.isDevelopers) {
          if (!meta.attributes['href-non-developers']) {
            meta.setAttribute('href-non-developers', meta.getAttribute('href'))
          }
          meta.setAttribute('href', meta.getAttribute('href-developers'))
        } else {
          if (!meta.attributes['href-non-developers']) {
            return // already non developpers
          }
          meta.setAttribute('href', meta.getAttribute('href-non-developers'))
        }
      },
      deep: true,
    },
  },

  methods: {
    // needed because css variables are not avaialable sooner
    ...mapMutations('user', { setLoadingUser: 'setLoading' }),
    ...mapMutations('sites', { setLoadingSites: 'setLoading' }),
    ...mapMutations('responsive', ['initBreakpoints']),
    ...mapActions('sites', { loadCompanyCategories: 'loadCompanyCategories' }),
    handleScroll() {
      const scrollTop = window.scrollY
      const scrollHeight = document.body.scrollHeight
      const windowHeight = window.innerHeight
      const scrollPercent = scrollTop / (scrollHeight - windowHeight)

      if (scrollHeight - 200 <= windowHeight) {
        this.arrowOpacity = 0
        return
      }

      this.arrowOpacity = 1 - scrollPercent
    },
    goToEndPage() {
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })
    },
    handleMainDataLoading() {
      // init loading state if user could be connected/connecting
      if (storage.getItem('oauth.cache') || storage.getItem('oauth.state')) {
        this.setLoadingUser(true)
        this.setLoadingSites(true)
      }
    },
  },
  created() {
    this.initBreakpoints()
    this.layoutStore.initNavigation()
    this.loadCompanyCategories()
    this.handleMainDataLoading()
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.handleScroll)
    this.resizeObserver.observe(this.$refs.app)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

.app-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .main-wrapper {
    display: flex;
    flex-grow: 1;
    position: relative;
    min-height: calc(100vh - #{$u-header-height});

    @media screen and (max-width: $screen_xs) {
      min-height: calc(100vh - #{$u-header-height-s});
    }

    main {
      z-index: 2;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;

      > div,
      > section {
        height: 100%;
      }
    }

    .u-organic-shape {
      position: fixed;
      bottom: -13%;
      right: 0;
      z-index: 0;
      display: flex;
      opacity: 0.9;

      > img {
        width: 100%;
      }

      @media screen and (max-width: $screen_s) {
        width: 40%;
        bottom: -8%;
      }
    }

    main:empty + .u-organic-shape {
      opacity: 0;
    }
  }
  .arrow-container {
    cursor: pointer;
    z-index: 999;
    position: fixed;
    left: 45%;
    top: 85%;
    transform: translateX(50%);
    width: 100%;

    svg {
      border: 1px solid black;
      border-radius: 50%;
      padding: 5px;
      transition: background-color 300ms linear;

      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/components/imports.scss';

.v-autocomplete__content.v-menu__content {
  z-index: 150 !important;

  .v-card {
    border-radius: 8px !important;
  }
}

//from PeriodPicker (non scoped style)
.no-margin {
  margin: 0px !important;
  padding: 0px !important;
}

.no-right-padding {
  padding-right: 0px !important;
}

.nav-open .v-content__wrap {
  @media only screen and (min-width: $screen_md) {
    width: calc(100vw - #{$drawer_width}) !important;
  }
}
.v-card {
  max-width: initial;
}
</style>

<style lang="scss">
@import '@/scss/global.scss';
</style>
