import { RouteLocation } from 'vue-router'

export function getUniqueQueryValue<T extends string = string>(values: T | (T | null)[], defaultValue?: T) {
  const v = Array.isArray(values) ? values[0] : values
  return v ?? defaultValue
}

export function getStrictUniqueQueryValue<T extends string = string>(values: T | (T | null)[]) {
  const v = getUniqueQueryValue(values)
  if (!v) throw new Error('Missing query value')
  return v
}

export function getRoutePattern(route: RouteLocation, full?: boolean): string | undefined {
  return route.matched.reduce((result: string | undefined, entry) => {
    if (route.name === entry.name) result = full ? entry.path : entry.path.replace(/\(.*\)\??/gi, '')
    return result
  }, undefined)
}
