import { sentry } from '@/plugins/yrtnes'

export const siteModule = {
  state: {
    site: null,
  },

  getters: {
    siteId: (state) => state.site?.id,
    site: (state) => state.site,
    siteName: (state) => state.site?.name ?? '',
    isSchedulesDefined: (state, getters, rootState, rootGetters) =>
      !!state.site?.schedules_defined && rootGetters['user/isCompany'],
    siret: (state) => state.site?.siret,
    username: (state) => {
      // TODO: check if used
      if (!state.user) {
        return null
      }
      if (!state.user.first_name && !state.user.last_name) {
        return state.user.email_address ?? state.user.email
      }
      return `${state.user.first_name} ${state.user.last_name}`
    },
  },

  mutations: {
    set(state, site) {
      state.site = site
    },
    update(state, site) {
      if (!site) {
        console.error('bad site update value', state.site, site)
        return
      }
      if (site.id && site.id !== state.site?.id) {
        console.error('bad site id:', state.site, site)
        return
      }
      for (const key in site) {
        state.site[key] = site[key]
      }
    },
    clear(state) {
      state.site = null
    },
  },

  actions: {
    setCurrentSite({ commit, rootGetters, dispatch, getters }, site) {
      if (site && typeof site !== 'object') {
        site = rootGetters['sites/getSite'](site)
      }
      commit('set', site)
      sentry.setContext('site', {
        id: getters.siteId ?? 'none',
        schedules_defined: getters.isSchedulesDefined,
        sources: (getters.site?.sources ?? []).map((source) => {
          return source.id
        }),
      })
      sentry.setTag('site_type', 'udwi')
      dispatch('alerts/clear', {}, { root: true })
      dispatch('sources/setSources', site?.sources ?? [], { root: true })
    },
    completeSchedules({ commit }) {
      commit('update', { schedules_defined: true })
    },
    clear({ dispatch, commit }) {
      commit('set', null)
      dispatch('notifications/clear', {}, { root: true })
      dispatch('alerts/clear', {}, { root: true })
      dispatch('schedules/clear', {}, { root: true })
      return dispatch('sources/clear', {}, { root: true })
    },
  },

  namespaced: true,
}
