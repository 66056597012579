import { get } from '@/helpers/http'
import { store } from '@/store/vuex/index'

export const kitsModule = {
  state: {
    kits: [],
    loadingKits: false,
  },

  getters: {
    kits: (state) => state.kits,
    loadingKits: (state) => state.loadingKits,
    sensorsToSetup: () =>
      store.getters['sources/sources']
        .filter((source) => source.state === 'uninstalled')
        .filter((source) => !source.isGateway())
        .sort((a, b) => {
          if (a.manufacturer === b.manufacturer) {
            if (a.isElectricity() && !b.isElectricity()) {
              return -1
            }
            if (!a.isElectricity() && b.isElectricity()) {
              return 1
            }
            return 0
          }
          if (a.manufacturer === 'fludia') {
            return -1
          }
          if (b.manufacturer === 'fludia') {
            return 1
          }
          return 0
        }),
    nextSensor: () => store.getters['kits/sensorsToSetup'][0],
  },

  mutations: {
    setLoading(state, { name, isLoading }) {
      state[name] = isLoading
    },
    set(state, kits) {
      state.kits = kits
    },
    clear(state) {
      state.kits = []
    },
  },

  actions: {
    async loadKits({ commit }, { siteId }) {
      commit('setLoading', { name: 'loadingKits', isLoading: true })
      const kits = await get(`configurations/kits/${siteId}`)
      commit('setLoading', { name: 'loadingKits', isLoading: false })
      commit('set', kits)
      return kits
    },
    clear({ commit }) {
      commit('clear')
    },
  },

  namespaced: true,
}
