<script setup lang="ts">
import { computed } from 'vue'
import { propsIcon } from './props'

const props = defineProps({
  ...propsIcon,
  rotate: {
    type: Number,
    default: 0,
  },
})
const deg = computed(() => props.rotate + 'deg')
</script>
<template>
  <svg :width="size" :height="size" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.533 9.09602L17.6036 16.503L10.533 23.91C9.82232 24.6546 9.82232 25.8572 10.533 26.6018C11.2437 27.3463 12.3918 27.3463 13.1025 26.6018L21.467 17.8393C22.1777 17.0948 22.1777 15.8921 21.467 15.1476L13.1025 6.38521C12.3918 5.64069 11.2437 5.64069 10.533 6.38521C9.84055 7.12973 9.82232 8.35151 10.533 9.09602Z"
      :fill="color"
    />
  </svg>
</template>

<style scoped>
path {
  transform-origin: 50% 50%;
  rotate: v-bind(deg);
}
</style>
