<script setup lang="ts">
import { defineAsyncComponent, onErrorCaptured, watch } from 'vue'
import { propsIcon } from '@/components/icons/props'
import { pascalCase } from '@/helpers/strings'

const props = defineProps({ ...propsIcon, name: { type: String, required: true } })
let Icon = defineAsyncComponent(() => import(`./Icon${pascalCase(props.name)}.vue`))

onErrorCaptured(() => {
  console.warn('Error catch AsyncIcon : ', props.name)
})

watch(
  () => props.name,
  () => {
    Icon = defineAsyncComponent(() => import(`./Icon${pascalCase(props.name)}.vue`))
  },
)
</script>

<template>
  <Icon v-if="name" :color="color" :size="size" data-unit-test="async-icon" />
</template>
