import { DELETE, get, patch, post } from '@/helpers/http'
import { clean } from '@/helpers/objects'
import Source from '@/models/Source'
import { fetchCompanyCategories } from '@/services/sites'

function map(site) {
  return {
    ...site,
    created_date: site.first_elec_source_installed,
    sources: (site.sources ?? []).map((source) => (source instanceof Source ? source : new Source(source))),
  }
}

export const sitesModule = {
  state: {
    sites: [],
    categories: [],
    isLoadingSites: false,
    isLoadingCategories: false,
  },

  getters: {
    sites: (state) => state.sites,
    /**
     * Returns true if there is at least one site
     * for the current user
     */
    hasSites: (state) => !!state.sites.length,
    /**
     * Get site from id
     *
     */
    getSite(state) {
      return function (siteId) {
        return state.sites.find((site) => site.id === Number(siteId))
      }
    },
    categories: (state) => state.categories,
    /**
     * Return true if at least one site has at least one source
     */
    hasSources: (state) => !!state.sites.find((site) => !!site.sources.length),
    /**
     * List all sources of all sites
     * @returns {sources[]}
     */
    allSources: (state) => state.sites.flatMap((site) => site.sources),
  },

  mutations: {
    set(state, sites) {
      sites = sites.map(map)
      state.sites = sites.sort((a, b) => a.id - b.id)
    },
    add(state, site) {
      const index = state.sites.findIndex((s) => s.id === site.id)
      if (~index) {
        state.sites.splice(index, 1, map(site, index))
      } else {
        state.sites.push(map(site, state.sites.length))
      }
      state.sites = state.sites.sort((a, b) => a.id - b.id)
    },
    update(state, site) {
      const index = state.sites.findIndex((s) => s.id === site.id)
      if (~index) {
        state.sites.splice(index, 1, map(site, index))
      } else {
        state.sites.push(map(site, state.sites.length))
      }
      state.sites = state.sites.sort((a, b) => a.id - b.id)
    },
    remove(state, { id }) {
      const index = state.sites.findIndex((s) => s.id === id)
      if (~index) {
        state.sites.splice(index, 1)
      }
    },
    clear(state) {
      state.sites = []
    },
    duplicate(state, site) {
      state.sites.push(map(site, state.sites.length))
      state.sites = state.sites.sort((a, b) => a.id - b.id)
    },
    setLoading(state, value) {
      state.isLoadingSites = value
    },
    setCategories(state, value) {
      state.categories = value
    },
    setLoadingCategories(state, value) {
      state.isLoadingCategories = value
    },
  },

  actions: {
    loadSites({ commit, getters, rootGetters, dispatch }) {
      commit('setLoading', true)
      if (!rootGetters['auth/isConnected']) {
        commit('setLoading', false)
        return Promise.resolve()
      }

      return get('sites')
        .then(async (data) => {
          // kits depends of sources
          await dispatch('kits/clear', {}, { root: true })
          commit('set', data)

          // force refresh current site
          if (rootGetters['site/siteId']) {
            const site = getters.getSite(rootGetters['site/siteId'])
            await dispatch('site/setCurrentSite', site, { root: true })
          }
          // set current site to first if not set yet
          if (!rootGetters['site/siteId'] && getters.sites[0]) {
            await dispatch('site/setCurrentSite', getters.sites[0], { root: true })
          }
          commit('setLoading', false)
          return data
        })
        .catch((error) => {
          console.error('/me:error', error)
        })
    },
    createSite({ commit, dispatch, getters }, { address, lat, lng, name, siret, phone_number }) {
      return post('sites', clean({ address, lat, lng, name, siret, phone_number })).then(async (site) => {
        commit('add', site)
        site = getters.getSite(site.id)
        await dispatch('site/setCurrentSite', site, { root: true })
        return site
      })
    },
    duplicateSite({ commit, dispatch, getters }, { site_id }) {
      return post('support/duplicate-site', { site_id }).then(async (site) => {
        commit('duplicate', site)
        site = getters.getSite(site.id)
        await dispatch('site/setCurrentSite', site, { root: true })
        return site
      })
    },
    updateSite(
      { commit, rootGetters, dispatch, getters },
      {
        id,
        address,
        lat,
        lng,
        name,
        siret,
        entreprise_category,
        air_conditioned_surface,
        has_clim,
        heated_surface,
        heating_energy,
        phone_number,
      },
    ) {
      return patch(
        `sites/${id}`,
        clean({
          address,
          lat,
          lng,
          name,
          siret,
          entreprise_category,
          air_conditioned_surface,
          has_clim,
          heated_surface,
          heating_energy,
          phone_number,
        }),
      ).then(async (site) => {
        commit('update', site)
        site = getters.getSite(site.id)
        if (rootGetters['site/siteId'] === site.id) {
          await dispatch('site/setCurrentSite', site, { root: true })
        }
        return site
      })
    },
    removeSite({ commit, dispatch, rootGetters, getters }, { id }) {
      commit('remove', { id })
      return DELETE(`sites/${id}`)
        .then(async () => {
          if (rootGetters['site/siteId']) {
            // if current site is deleted => change to first one
            const site = getters.getSite(rootGetters['site/siteId'])
            if (!site) {
              await dispatch('site/setCurrentSite', getters.sites[0], { root: true })
            }
          }
        })
        .catch((error) => {
          dispatch('loadSites', { force: true })
          return Promise.reject(error)
        })
    },
    clear({ dispatch, commit }) {
      commit('set', [])
      return dispatch('site/clear', {}, { root: true })
    },
    async loadCompanyCategories({ commit }) {
      commit('setLoadingCategories', true)
      const data = await fetchCompanyCategories()
      // console.log(data)
      commit('setCategories', data)
      commit('setLoadingCategories', false)
    },
  },

  namespaced: true,
}
