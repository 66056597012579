<template>
  <Teleport to="body" v-if="modelValue">
    <div
      v-bind="$attrs"
      class="u-modal"
      @click.self="onClickOutside"
      @mousedown="onMouseDown"
      ref="container"
      :key="keyModal"
    >
      <div class="u-popup" @mouseup="onMouseUp" :style="'width: ' + width">
        <IconClose v-if="closable" class="pointer close" :size="24" @click="cancel" />
        <slot name="popup" :cancel="cancel" :validation="validation" :action="action">
          <!-- Header -->
          <header>
            <h2 class="u-txt-2xl center flex items-center">
              <slot name="title">Title</slot>
            </h2>
          </header>

          <!-- Content -->
          <section class="content" v-if="hasDefaultSlot">
            <slot />
          </section>

          <div v-if="$slots.notice" class="u-notice">
            <slot name="notice" />
          </div>

          <!-- Footer -->
          <footer>
            <slot name="footer" :cancel="cancel" :validation="validation" :action="action">
              <UButton text="Annuler" type="tertiary" @click="cancel" />
              <UButton text="Continuer" @click="validation" />
            </slot>
          </footer>
        </slot>
      </div>
    </div>
  </Teleport>
</template>

<script>
import hotkeys from 'hotkeys-js'
import IconClose from '@/components/icons/IconClose.vue'
import UButton from '@/components/ui/UButton.vue'

let id = 0

export default {
  name: 'UModal',
  components: { IconClose, UButton },
  emits: ['close', 'validation', 'cancel', 'action'],
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    /**
     * If true will add a close button and emits cancel event on this button or on click outside
     */
    closable: {
      type: Boolean,
      default: true,
    },
    /**
     * Modal css width value
     */
    width: {
      type: String,
      required: false,
      default: 'auto',
    },
  },
  data() {
    return {
      // needed to handle clickOutside
      clickingOutside: false,
      keyModal: 'modal-' + id++,
    }
  },
  mounted() {
    if (this.modelValue) {
      this.createListeners()
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
  },
  methods: {
    // needed to avoid unwanted outside click (starting from outside finising inside or the opposite)
    onMouseDown(event) {
      this.clickingOutside = event.target === this.$refs.container
    },
    onMouseUp(event) {
      if (!this.clickingOutside) {
        return
      }
      this.clickingOutside = event.target === this.$refs.container
    },
    onClickOutside() {
      if (!this.clickingOutside) {
        return // mousedown was not outside
      }
      this.cancel()
    },
    onEscape(event) {
      event.preventDefault()
      this.cancel()
    },
    cancel() {
      if (!this.closable) {
        return
      }
      return this.action('cancel')
    },
    validation() {
      return this.action('validation')
    },
    action(type) {
      const event = new CustomEvent(`${type}_event`, { cancelable: true, cancelBubble: true })
      event.cancel = () => {
        event.preventDefault()
        event.cancelled = true
      }
      this.$emit(type, event)
      this.$emit('action', { type, event })
      if (event.defaultPrevented) {
        return
      }
      this.close()
    },
    close() {
      this.$emit('close', false)
    },
    createListeners() {
      hotkeys('escape', (this.keyListener = this.onEscape.bind(this)))
      const htmlElement = document.body.parentElement
      if (!htmlElement.classList.contains('prevent-scroll')) {
        htmlElement.classList.add('prevent-scroll')
      }
    },
    clearListeners() {
      if (this.keyListener) {
        hotkeys.unbind('escape', this.keyListener)
        this.keyListener = null
      }
      document.body.parentElement.classList.remove('prevent-scroll')
    },
  },
  beforeUnmount() {
    this.clearListeners()
  },
  watch: {
    modelValue() {
      this.clearListeners()
      if (this.modelValue) {
        this.createListeners()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

$padding: 2em;
$padding-s: 1em;

.u-modal {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($dark, 0.5);
  padding: 5vh 5vw;
  overflow-y: scroll;

  .u-popup {
    position: relative;
    max-width: 90vw;
    background: $white;
    box-shadow: 0px 4px rgb($black, 0.16);
    border-radius: 12px;

    .close {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $padding * 2.5 $padding $padding $padding;
      @media screen and (max-width: $screen_xs) {
        padding: $padding-s * 2.5 $padding-s $padding-s $padding-s;
      }
      h2 {
        text-align: center;
      }
    }
    section {
      @include u-txt-lg;
      padding: 1em $padding 1.5em;
      :deep(p) + p {
        margin-top: 1em;
      }
    }
    footer {
      padding: calc($padding / 2) $padding 2 * $padding $padding;
      display: flex;
      align-items: center;
      justify-content: space-around;
      @media screen and (max-width: $screen_s) {
        flex-direction: column-reverse;
        padding-bottom: 40px;
      }

      :deep(button) + button {
        margin-left: 2em;
        @media screen and (max-width: $screen_s) {
          margin-left: 0;
          margin-bottom: 1em;
        }
      }
    }
  }

  &.debug .u-popup {
    width: 1200px;
  }
}

.u-notice {
  background: $blue-light;
  padding: 1em $padding 1.5em;
}

.pointer {
  cursor: pointer !important;
}
</style>
