/* eslint-disable */
// DON'T USE TYPESCRIPT HERE.
import { env } from '@/helpers/env'

const MATOMO_CONTAINER = env('VUE_APP_MATOMO_CONTAINER')

export default () => {
  var _mtm = (window._mtm = window._mtm || [])
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  ;(function () {
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0]
    g.async = true
    g.src = `https://cdn.matomo.cloud/ecoco2.matomo.cloud/${MATOMO_CONTAINER}.js`
    s.parentNode.insertBefore(g, s)
  })()
}
