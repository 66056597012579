<template>
  <div class="toasts">
    <div class="u-toast-container">
      <UToast v-bind="toast" v-for="toast in toasts" :key="toast.$id" @close="onClose(toast)" remote />
    </div>
    <div id="u-toasts-top-left" multiple class="toasts-container top left" />
    <div id="u-toasts-top-center" multiple class="toasts-container top center" />
    <div id="u-toasts-top-right" multiple class="toasts-container top right" />
    <div id="u-toasts-bottom-left" multiple class="toasts-container bottom left" />
    <div id="u-toasts-bottom-center" multiple class="toasts-container bottom center" />
    <div id="u-toasts-bottom-right" multiple class="toasts-container bottom right" />
  </div>
</template>

<script>
import { events } from '../utils/events'
import UToast from './UToast.vue'

export default {
  name: 'UToasts',
  components: {
    UToast,
  },
  data() {
    return {
      toasts: [],
    }
  },
  created() {
    this.id = 1
    events.on('toast', (this.listener = this.add.bind(this)))
  },
  methods: {
    add(toast) {
      Object.defineProperty(toast, '$id', {
        enumerable: false,
        writable: false,
        value: this.id++,
      })
      this.toasts.push(toast)
    },
    remove(toast) {
      const index = this.toasts.indexOf(toast)
      if (~index) {
        this.toasts.splice(index, 1)
      }
    },
    onClose(toast) {
      this.remove(toast)
    },
  },
  beforeUnmount() {
    if (!this.listener) {
      return
    }
    events.off('toast', this.listener)
  },
}
</script>

<style lang="scss" scoped>
.toasts {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  padding-bottom: 10px;
  > .toasts-container {
    position: fixed;
    width: 33vw;
    height: 50%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &.top {
      padding-top: 20px;
    }
    &.bottom {
      top: 50%;
      justify-content: flex-start;
      padding-bottom: 20px;
      flex-direction: column-reverse;
    }
    &.left {
      padding-left: 20px;
      align-items: flex-start;
    }
    &.center {
      left: 33vw;
      align-items: center;
    }
    &.right {
      right: 0;
      padding-right: 20px;
      align-items: flex-end;
    }
    :deep(.u-toast) {
      pointer-events: all;
    }
  }
  &:empty {
    display: none;
  }
}
.debug {
  &.toasts {
    border: 1px solid red;
    > .toasts-container {
      border: 1px solid blue;
      &.bottom {
        border-color: green;
      }
    }
  }
}
.u-toast-container:empty {
  display: none;
}
</style>
