import { get } from '@/helpers/http'

export const filtersModule = {
  state: {
    programs: [],
    territories: [],
  },

  getters: {
    filters: (state) => ({
      programs: state.programs.filter((p) => p.selected),
      territories: state.territories.filter((t) => t.selected),
    }),
    territories: (state) => state.territories,
    programs: (state) => state.programs,
    selectedTerritories: (state) => state.territories.filter((t) => t.selected),
    selectedPrograms: (state) => state.programs.filter((p) => p.selected),
    queryUrl: (state) => {
      const query = {
        territories: state.territories
          .filter((t) => t.selected)
          .map((t) => t.id)
          .sort()
          .join(','),
        programs: state.programs
          .filter((t) => t.selected)
          .map((t) => t.id)
          .sort()
          .join(','),
      }
      if (!query.territories) {
        delete query.territories
      }
      if (!query.programs) {
        delete query.programs
      }
      return query
    },
    queryFilter: (state) => {
      const query = {
        territory: state.territories
          .filter((t) => t.selected)
          .map((t) => t.id)
          .sort(),
        program: state.programs
          .filter((t) => t.selected)
          .map((t) => t.id)
          .sort(),
      }
      if (!query.territory.length) {
        delete query.territory
      }
      if (!query.program.length) {
        delete query.program
      }
      return query
    },
  },

  mutations: {
    setPrograms(state, programs) {
      state.programs = programs.map((v) => {
        v.selected = false
        return v
      })
    },
    setTerritories(state, territories) {
      state.territories = territories.map((v) => {
        v.selected = false
        return v
      })
    },
    selectPrograms(state, programs) {
      programs = programs
        .map((program) => {
          if (typeof program === 'object') {
            return program
          }
          return state.programs.find((p) => p.id === program)
        })
        .filter((item) => item)

      state.programs.forEach((program) => (program.selected = false))
      programs.forEach((program) => (program.selected = true))
    },
    selectTerritories(state, territories) {
      territories = territories
        .map((territory) => {
          if (typeof territory === 'object') {
            return territory
          }
          return state.territories.find((p) => p.id === territory)
        })
        .filter((item) => item)

      state.territories.forEach((territory) => (territory.selected = false))
      territories.forEach((territory) => (territory.selected = true))
    },
  },

  actions: {
    loadTerritories({ commit }) {
      return get('support/territories', {}, { impersonate: false }).then((territories) => {
        commit('setTerritories', territories)
      })
    },
    loadPrograms({ commit }) {
      return get('support/programs', {}, { impersonate: false }).then((programs) => {
        commit('setPrograms', programs)
      })
    },
  },

  namespaced: true,
}
