import { weekdays } from '@/const/time'
import { formatNumber } from '@/helpers/Setup'
import { get, put } from '@/helpers/http'

const schedulesInitial = () =>
  weekdays.map((day, i) => {
    return {
      periods: [
        {
          start: { hours: 9, minutes: 0, isValid: true },
          end: { hours: 18, minutes: 0, isValid: true },
        },
      ],
      is_open: i < 5,
      day,
    }
  })

function formatSchedules(schedules) {
  if (!schedules) {
    return []
  }
  return schedules.map((el) => {
    el.periods = el.periods.map((period) => {
      return {
        start: {
          hours: parseInt(period.start_time.substring(0, 2)),
          minutes: parseInt(period.start_time.substring(3)),
          isValid: true,
        },
        end: {
          hours: parseInt(period.end_time.substring(0, 2)),
          minutes: parseInt(period.end_time.substring(3)),
          isValid: true,
        },
      }
    })
    el.is_open = !!el.periods.length
    if (!el.periods.length) {
      el.periods = [
        {
          start: { hours: 9, minutes: 0, isValid: true },
          end: { hours: 18, minutes: 0, isValid: true },
        },
      ]
    }
    return el
  })
}

function formatForBackend(schedules) {
  if (!schedules) return []
  return schedules.map((day) => {
    let periods
    if (day.is_open) {
      periods = day.periods.map((period) => {
        return {
          start_time: formatNumber(period.start.hours % 24) + ':' + formatNumber(period.start.minutes),
          end_time: formatNumber(period.end.hours % 24) + ':' + formatNumber(period.end.minutes),
        }
      })
    } else {
      periods = []
    }
    return {
      day: day.day,
      periods,
    }
  })
}

export const schedulesModule = {
  state: {
    schedules: schedulesInitial(),
    loadingSchedules: false,
  },

  getters: {
    schedules: (state) => state.schedules,
    loadingSchedules: (state) => state.loadingSchedules,
    today: (state) => {
      const today = new Date().getDay()
      return state.schedules[today].day
    },
  },

  mutations: {
    setLoading(state, { name, isLoading }) {
      state[name] = isLoading
    },
    set(state, schedules) {
      state.schedules = formatSchedules(schedules)
    },
    initSchedules(state) {
      state.schedules = schedulesInitial()
    },
    update(state, schedules) {
      state.schedules = formatSchedules(schedules)
    },
    clear(state) {
      state.schedules = []
    },
  },

  actions: {
    async loadSchedules({ commit, rootGetters }, { siteId }) {
      if (!rootGetters['site/isSchedulesDefined']) {
        commit('initSchedules')
        return Promise.resolve()
      }
      commit('setLoading', { name: 'loadingSchedules', isLoading: true })
      const schedules = await get(`presence/schedules/${siteId}`)
      commit('setLoading', { name: 'loadingSchedules', isLoading: false })

      if (!schedules?.data) return
      commit('set', schedules.data)
    },
    updateSchedules({ commit, dispatch }, { site_id, data }) {
      const schedules = formatForBackend(data)
      return put('/presence/schedules/', { site_id, data: schedules })
        .then((schedules) => {
          commit('update', schedules.data)
          return dispatch('site/completeSchedules', {}, { root: true })
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    clear({ commit }) {
      commit('clear')
    },
  },

  namespaced: true,
}
