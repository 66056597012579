/**
 * Convert color to css color or css variable
 * @param {string} color Convert color to css color or css variable
 */
export function parse(color) {
  if (!color) {
    return color
  }
  if (color.startsWith('--')) {
    return `var(${color})`
  }
  return color
}
