import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueTippy from 'vue-tippy'
import { router } from '@/plugins/router'
import '@/plugins/yrtnes'
import { initSentryPlugin } from '@/plugins/yrtnes'
import '@/services/config'
import { useGDPR } from '@/services/gdpr'
import { store } from '@/store/vuex/index'
import App from './App.vue'
import './plugins/DayJS'
import './plugins/VueHighcharts'
import i18n from './plugins/VueI18n'

useGDPR()

const pinia = createPinia()
const app = createApp(App)

app.use(autoAnimatePlugin)
app.use(i18n)
app.use(store)
app.use(pinia)
app.use(router)
app.use(VueTippy)
initSentryPlugin(app)
store.dispatch('auth/listen')
app.mount('#app')
