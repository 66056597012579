import { isNumeric } from '@/helpers/maths'
import { kebab } from '@/helpers/strings'

/**
 * Parse and convert a given query string to a simple key-value hash,
 * in order to be easily used in JS code.
 *
 * @deprecated
 * @param  {string} str [The query string to parse]
 * @return {Object}
 */
export function parse(url) {
  if (!url) return {}
  const index = url.indexOf('?')
  if (~index) {
    url = url.substring(index + 1)
  }
  const parts = url.split('&')
  return parts.reduce((result, part) => {
    part = part.split('=')
    let value = decodeURIComponent(part[1] ?? '')
    if (isNumeric(value)) {
      value = parseFloat(value)
    } else if (value === '' || value === 'true') {
      value = true
    }
    if (value === 'false' || value === 0) {
      value = false
    }
    let key = decodeURIComponent(part[0])
    const isArray = (result[key] !== undefined && result[key] !== null) || key.endsWith('[]')
    if (isArray) {
      if (key.endsWith('[]')) {
        key = key.substring(0, key.length - 2)
      }
      if (!Array.isArray(result[key])) {
        if (result[key] !== undefined && result[key] !== null) {
          result[key] = [result[key]]
        } else {
          result[key] = []
        }
      }
      result[key].push(value)
    } else {
      result[key] = value
    }

    return result
  }, {})
}
/**
 * Convert object to query params
 * @deprecated
 * @param  {Object} params
 * @return {string}
 */
export function stringify(params) {
  return Object.keys(params ?? {})
    .map((p) => {
      if (Array.isArray(params[p])) {
        return params[p].map((v) => stringify({ [p]: v })).join('&')
      }
      return `${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`
    })
    .join('&')
}
/**
 * safely join paths for uri
 * @deprecated
 * @returns
 */
export function join(...paths) {
  if (!paths.length) {
    return null
  }
  let uri = paths[0]
  for (let i = 1; i < paths.length; i++) {
    if (!uri.endsWith('/')) {
      uri += '/'
    }
    if (paths[i].startsWith('/')) {
      uri += paths[i].substring(1)
    } else {
      uri += paths[i]
    }
  }
  return uri
}
/**
 * Convert string value to friendly url string (with no weird character)
 * @deprecated
 * @param {string} string original string
 * @returns
 */
export function clean(string) {
  return encodeURIComponent(kebab(`${string}`, '-').replace(/[^a-zA-Z0-9-_]/g, ''))
}
