<template>
  <span class="u-loader" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    default: '#000',
  },
  size: {
    type: [Number, String],
    default: 48,
    validator(v) {
      return Number.isFinite(Number(v))
    },
  },
  width: {
    type: [Number, String],
    default: 4,
    validator(v) {
      return Number.isFinite(Number(v))
    },
  },
})
const sizePx = computed(() => props.size + 'px')
const borderWidth = computed(() => props.width + 'px')
</script>

<style lang="scss" scoped>
@keyframes rotate {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}
.u-loader {
  display: inline-block;
  width: v-bind(sizePx);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border-style: solid;
  border-width: v-bind(borderWidth);
  border-color: #ededed;
  border-top-color: v-bind(color);
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
</style>
