<template>
  <footer class="u-footer">
    <div class="left">
      <router-link :to="{ name: 'dashboard' }">
        <div class="u-logo-container">
          <img src="@/assets/img/logo-udwi-white.svg" :alt="t('footer.altImg.logo')" loading="lazy" />
        </div>
      </router-link>

      <p class="trademark">@ Eco CO2</p>
    </div>

    <nav>
      <ul>
        <li class="nav-item">
          <a href="https://www.ecoco2.com" target="_blank">
            <p v-t="'footer.discover'" />
          </a>
        </li>

        <li class="nav-item">
          <a href="javascript:void 0;" @click="goTo('legales')">
            <p v-t="'footer.legales'" />
          </a>
        </li>

        <template v-if="user">
          <li class="nav-item">
            <a href="javascript:void 0;" @click="goTo('tos')">
              <p v-t="'footer.tos'" />
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:void 0;" @click="goTo('privacy')">
              <p v-t="'footer.privacy'" />
            </a>
          </li>
        </template>
      </ul>
    </nav>
  </footer>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { join } from '@/helpers/uri'
import { config } from '@/services/config'
import { fetchContact } from '@/services/contact'

const { t } = useI18n()

const store = useStore()

const programs = computed(() => store.getters['user/programs'])
const territory = computed(() => store.getters['user/territory'])
const user = computed(() => store.getters['user/user'])
const tos = ref('')
const privacy = ref('')
const legales = ref('accounts/legal-notice')

async function goTo(type: string) {
  const res = await fetchContact([...programs.value, territory.value])

  if (res) {
    tos.value = res.tos
    privacy.value = res.privacy
  }
  if (!user.value) {
    return
  }

  let url: string
  if (type === 'legales') {
    url = legales.value
  } else {
    url = type === 'privacy' ? privacy.value : tos.value
  }

  if (!url) {
    return
  }
  window.location.href = join(config('app.opossum'), url)
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

footer {
  background-color: $dark;
  min-height: $u-footer-height;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 2em 5vw;
  z-index: 1;
  gap: 2em;
  @media screen and (max-width: $screen_s) {
    padding: 3em;
    grid-template-columns: auto;
    gap: 2.5em;
    p {
      text-align: center;
    }
  }

  .left {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2em;
    align-items: center;
    @media screen and (max-width: $screen_s) {
      grid-template-columns: auto;
      gap: 1em;
    }
    .u-logo-container {
      width: 70px;
      margin: 0 auto;
      > img {
        width: 100%;
      }
    }
    .trademark {
      @include u-txt-xs;
      color: $grey;
      white-space: nowrap;
    }
  }

  nav {
    ul {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 2em;
      align-items: center;
      @media screen and (max-width: $screen_s) {
        grid-template-columns: auto;
        gap: 1.25em;
      }

      li {
        a {
          @include u-txt-xs;
          @include PrimaryFontSemiBold;
          color: $grey-light;
          text-decoration: none;
          transition: color linear 300ms;
          &:hover {
            color: $grey;
          }
        }
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "footer": {
      "altImg": {
        "logo": "Logo Udwi"
      },
      "discover": "Découvrir Eco CO2",
      "legales": "Mentions légales",
      "privacy": "Politique de confidentialité",
      "tos": "Conditions Générales d'Utilisation"
    }
  }
}
</i18n>
