import clone from 'clone'
import { get, post } from '@/helpers/http'
import { clean as cleanObject } from '@/helpers/objects'
import { clean } from '@/helpers/uri'

export const ecotipsModule = {
  state: {
    ecotips: [],
    loadingEcotips: false,
    categories: [],
    loadingCategories: false,
  },

  getters: {
    ecotips: (state) => state.ecotips,
    loadingEcotips: (state) => state.loadingEcotips,
    toRealized: (state) =>
      state.ecotips.filter((e) => !e.started_on && !e.multiple_times_on && !e.habit_on && !e.archived),
    realized: (state) => state.ecotips.filter((e) => e.habit_on),
    archived: (state) => state.ecotips.filter((e) => e.archived),
    categories: (state) => state.categories,
    loadingCategories: (state) => state.loadingCategories,
    subcategories: (state) => state.categories.flatMap((c) => c.subcategories),

    getCategory: (state) => (name) => {
      return state.categories.find((category) => category.name === name || category.id === name)
    },
    getSubCategory: (state) => (name, subcategory) => {
      const category = state.categories.find((category) => category.name === name || category.id === name)
      if (!category) {
        return null
      }
      return category.subcategories.find(
        (sub) => sub.name?.toString() === subcategory?.toString() || sub.id?.toString() === subcategory?.toString(),
      )
    },
    getSubcategories: (state) => (name) => {
      return state.categories.find((category) => category.name === name || category.id === name)?.subcategories ?? []
    },
    getEcotip: (state) => {
      return (id) => {
        return state.ecotips.find((e) => e.id?.toString() === id?.toString())
      }
    },
  },

  mutations: {
    setLoading(state, { name, isLoading }) {
      state[name] = isLoading
    },
    setEcotips(state, ecotips) {
      state.ecotips = ecotips.map((ecotip) => {
        if (!ecotip.gains) {
          ecotip.gains = 0
        }
        if (!ecotip.implementation_easyness) {
          ecotip.implementation_easyness = 0
        }
        return ecotip
      })
    },
    setCategories(state, categories) {
      state.categories = categories.map((category) => {
        category.name = clean(category.title.toLowerCase())
        category.subcategories = (category.subcategories ?? [])
          .map((subcategory) => {
            subcategory.name = clean(subcategory.title.toLowerCase())
            return subcategory
          })
          .sort((a, b) => a.title.localeCompare(b.title))
        return category
      })
    },
    archived(state, { ecotip, archived }) {
      const index = state.ecotips.findIndex((t) => t.id?.toString() === ecotip.id?.toString())
      if (index > -1) {
        const tip = state.ecotips[index]
        tip.archived = archived
      }
    },
    updateState(state, { ecotip, data, merge }) {
      const index = state.ecotips.findIndex((t) => t.id?.toString() === ecotip.id?.toString())
      if (index > -1) {
        const tip = state.ecotips[index]
        tip.started_on = null
        tip.multiple_times_on = null
        tip.habit_on = null
        tip.archived = false
        if (merge) {
          data = clone(data)
          state.ecotips.splice(index, 1, Object.assign({}, tip, cleanObject(data)))
        } else {
          state.ecotips.splice(index, 1, Object.assign({}, tip))
        }
      }
    },
    clear(state) {
      state.ecotips = []
      state.categories = []
    },
  },

  actions: {
    async loadEcotips({ commit }, { siteId }) {
      commit('setLoading', { name: 'loadingEcotips', isLoading: true })
      const ecotip = await get(`help/${siteId}/tips`)
      commit('setLoading', { name: 'loadingEcotips', isLoading: false })
      commit('setEcotips', ecotip)
    },
    async loadCategories({ commit }, { siteId }) {
      commit('setLoading', { name: 'loadingCategories', isLoading: true })
      const categories = await get(`help/${siteId}/tips/categories`)
      commit('setLoading', { name: 'loadingCategories', isLoading: false })
      commit('setCategories', categories)
    },
    async updateState({ commit, dispatch }, { site: siteId, ecotip, data }) {
      commit('updateState', { ecotip, data, merge: true })
      try {
        await post('help/tips/action', { ...data, site: siteId, tip: ecotip.id }, { retry: true })
      } catch (error) {
        dispatch('loadEcotips', { siteId, force: true })
      }
    },
    async archived({ commit, dispatch }, { site: siteId, ecotip, archived }) {
      commit('archived', { ecotip, archived })

      try {
        await post('help/tips/action', { archived, site: siteId, tip: ecotip.id }, { retry: true })
      } catch (error) {
        dispatch('loadEcotips', { siteId, force: true })
      }
    },
    clear({ commit }) {
      commit('clear')
    },
  },

  namespaced: true,
}
