/* eslint-disable eqeqeq */
import clone from 'clone'
import deepmerge from 'deepmerge'
import * as configFiles from '@/config'

export class Configuration {
  constructor() {
    this.data = configFiles
  }

  keys() {
    return this.buildKeys(this.data)
  }

  buildKeys(data, prefix = '') {
    let keys = Object.keys(data)
    keys = keys.reduce((previous, key) => {
      const item = data[key]
      if (item && typeof item === 'object') {
        previous = previous.concat(this.buildKeys(item, prefix + key + '.'))
      }
      return previous
    }, keys)
    return keys.map((item) => prefix + item)
  }

  env() {
    return this.get('app.environment')
  }

  isDebug() {
    const isDebug = this.get('app.debug')
    if (isDebug != undefined) {
      return isDebug
    }
    return !this.isProduction()
  }

  isLocal() {
    return !!~this.env().indexOf('local')
  }

  isProduction() {
    return !!~this.env().indexOf('production')
  }

  merge(key, data) {
    if (typeof key === 'string') {
      if (this.data[key]) {
        this.data[key] = deepmerge(this.data[key], data)
      } else {
        this.data[key] = data
      }
    } else {
      data = key
      this.data = deepmerge(this.data, data)
    }
    return this.get()
  }

  has(key) {
    if (!key) {
      return false
    }
    const keys = key.split('.')
    // not last
    key = keys.pop()
    let current = this.data
    for (const k of keys) {
      if (current[k] == null) {
        current[k] = {}
      }
      if (typeof current[k] !== 'object') {
        current[k] = {}
      }
      current = current[k]
    }
    return current[key] != undefined
  }

  get(key, defaultValue = null) {
    if (!key) {
      return clone(this.data)
    }
    const keys = key.split('.')
    // not last
    key = keys.pop()
    let current = this.data
    for (const k of keys) {
      if (current[k] == null) {
        current[k] = {}
      }
      if (typeof current[k] !== 'object') {
        current[k] = {}
      }
      current = current[k]
    }
    return current[key] != undefined ? current[key] : defaultValue
  }

  set(key, value) {
    const keys = key.split('.')
    // not last
    key = keys.pop()
    let current = this.data
    for (const k of keys) {
      if (current[k] == null) {
        current[k] = {}
      }
      if (typeof current[k] !== 'object') {
        console.warn('Configuration nested key not object', current, keys, k)
        current[k] = {}
      }
      current = current[k]
    }
    if (current[key] === value) {
      return
    }
    current[key] = value
  }

  remove(key) {
    const keys = key.split('.')
    // not last
    key = keys.pop()
    let current = this.data
    for (const k of keys) {
      if (current[k] == null) {
        current[k] = {}
      }
      if (typeof current[k] !== 'object') {
        console.warn('Configuration nested key not object', current, keys, k)
        current[k] = {}
      }
      current = current[k]
    }
    delete current[key]
  }

  writeExternal() {
    return clone(this.data)
  }

  readExternal(data) {
    this.data = data
  }
}
export const configuration = new Configuration()
export function config(key, value) {
  if (value != undefined) {
    return configuration.set(key, value)
  }
  return configuration.get(key)
}
config.instance = configuration
// add Configuration methods to config
for (const key of Object.getOwnPropertyNames(Configuration.prototype)) {
  if (key === 'constructor') {
    continue
  }
  if (typeof configuration[key] === 'function') {
    config[key] = configuration[key].bind(configuration)
  }
}
