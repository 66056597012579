<template>
  <li class="u-autocomplete-item" :class="classes" :disabled="disabled" @click="click">
    <AsyncIcon v-if="icon" :name="icon" :size="24" color="currentColor" />
    <div v-if="text || $slots.default" class="data">
      <slot>{{ text }}</slot>
    </div>
  </li>
</template>

<script>
import AsyncIcon from '@/components/icons/AsyncIcon.vue'

export default {
  name: 'UAutoCompleteItem',
  components: {
    AsyncIcon,
  },
  emits: ['click'],
  props: {
    text: {
      type: String,
      required: false,
    },
    icon: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    classes() {
      const classes = []
      if (this.icon) {
        classes.push('icon')
      }
      return classes
    },
  },

  methods: {
    click(e) {
      if (this.disabled) {
        return
      }
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

.u-autocomplete-item {
  min-height: $u-autocomplete-height;
  padding: $u-autocomplete-padding-y $u-autocomplete-padding-x;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $cream;
    p {
      font-weight: 500;
    }
  }
  &:focus,
  &:active {
    background-color: $cream-dark;
  }
  &[selected] {
    &:hover {
      background-color: $cream-dark;
    }
    p {
      font-weight: 500;
    }
  }
  &[key-selected] {
    p {
      font-weight: 500;
    }
  }
  svg {
    margin-right: 0.75em;
  }
  .data {
    display: grid;
    gap: 0.5em;
    align-items: center;
    justify-content: space-between;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto auto auto 40px;
    @media screen and (max-width: $screen_s) {
      flex-direction: column;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
    }
  }
}
</style>
