type Fn<T> = (v: T) => number

/**
 * Test if value is numeric
 * @param {*} data to test
 * @return {Boolean}
 */
export function isNumeric<T>(data: T): boolean {
  if (typeof data !== 'string' && typeof data !== 'number') {
    return false
  }
  let newNum
  if (typeof data === 'string') {
    newNum = parseFloat(data)
  } else {
    newNum = data
  }

  return !isNaN(newNum) && isFinite(newNum)
}
/**
 * Return random integer [min, max]
 * If only one number is given it will return a random integer between [0, number[
 * @param {number} min
 * @param {number} max
 */
export function randomInteger(min: number, max: number): number {
  if (max === undefined) {
    max = min - 1 // usefull when given array/string length
    min = 0
  }
  return Math.floor(Math.random() * (max - min + 1) + min)
}
/**
 * Sum all values in array
 * @param {array} values Values to sum
 * @param {Function} map If needed used to map values before sum
 * @returns sum
 */
export function sum<T = number>(values: T[], map?: Fn<T>): number {
  if (!map) {
    const newArr = values as number[]
    return newArr.reduce((total, value) => value + total, 0) as number
  }
  return values.reduce((total: number, value: T) => map(value) + total, 0) as number
}
/**
 * Return max value in array
 * @param {array} values Array of values
 * @param {Function} map If needed use mapped values to find max
 * @returns max value/object
 */
export function max<T = number>(values: T[], map?: Fn<T>): number | T {
  if (!map) {
    return values.reduce((total, value) => (total > value ? total : value), values[0])
  }
  return values.reduce((total, value) => (map(total) > map(value) ? total : value), values[0])
}
/**
 * Return min value in array
 * @param {array} values Array of values
 * @param {Function} map If needed use mapped values to find min
 * @returns min value/object
 */
export function min<T = number>(values: T[], map?: Fn<T>): number | T {
  if (!map) {
    return values.reduce((total, value) => (total < value ? total : value), values[0])
  }
  return values.reduce((total, value) => (map(total) < map(value) ? total : value), values[0])
}
/**
 * format number to have at least min digits
 * @param {number} num number to format
 * @param {number} min min of digits
 * @returns string
 */
export function minDigits(num: number, min = 0): string {
  let strNum = num + ''
  while (strNum.length < min) {
    strNum = '0' + strNum
  }
  return strNum
}
/**
 * Test if value is number only (12e4 not included)
 * @param {number} num number to format
 * @returns boolean
 */
export function isValidNumber(num: number): boolean {
  if (typeof num !== 'number' && typeof num !== 'string') return false
  const regex = /^-?[0-9]+((\.|,)[0-9]+)?$/

  return regex.test(`${num}`)
}

/**
 * Parse string to convert coma to dot and set maximum digits
 * @param {string|number} value The value to convert
 * @param {number} digits The number of digits to appear after the decimal point
 * @returns {string|null}
 */
export function parseDecimal(value: string | number, digits = 0): string {
  if (typeof value !== 'number' && typeof value !== 'string') return ''
  if (!digits) {
    return parseFloat(`${value}`.replace(',', '.')).toString()
  }
  return parseFloat(`${value}`.replace(',', '.')).toFixed(digits)
}

/**
 * Fixe number of digits after decimal point without rounding
 * @param {number} number The number to round
 * @param {number} precision The number of digits to appear after the decimal point
 * @returns {number}
 */
export function toFixedNoRound(number: number, precision = 1): number {
  if (typeof number !== 'number') return 0
  const factor = Math.pow(10, precision)
  return Math.floor(number * factor) / factor
}
