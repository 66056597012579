<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import TextBadge from '@/components/Shared/TextBadge.vue'
import AsyncIcon from '@/components/icons/AsyncIcon.vue'
import UTooltip from '@/components/ui/UTooltip.vue'
import { useLayoutStore } from '@/store/pinia/layout'

const { t } = useI18n()

type TargetType = '_self' | '_blank'
type BadgeType = 'new' | 'intern'

const router = useRouter()
const store = useStore()
const layoutStore = useLayoutStore()

const props = defineProps({
  to: {
    type: Object as PropType<RouteLocationRaw | string>,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  target: {
    type: String as PropType<TargetType>,
    default: '_self',
  },
  icon: String,
  badge: String as PropType<BadgeType>,
  disabled: Boolean,
  tooltipText: String,
  hasNewContent: Boolean,
})

const max = computed(() => store.getters['responsive/max'])
const sScreenSize = computed(() => max.value('s'))

const closeNavigation = () => {
  layoutStore.toggle()
}

const badgeContent = computed<string>(() =>
  props.badge
    ? {
        new: t('new_toast'),
        intern: t('is_intern_toast'),
      }[props.badge]
    : '',
)
const badgeColor = computed<string>(() =>
  props.badge
    ? {
        new: 'var(--kikorangi-blue)',
        intern: 'var(--red-dark)',
      }[props.badge]
    : '',
)
const badgeTextColor = computed<string>(() =>
  props.badge
    ? {
        new: 'white',
        intern: 'white',
      }[props.badge]
    : '',
)

const onClick = (): void => {
  if (props.disabled) return
  if (typeof props.to === 'string') {
    window.open(props.to, props.target)
    return
  }
  router.push(props.to)
  if (sScreenSize.value) {
    closeNavigation()
  }
}
</script>

<template>
  <li class="u-txt-sm">
    <RouterLink :to="props.to" custom v-slot="{ href, isActive }">
      <a
        :href="href"
        @click.prevent="onClick"
        class="flex items-center content-between py-1 px-4 txt-semibold no-underline"
        :class="{ active: isActive, disabled: props.disabled }"
      >
        <span class="flex items-center">
          <AsyncIcon v-if="props.icon" :name="props.icon" :size="24" class="mr-2" />
          <span class="item-label">{{ label }}</span>
        </span>
        <span class="flex items-center">
          <span v-if="hasNewContent" class="red-dot" :class="{ 'mr-2': props.badge || props.tooltipText }" />
          <TextBadge
            v-if="props.badge"
            :content="badgeContent"
            :color="badgeTextColor"
            :background-color="badgeColor"
            bold
            size="sm"
          />
          <UTooltip v-if="props.tooltipText" :text="props.tooltipText" placement="right" class="item-tt ml-1" />
        </span>
      </a>
    </RouterLink>
  </li>
</template>

<style lang="scss" scoped>
li {
  a:hover {
    background-color: var(--cream-light);
  }
  a.active,
  a.active:hover {
    background-color: var(--cream);
  }
  a.disabled,
  a.disabled:hover {
    background-color: transparent;

    .item-label {
      opacity: 0.5;
    }
  }

  .red-dot {
    background-color: var(--red);
    width: 7px;
    border: solid 1px var(--white);
    border-radius: 50%;
    aspect-ratio: 1;
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "new_toast": "nouveau",
    "is_intern_toast": "interne"
  }
}
</i18n>
