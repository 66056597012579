import clone from 'clone'
import { get, patch } from '@/helpers/http'
import { empty } from '@/helpers/misc'
import { sentry } from '@/plugins/yrtnes'
import { hero } from '@/services/helphero'

export const userModule = {
  state: {
    user: null,
    isLoadingUser: false,
  },

  getters: {
    user: (state) => state.user,
    loadingUser: (state) => state.isLoadingUser,
    userId: (state) => state.user?.user_id ?? state.user?.email_address,
    email: (state) => state.user?.email_address,
    territory: (state) => {
      const territory = typeof state.user?.territory === 'string' ? state.user.territory : state.user?.territory?.slug
      return territory?.toLowerCase()
    },
    mvp: (state) => !!state.user?.groups?.includes('mvp'),
    programs: (state) => {
      const programs = state.user?.programs ?? []
      return programs
        .filter((program) => program)
        .map((program) => (typeof program === 'string' ? program : program?.slug))
        .map((program) => program.toLowerCase())
    },
    groups: (state) => state.user?.groups ?? [],
    userType: (state) => state.user?.user_type.toLowerCase(),
    isSetup: (state) => !!state.user?.wizard_complete,
    isCompany: (state) => state.user?.user_type === 'PROFESSIONAL',
    isSeize: (state) => state.user?.programs.includes('seize'),
    isZeste: (state) => state.user?.programs.includes('zeste'),
    isUdwi: (state) => state.user?.programs.includes('commercialisation_udwi'),
    isMetropole: (state) => {
      if (state.user?.territory === 'corse') {
        return true
      }
      if (state.user?.territory === 'france_metropole') {
        return true
      }
      return false
    },
    isIntern: (state) => state.user.programs.includes('interne'),
    username: (state) => {
      if (!state.user) {
        return null
      }
      if (!state.user.first_name && !state.user.last_name) {
        return state.user.email_address ?? state.user.email
      }
      return `${state.user.first_name} ${state.user.last_name}`
    },
    canImpersonate: (state) => state.user.can_impersonate,
  },

  mutations: {
    set(state, user) {
      state.user = user
    },
    update(state, user) {
      // force update
      state.user = Object.assign({}, state.user, user)
    },
    setLoading(state, value) {
      state.isLoadingUser = value
    },
  },

  actions: {
    loadUser({ commit, rootGetters, getters, dispatch }) {
      commit('setLoading', true)
      if (!rootGetters['auth/isConnected']) {
        commit('setLoading', false)
        return Promise.resolve()
      }
      return get('me')
        .then(async (user) => {
          commit('set', user)
          await dispatch('acl/setACL', user, { root: true })
          if (!rootGetters['impersonate/impersonating']) {
            hero().identify(getters.userId, {
              is_seize: getters.isSeize,
              is_zeste: getters.isZeste,
              is_commercialisation_udwi: getters.isUdwi,
              territory: getters.territory,
              is_company: getters.isCompany,
              days_since_installation_end: getters.user.days_since_wizard_complete ?? 0,
            })
          }
          const context = {
            user_id: getters.userId,
            is_seize: getters.isSeize,
            is_zeste: getters.isZeste,
            is_metropole: getters.isMetropole,
            is_commercialisation_udwi: getters.isUdwi,
            territory: getters.territory,
            type: getters.userType,
            programs: getters.programs,
            role: rootGetters['acl/role'],
            days_since_installation_end: getters.user.days_since_wizard_complete ?? 0,
          }
          if (rootGetters['impersonate/impersonating']) {
            context.impersonator = rootGetters['impersonate/impersonator']?.email_address
          }
          commit('setLoading', false)
          sentry.setUser(context)
          sentry.setTag('user_type', context.type)
          return user
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async updateUser({ commit, state, getters }, { setup, ...userInfos } = {}) {
      if (!empty(userInfos)) {
        const previous = clone(state.user)
        commit('update', userInfos)
        await patch('me/profile', userInfos)
          .then((user) => {
            commit('update', user)
            return user
          })
          .catch((e) => {
            commit('update', previous)
            return Promise.reject(e)
          })
      }
      if (setup) {
        const previous = getters.isSetup
        commit('update', { wizard_complete: setup })
        await patch('me', { wizard_complete: setup }).catch((e) => {
          commit('update', { wizard_complete: previous })
          return Promise.reject(e)
        })
      }
    },
    clear({ dispatch, commit }) {
      commit('set', null)
      dispatch('acl/clear', {}, { root: true })
      dispatch('auth/logout', {}, { root: true })
      return dispatch('sites/clear', {}, { root: true })
    },
  },

  namespaced: true,
}
