import EventEmitter2 from 'eventemitter2'
import { config } from '@/services/config'

/**
 * Local storage helper
 * Emits event from other tabs when they edit localstorage
 * event = edited key
 */
const prefix = () => config('auth.key').substring(0, 6)
class Storage extends EventEmitter2 {
  constructor() {
    super({ wildcard: true, maxListeners: 0 })
  }

  getItem(name) {
    name = `${prefix()}.${name}`
    const item = localStorage.getItem(name)
    if (item) {
      try {
        return JSON.parse(item)
      } catch (error) {
        return item // for old value
      }
    }
    return item
  }

  setItem(name, value) {
    name = `${prefix()}.${name}`
    return localStorage.setItem(name, JSON.stringify(value))
  }

  removeItem(name) {
    name = `${prefix()}.${name}`
    return localStorage.removeItem(name)
  }

  hasItem(name) {
    name = `${prefix()}.${name}`
    return !!localStorage.getItem(name)
  }

  clear() {
    return !!localStorage.clear()
  }
}

export const storage = new Storage()

window.addEventListener('storage', (event) => {
  const value = storage.getItem(event.key)
  storage.emit(event.key, value)
})
