import { defineStore } from 'pinia'
import { store } from '@/store/vuex/index'

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    navigation: {
      open: false,
      visible: true,
    },
  }),

  getters: {
    navigationOpen: (state) => state.navigation.open && state.navigation.visible,
  },

  actions: {
    initNavigation() {
      const min = store.getters['responsive/min']
      if (!store.getters['responsve/isMobile'] && min('lg')) {
        this.toggle()
      }
    },
    toggle() {
      this.navigation.open = !this.navigation.open
    },
  },
})
