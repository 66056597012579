import initHelpHero from 'helphero'
import { HelpHero } from 'helphero'
import { config } from '@/services/config'

let instance: HelpHero
export function hero() {
  if (instance) {
    return instance
  }
  if (config('help.hero.enabled') && config('help.hero.id')) {
    return (instance = initHelpHero(config('help.hero.id')))
  }
}
