import { randomInteger } from '@/helpers/maths'

export function kebab(text: string, delimiter = '_'): string {
  if (text === null || text === undefined) return text
  text = `${text}`
  text = text.substring(0, 1).toLowerCase() + text.substring(1)
  text = text.replace(/([A-Z])/g, ' $1')
  text = text.replace(/ /g, delimiter)
  text = text.replace(new RegExp(delimiter + delimiter, 'g'), delimiter)

  return text.toLowerCase()
}

export function kebabCase(text: string, delimiter = '_'): string {
  return kebab(text, delimiter)
}

export function pascalCase(str: string) {
  const arr = str.split('-')
  return arr.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('')
}

export function firstUpperCase(text: string): string {
  if (text === null || text === undefined) return text
  text = `${text}`

  return text.charAt(0).toUpperCase() + text.substring(1)
}

export function random(length = 32): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let token = ''
  while (length--) {
    token += chars[randomInteger(0, chars.length - 1)]
  }

  return token
}

export function isEmail(email: string): boolean {
  const rgx =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i // eslint-disable-line

  return rgx.test(email)
}

export function removeAccents(text: string): string {
  if (text === null || text === undefined) return text
  text = `${text}`

  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
