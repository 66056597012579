/**
 * Returns a promise with method resolve/reject inside
 * @deprecated
 * @returns
 */
export function self() {
  let resolve, reject
  const promise = new Promise((r, j) => {
    resolve = (parameter) => {
      promise.triggered = true
      promise.resolved = true
      return r(parameter)
    }
    reject = (parameter) => {
      promise.triggered = true
      promise.rejected = true
      return j(parameter)
    }
  })
  promise.triggered = false
  promise.resolved = false
  promise.rejected = false
  promise.resolve = resolve
  promise.reject = reject
  return promise
}
