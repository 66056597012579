import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestTransformer,
  RawAxiosRequestHeaders,
  ResponseType,
} from 'axios'
import { toast } from '@/components/ui/toasts/toast'
import { config } from '@/services/config'
import { store } from '@/store/vuex/index'

// TODO: manage refresh/logout processes outside of helper
// TODO: reimplement an intelligent "retry request" process
// TODO: return the full `response` instance instead of `response.data` to allow access to other response informations (code, error, ...)
// TODO: add UT

type UserOptions = {
  /**
   * @description If `true`, uses the opossum server baseURL instead of hippo.
   * @default false
   */
  opossum?: boolean
  /**
   * @deprecated use `useFormData` instead
   * @description If `true`, converts the request body to the "FormData" format.
   * @default false
   */
  formData?: boolean
  /**
   * @description Alias of `formData`.
   * @default false
   */
  useFormData?: boolean
  /**
   * @deprecated use the `q` argument of dedicated methods instead (`.get`, `.post`, ...).
   * @description Object of query params to be sent with the request.
   * @default undefined
   */
  params?: Record<string, string>
  /**
   * @description By default all requests will check the state of the "impersonate" store and automatically add the corresponding header if needed. Setting this option to `false` will force the request to be made without impersonating.
   */
  impersonate?: boolean
}
type InternalOptions = UserOptions & {
  responseType?: ResponseType
  headers?: Record<string, string>
}

type RequestMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
type RequestQuery = Record<string, unknown>
type ResponseCode = 0 | 200 | 400 | 401 | 403 | 404 | 405 | 408 | 409 | 410 | 429 | 500 | 502 | 503 | 504
type ResponseMessage =
  // other
  | undefined
  | 'error'
  | 'network_error'
  // success
  | 'OK'
  // client error
  | 'bad_request'
  | 'unauthorized'
  | 'forbidden'
  | 'not_found'
  | 'method_not_allowed'
  | 'request_timeout'
  | 'conflict'
  | 'gone'
  | 'too_many_requests'
  // server error
  | 'server_error'
  | 'bad_gateway'
  | 'service_unavailable'
  | 'gateway_timeout'
type Response = {
  code: ResponseCode | undefined
  message: ResponseMessage | undefined
  data: unknown | undefined
  error: string | undefined
}

function formatRequestConfig(
  url: string,
  m: RequestMethod,
  data?: unknown,
  q?: RequestQuery,
  options?: InternalOptions,
): AxiosRequestConfig {
  let baseURL: string = ''
  const headers: RawAxiosRequestHeaders = { ...options?.headers }
  if (!headers['Content-Type']) headers['Content-Type'] = 'application/json'
  const transformRequest: AxiosRequestTransformer[] = []
  const impersonate: boolean = options?.impersonate ?? true
  // relative endpoint (URI)
  if (!url.startsWith('http')) {
    // base API server
    baseURL = options?.opossum ? config('app.opossum') : config('app.hippo')
    // URL trailing slash
    if (!url.endsWith('/') && !url.includes('?')) url += '/'
    // auth token
    const token = store.getters['auth/token']
    if (token) headers['Authorization'] = `Bearer ${token}`
    // impersonate
    if (!options?.opossum) {
      const impersonating = store.getters['impersonate/impersonating']
      const impMainEmail = store.getters['impersonate/impersonatorEmail']
      const imp3rdEmail = store.getters['impersonate/email']
      if (impersonate && impersonating && impMainEmail !== imp3rdEmail) headers['X-IMPERSONATE-EMAIL'] = imp3rdEmail
    }
    // "FormData" conversion
    if (options?.useFormData || options?.formData) {
      transformRequest.push((data: Record<string, unknown>) => {
        const fd = new FormData()
        for (const [key, value] of Object.entries(data)) {
          if (Array.isArray(value)) for (const [, subValue] of Object.entries(value)) fd.append(key, subValue)
          else fd.set(key, value as string)
        }
        return fd
      })
    }
  }
  // absolute URL
  else baseURL = ''
  return {
    url,
    method: m.toLowerCase(),
    baseURL,
    params: q || undefined,
    data: data || undefined,
    responseType: options?.responseType || 'json',
    headers,
    transformRequest: transformRequest.length
      ? transformRequest
      : [...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
  }
}

function formatResponseState(code: ResponseCode | undefined): { code: ResponseCode; message: ResponseMessage } {
  const messages: Record<ResponseCode, ResponseMessage> = {
    0: 'network_error',
    200: 'OK',
    400: 'bad_request',
    401: 'unauthorized',
    403: 'forbidden',
    404: 'not_found',
    405: 'method_not_allowed',
    408: 'request_timeout',
    409: 'conflict',
    410: 'gone',
    429: 'too_many_requests',
    500: 'server_error',
    502: 'bad_gateway',
    503: 'service_unavailable',
    504: 'gateway_timeout',
  }
  const message = messages[code as keyof typeof messages]
  return {
    code: code ?? 0,
    message: message ?? messages['0'],
  }
}

async function request<T>(
  url: string,
  m: RequestMethod = 'GET',
  data?: unknown,
  q?: RequestQuery,
  config?: InternalOptions,
): Promise<T> {
  const opts = formatRequestConfig(url, m, data, q, config)
  const response: Response = {
    code: undefined,
    data: undefined,
    message: undefined,
    error: undefined,
  }
  try {
    const res = await axios.request(opts)
    const { code, message } = formatResponseState(res.status as ResponseCode)
    response.code = code
    response.message = message
    response.data = res.data
  } catch (e: unknown) {
    const error = e as AxiosError
    // request OK, response not in 2xx
    if (error.response) {
      const { code, message } = formatResponseState(error.response?.status as ResponseCode)
      response.code = code
      response.message = message
      // auto-retry w/ refresh token if code 401
      if (response.code === 401) {
        if (store.getters['auth/token']) {
          // TODO: manage refresh/logout processes outside of helper
          try {
            await store.dispatch('auth/refreshAccessToken')
            return request(url, m, data, q, config)
          } catch {
            store.dispatch('auth/logout')
          }
        }
      }
      if (response.code >= 500)
        toast({ type: 'error', text: 'Erreur serveur. Merci de réessayer ultérieurement.', delay: 5000 })
    }
    // request OK, no response
    else if (error.request) {
      response.message = 'network_error'
      response.error = error.code
      toast({ type: 'error', text: 'Erreur réseau. Merci de réessayer ultérieurement.', delay: 5000 })
    }
    // other error
    else toast({ type: 'error', text: 'Erreur imprévue. Merci de réessayer ultérieurement.', delay: 5000 })
    let prc: any
    try {
      prc = process
    } catch {
      // JS throws if `process` is undefined => Nothing to do.
    }
    // Throw manually for subsequent try/catch blocks (only in browser env)
    if (!prc && !prc?.env) throw error
  }
  return response.data as T
}

export async function get<T>(url: string, q?: RequestQuery, opts?: UserOptions): Promise<T> {
  return request<T>(url, 'GET', undefined, q, opts)
}
export async function getFile<T>(url: string, q?: RequestQuery, opts?: UserOptions): Promise<T> {
  return request<T>(url, 'GET', undefined, q, {
    responseType: 'blob',
    ...opts,
  })
}
export async function post<T>(url: string, data: unknown, q?: RequestQuery, opts?: UserOptions): Promise<T> {
  return request<T>(url, 'POST', data, q, opts)
}
export async function patch<T>(url: string, data: unknown, q?: RequestQuery, opts?: UserOptions): Promise<T> {
  return request<T>(url, 'PATCH', data, q, opts)
}
export async function put<T>(url: string, data: unknown, q?: RequestQuery, opts?: UserOptions): Promise<T> {
  return request<T>(url, 'PUT', data, q, opts)
}

export async function DELETE<T>(url: string, data?: unknown, q?: RequestQuery, opts?: UserOptions): Promise<T> {
  return request<T>(url, 'DELETE', data, q, opts)
}
