<script setup lang="ts">
import { computed } from 'vue'
import IconClose from './icons/IconClose.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  maxWidth: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', b: boolean): void
}>()

const model = computed({
  get() {
    return props.modelValue
  },
  set(b: boolean) {
    emit('update:modelValue', b)
  },
})

function close() {
  model.value = false
}
</script>

<template>
  <Teleport v-if="model" to="body">
    <div class="overlay">
      <div class="abs" @mousedown.self="close">
        <div class="dialog p-4 s-p-8" :style="{ maxWidth }">
          <IconClose class="corner" @mousedown="close" />
          <slot></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.overlay {
  position: fixed;
  z-index: 1000;
  inset: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  font-family: Roboto;
}

.abs {
  position: absolute;
  inset: 0;
  overflow-y: auto;
  z-index: 1001;
}

.dialog {
  position: relative;
  top: 4%;
  margin: auto;
  background-color: white;
  box-shadow: 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  max-width: min(90%, 800px);
  min-width: 300px;
}

.corner {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
</style>
