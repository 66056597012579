import dayjs from 'dayjs'
import { support } from '@/helpers/env'
import { get } from '@/helpers/http'
import { empty } from '@/helpers/misc'
import { clean } from '@/helpers/objects'
import { storage } from '@/helpers/storage'

export const impersonateModule = {
  state: {
    users: storage.getItem('impersonate.history') ?? [],
    email: null,
    impersonator: null,
    territories: [],
    programs: [],
    history: storage.getItem('impersonate.history') ?? [],
    types: ['professional', 'private'],
  },

  getters: {
    users: (state) => state.users,
    territories: (state) => state.territories,
    groups: (state) => state.impersonator?.groups ?? [],
    programs: (state) => state.programs,
    email: (state) => state.email,
    impersonating: (state) => !!state.email,
    impersonator: (state) => state.impersonator,
    impersonatorEmail: (state) => state.impersonator?.email_address,
    types: (state) => state.types,
  },

  mutations: {
    setEmail(state, email) {
      state.email = email
    },
    setImpersonator(state, user) {
      state.impersonator = user
    },
    setUsers(state, users) {
      state.users = users.map((user) => {
        let name = `${user.first_name} ${user.last_name}`
        if (!user.first_name && !user.last_name) {
          name = user.email
          const index = user.email.indexOf('@')
          if (~index) {
            name = user.email.substring(0, index)
          }
        }
        const f = dayjs().valueOf()
        const to = dayjs(user.last_login).valueOf()
        return {
          ...user,
          name,
          lastLogin: user.last_login ? dayjs(f).to(to) : 'jamais',
        }
      })
    },
    addHistory(state, user) {
      let record = state.history.find((u) => u.email === user.email)
      if (!record) {
        record = user
        state.history.push(user)
      } else {
        // update user data
        record = Object.assign(record, user)
      }
      if (!record.count) {
        record.count = 0
      }
      record.count++
      state.history.sort((a, b) => b.count - a.count)
      state.history = state.history.slice(0, 20)
      storage.setItem('impersonate.history', state.history)
    },
    removeHistory(state, user) {
      let index = state.history.findIndex((u) => u.email === user.email)
      if (~index) {
        state.history.splice(index, 1)
        storage.setItem('impersonate.history', state.history)
      }
      index = state.users.findIndex((u) => u.email === user.email)
      if (~index) {
        state.users.splice(index, 1)
      }
    },
    setTerritories(state, territories) {
      state.territories = territories
    },
    setPrograms(state, programs) {
      state.programs = programs
    },
  },

  actions: {
    async loadImpersonator({ commit, dispatch, rootGetters /*, rootCommit */ }, { email }) {
      const user = await get('me', {}, { impersonate: false })
      if (!user) {
        throw new Error('not_loggued')
      }
      await dispatch('acl/setACL', user, { root: true })
      // not allowed or impersonate yourself
      if (!rootGetters['acl/can']('impersonate') || (user.email_address === email && !support)) {
        await dispatch('acl/clear', {}, { root: true })
        throw new Error('impersonate_not_allowed')
      }
      if (!email && support) {
        email = user.email_address // support
      }
      commit('setEmail', email)
      // clear current user acl
      await dispatch('acl/clear', {}, { root: true })
      commit('setImpersonator', user)
      dispatch('acl/setImpersonator', user, { root: true })
      return user
    },
    async loadUsers(
      { commit, state },
      { search, user_type, program, territory, sensor, email, fisrt_name, last_name } = {},
    ) {
      const query = clean({ search, user_type, program, territory, sensor, email, fisrt_name, last_name })
      if (empty(query) && state.history.length) {
        commit('setUsers', state.history)
        return
      }
      return get('users', query, { impersonate: false }).then((result) => {
        const users = result.results ?? result // TODO: remove this and only keep result.results
        commit('setUsers', users)
      })
    },
    loadTerritories({ commit }) {
      return get('support/territories', {}, { impersonate: false }).then((territories) => {
        commit('setTerritories', territories)
      })
    },
    loadPrograms({ commit }) {
      return get('support/programs', {}, { impersonate: false }).then((programs) => {
        commit('setPrograms', programs)
      })
    },
    clear({ commit, dispatch }) {
      commit('setEmail', null)
      commit('setUsers', [])
      commit('setTerritories', [])
      commit('setPrograms', [])
      commit('setImpersonator', null)
      dispatch('acl/setImpersonator', null, { root: true })
    },
  },

  namespaced: true,
}
