import { DELETE, patch } from '@/helpers/http'
import { empty } from '@/helpers/misc'
import { clean, partial } from '@/helpers/objects'
import Source from '@/models/Source'
import { store } from '@/store/vuex/index'

export const sourcesModule = {
  state: {
    source: null,
    sources: [],
  },

  getters: {
    sourceId: (state) => state.source?.id,
    source: (state) => state.source,
    sources: (state) => state.sources,
    getSource: (state) => (id) => state.sources.find((source) => source.id?.toString() === id?.toString()),
    hasSources: (state) => !!state.sources.length,
    hasSource: (state) =>
      function (id) {
        return !!state.sources.find((source) => source.id?.toString() === id?.toString())
      },
    pairedSources: (state) => state.sources.filter((source) => source.paired).length,
    mainSource: (state) => {
      const sources = state.sources.filter((source) => source.main)
      if (sources.length) {
        return sources[0]
      }
      return null
    },
    /**
     * Get all sources filtered by type
     * @param {array|string} Type/types to filter with
     * @returns
     */
    getSourcesByType: (state) =>
      function (type) {
        const types = Array.isArray(type) ? type : [type]
        return state.sources.filter((source) => types.some((type) => source.isType(type)))
      },
    getSourceById: (state) =>
      function (id) {
        return state.sources.find((source) => source.id?.toString() === id?.toString())
      },
    hasGateway: (state) => !!state.sources.find((source) => source.isGateway()),
    /**
     * List all sources of all sites
     * @returns {sources[]}
     */
    allSources: () => store.getters['sites/allSources'],
    gateways: (state) => state.sources.filter((source) => source.isGateway()),
    hasPmePmi: (state) => state.sources.some((source) => source.hasPmePmi()),
  },

  mutations: {
    setSource(state, source) {
      state.source = source
    },
    set(state, sources) {
      state.sources = sources
    },
    setMain(state, { source, sources }) {
      sources.forEach((source) => {
        source.main = false
      })
      source.main = true
    },
    update(state, source) {
      const model = state.sources.find((s) => s.id?.toString() === source.id?.toString())
      if (!model) {
        console.error('[update] source not found', source)
      }
      model.update(source)
    },
    add(state, source) {
      source = source instanceof Source ? source : new Source(source)
      const index = state.sources.findIndex((s) => s.id?.toString() === source.id?.toString())
      if (~index) {
        state.sources.splice(index, 1, source)
      } else {
        state.sources.push(source)
      }
    },
    edit(state, { source, edit }) {
      if (typeof source !== 'object') {
        source = state.sources.find((s) => s.id?.toString() === source?.toString())
      }
      if (!source) {
        console.error('[edit] source not found:', source)
        return
      }
      source.update(edit)
    },
    remove(state, source) {
      if (typeof source !== 'object') {
        source = state.sources.find((s) => s.id?.toString() === source?.toString())
      }
      if (!source) {
        console.error('[remove] source not found:', source)
        return
      }
      const index = state.sources.indexOf(source)
      if (!~index) {
        console.error('[remove] source not found:', source)
        return
      }
      state.sources.splice(index, 1)
    },
    clear(state) {
      state.sources = []
    },
    final(state) {
      state.sources.forEach((source) => {
        source.update({ final: true })
      })
    },
  },

  actions: {
    setSources({ commit, dispatch }, sources) {
      commit('set', sources)
      return dispatch('clearSource')
    },
    setMainSource({ commit }, { source, sources }) {
      commit('setMain', { source, sources })
      return patch(`sources/${source.id}`, { main: true })
    },
    updateSource({ commit, getters }, { name, indoor }) {
      const id = getters.sourceId
      const source = getters.getSource(id)
      if (!source) {
        console.error('[update] source not found:', id)
        return
      }
      const change = clean({ name, indoor })
      if (empty(change)) {
        console.warn('[update-source] no change to send', { id, name, indoor })
        return
      }
      const oldValue = partial(Object.keys(change), source)
      commit('edit', { source, edit: change })

      return patch(`sources/${id}`, {
        ...change,
      })
        .then((result) => {
          commit('update', result)
          return result
        })
        .catch((error) => {
          commit('edit', { source, edit: oldValue })
          return Promise.reject(error)
        })
    },
    deleteSource({ commit, dispatch, getters }) {
      const id = getters.sourceId
      const source = getters.getSource(id)
      if (!source) {
        console.error('[delete] source not found:', id)
        return
      }
      commit('remove', source)
      // kits depends of sources
      dispatch('kits/clear', {}, { root: true })
      return DELETE(`sources/${id}`)
        .then((result) => {
          return result
        })
        .catch((error) => {
          commit('add', source)
          return Promise.reject(error)
        })
    },
    unpairSource({ getters, commit }) {
      const id = getters.sourceId
      const source = getters.getSource(id)
      if (!source?.paired) {
        console.error('[unpair] source not found:', id)
        return
      }
      commit('edit', { source, edit: { paired: false } })
      return DELETE(`sources/${id}`)
        .then((result) => {
          source.paired = false
          return result
        })
        .catch((error) => {
          commit('edit', { source, edit: { paired: true } })
          return Promise.reject(error)
        })
    },
    clear({ dispatch, commit }) {
      commit('set', [])
      return dispatch('clearSource')
    },
    clearSource({ dispatch }) {
      return dispatch('setCurrentSource', null)
    },
    setCurrentSource({ commit, getters }, source) {
      if (source && typeof source !== 'object') {
        source = getters.getSource(source)
      }
      commit('setSource', source)
      return source
    },
  },

  namespaced: true,
}
