import clone from 'clone'
import { DELETE, get, patch, post } from '@/helpers/http'

/**
 * Alerts Types:
 *  - threshold (ex: sends alert if temperature/humidity/electricity/power is greater/lower than $value)
 *  - device (sends alert if user sensor has problem)
 *  - comfort (sends alert if user sensor indicates an exit from the comfort zone)
 */

function format(alert) {
  if (alert.stream_type) {
    alert.stream_type = alert.stream_type.toLowerCase()
  }
  return alert
}

export const alertsModule = {
  state: {
    alerts: [],
  },

  getters: {
    thresholds: (state) => state.alerts.filter((a) => a.type === 'threshold'),
    materials: (state) => state.alerts.filter((a) => a.type === 'device'),
    hebdoReport: (state) => state.alerts.filter((a) => a.category === 'hebdo_report'),
  },

  mutations: {
    set(state, alerts) {
      state.alerts = alerts.map(format)
    },

    add(state, { alert }) {
      state.alerts.push(format(alert))
    },

    update(state, { alertId, data }) {
      const index = state.alerts.findIndex((alert) => alert.id === alertId)
      if (index > -1) {
        const formatedData = format(data)
        const alert = state.alerts[index]
        state.alerts.splice(index, 1, Object.assign(alert, formatedData))
      }
    },

    delete(state, { alertId }) {
      const index = state.alerts.findIndex((alert) => alert.id === alertId)
      if (index > -1) {
        state.alerts.splice(index, 1)
      }
    },

    clear(state) {
      state.alerts = []
    },
  },

  actions: {
    /**
     * Get all alerts by site id
     * @param {siteId} siteId site id
     * @return void
     * Types:
     *  - threshold (ex: sends alert if temperature/humidity/electricity/power is greater/lower than $value)
     *  - device (sends alert if user sensor has problem)
     *  - comfort (sends alert if user sensor indicates an exit from the comfort zone)
     */
    async loadAlerts({ commit }, { siteId }) {
      const alerts = await get(`alerts/sites/${siteId}/options`)
      commit('set', alerts)
    },

    async updateAlert({ commit, state }, { alertId, data }) {
      const previous = clone(state.alerts.find((a) => a.id === alertId))
      commit('update', { alertId, data })

      if (data.stream_type) {
        data.stream_type = data.stream_type.toUpperCase()
      }

      try {
        const alert = await patch(`alerts/options/${alertId}`, { ...data })
        commit('update', { alertId, data: alert })
      } catch (e) {
        commit('update', { alertId, data: previous })
        return Promise.reject(e)
      }
    },

    async deleteAlert({ commit }, { alertId }) {
      await DELETE(`alerts/options/${alertId}`)
      commit('delete', { alertId })
    },

    async createAlert({ commit }, { data }) {
      const alert = await post('alerts/threshold/', data)
      commit('add', { alert })
    },

    clear({ commit }) {
      commit('clear')
    },
  },

  namespaced: true,
}
