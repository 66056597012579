<template>
  <header class="u-header pt-2" :class="{ impersonate: impersonating, developers }" role="banner">
    <nav role="navigation" aria-label="menu" class="main-nav px-6">
      <button type="button" @click="layoutStore.toggle" :aria-label="$t('navigation.aria_label.open_menu')">
        <IconHamburgerMenu
          v-if="hasNavigation"
          id="burger-menu"
          :size="24"
          data-cy="open-menu"
          role="button"
          aria-controls="side-navigation"
          aria-haspopup="menu"
          :aria-expanded="String(layoutStore.navigationOpen)"
          aria-orientation="vertical"
          aria-hidden="true"
        />
      </button>
      <RouterLink :to="{ name: 'dashboard' }" class="mr-auto mb-2" data-cy="udwi-logo" @click="closeNavigation">
        <img
          v-if="isZeste"
          src="/assets/img/logo-zeste-black.svg"
          :alt="$t('navigation.header.zeste_logo_alt')"
          class="logo"
        />
        <img v-else src="/assets/img/logo-udwi-black.svg" :alt="$t('navigation.header.udwi_logo_alt')" class="logo" />
      </RouterLink>
      <FilterSelector v-if="isSupport && showProgramsAndTerritories" class="ml-8" />
      <SiteSelector v-if="!isSupport && !max('xs') && sites.length && can('dashboard')" :disabled="pageIsInit" />

      <USelect
        align="right"
        class="drop-icon txt-nowrap"
        :aria-label="$t('navigation.aria_label.open_profil')"
        role="button"
      >
        <template #title>
          <IconUser :size="24" />
        </template>
        <UOption>
          <RouterLink
            :to="{ name: 'profile-user' }"
            class="flex-between flex-align-center no-style py-2 px-4"
            @click="closeNavigation"
          >
            {{ $t('navigation.profile') }}
            <IconUser :size="24" />
          </RouterLink>
        </UOption>
        <UOption>
          <a class="flex-between flex-align-center py-2 px-4 no-style" @click="onLogoutClick">
            {{ $t('navigation.logout') }}
            <IconLogout :size="24" class="ml-6" />
          </a>
        </UOption>
      </USelect>

      <USelect
        align="right"
        class="drop-icon txt-nowrap"
        :aria-label="$t('navigation.aria_label.open_help')"
        role="button"
      >
        <template #title>
          <IconContact :size="24" />
        </template>
        <UOption>
          <RouterLink
            :to="{ name: 'faq' }"
            class="flex-between flex-align-center py-2 px-4 no-style"
            @click="closeNavigation"
          >
            {{ $t('navigation.faq') }}
            <IconHelp :size="24" />
          </RouterLink>
        </UOption>
        <UOption>
          <div class="flex-between flex-align-center py-2 px-4" @click="contactSupport">
            {{ $t('navigation.support') }}
            <IconLogout :size="24" class="ml-6" />
          </div>
        </UOption>
      </USelect>

      <RouterLink
        v-if="!isSupport && can('dashboard') && sites.length"
        :to="{ name: 'notifications', params: { siteId } }"
        data-tour="notifications"
        class="icon animate__animated"
        :class="{ animate__swing: hasNew }"
        :aria-label="$t('navigation.aria_label.open_alerts')"
        @click="closeNavigation"
      >
        <IconNotification :size="24" role="button" aria-hidden="true" data-cy="bellIcon" />
        <span
          class="red-dot animate__animated"
          data-cy="redDot"
          :class="{ animate__fadeIn: hasNew, 'opacity-0': !hasNew }"
        />
      </RouterLink>
    </nav>
  </header>
</template>

<script>
import { mapStores } from 'pinia'
import { mapActions, mapGetters } from 'vuex'
import UOption from '@/components/Select/UOption.vue'
import USelect from '@/components/Select/USelect.vue'
import IconContact from '@/components/icons/IconContact.vue'
import IconHamburgerMenu from '@/components/icons/IconHamburgerMenu.vue'
import IconHelp from '@/components/icons/IconHelp.vue'
import IconLogout from '@/components/icons/IconLogout.vue'
import IconNotification from '@/components/icons/IconNotification.vue'
import IconUser from '@/components/icons/IconUser.vue'
import SiteSelector from '@/components/layout/SiteSelector.vue'
import { modal } from '@/components/ui/helpers'
import { logout } from '@/services/user'
import { useLayoutStore } from '@/store/pinia/layout'
import FilterSelector from '@/support/components/layout/FilterSelector.vue'

export default {
  name: 'AppHeader',
  components: {
    SiteSelector,
    USelect,
    UOption,
    FilterSelector,
    IconHamburgerMenu,
    IconNotification,
    IconContact,
    IconLogout,
    IconUser,
    IconHelp,
  },
  props: {
    hasNavigation: {
      type: Boolean,
      required: true,
    },
    isSupport: Boolean,
  },
  data() {
    return {
      loaders: [],
    }
  },
  created() {
    if (this.watchLoading) {
      this.watchLoading('loadingUser')
      this.watchLoading('loadingSites')
    }
    this.loadUser()
    this.loadSites()
  },
  watch: {
    siteId: {
      handler(value) {
        if (!value) return
        if (!this.isSetup) {
          return
        }
        this.loadNotificationUnread({ siteId: this.siteId })
      },
      immediate: true,
    },
    isSetup: {
      handler() {
        if (!this.isSetup) {
          return
        }
        if (!this.site) {
          return
        }
        this.loadNotificationUnread({ siteId: this.siteId })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useLayoutStore),
    ...mapGetters('site', ['siteId']),
    ...mapGetters('sites', ['loadingSites', 'sites', 'hasSites', 'hasSources']),
    ...mapGetters('impersonate', ['impersonating']),
    ...mapGetters('notifications', ['hasNew']),
    ...mapGetters('responsive', ['max', 'min']),
    ...mapGetters('user', ['loadingUser', 'user', 'userId', 'mvp', 'userType', 'isSetup', 'isCompany', 'isZeste']),
    ...mapGetters('acl', ['can', 'admin']),
    showProgramsAndTerritories() {
      return this.min('xs')
    },
    developers() {
      return this.can('developers')
    },
    pageIsInit() {
      return this.$route.fullPath.startsWith('/init')
    },
    loading() {
      return this.loaders.some((computed) => {
        return this[computed]
      })
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('notifications', ['loadNotificationUnread']),
    ...mapActions('user', ['loadUser']),
    ...mapActions('sites', ['loadSites']),
    contactSupport() {
      modal('contact-support')
    },
    closeNavigation() {
      if (this.max('xs') && this.layoutStore.navigationOpen) {
        this.layoutStore.toggle()
      }
    },
    watchLoading(...computeds) {
      computeds.forEach((computed) => {
        if (typeof this[computed] !== 'boolean') {
          throw new Error(`${computed} must be a boolean`)
        }
        this.loaders.push(computed)
      })
    },
    onLogoutClick() {
      logout()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

.animate__swing {
  animation-delay: 1s;
}

.no-style {
  text-decoration: none;
}
.red-dot {
  position: absolute;
  top: 30%;
  left: 55%;
  background-color: red;
  width: 7px;
  border: solid 1px white;
  border-radius: 50%;
  aspect-ratio: 1;
  animation-delay: 1.3s;
}
.flex {
  display: flex;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.logo {
  display: block;
  height: 26px;
}

header {
  background-color: var(--white);
  height: $u-header-height;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0px 4px 8px rgba($dark, 0.12);
  z-index: $zindex-u-nav + 1;
  &.developers {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 7px;
      background-color: $red;
      width: 100%;
      opacity: 1;
      pointer-events: none;
    }
  }

  &.impersonate {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 5px;
      background-color: $red;
      width: 100%;
    }
  }

  @media screen and (max-width: $screen_s) {
    height: $u-header-height-s;
  }

  nav.main-nav {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 24px;
  }
}
.drop-icon :deep(.u-select-list) {
  border: solid 2px var(--grey-light);
  border-radius: 12px;
  translate: 0 4px;
}
</style>
