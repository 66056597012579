<template>
  <ul class="nav-admin">
    <li class="content-title">
      <p>Admin</p>
      <div v-if="!impersonating && admin" :class="{ active: isMobile }" class="switch-view">
        <p class="u-txt-xs pointer" @click="switchRole">Switch to user view</p>
      </div>
    </li>

    <li class="impersonate" @click="onImpersonate" @mouseover="showClose = true" @mouseleave="showClose = false">
      <div class="nav-item impersonate-btn pointer">
        <IconUser :size="24" color="var(--dark)" />
        <p class="u-txt-sm">Impersonate</p>
        <IconClose
          v-if="impersonating && (showClose || isMobile)"
          :size="16"
          :color="closeColor"
          @click.stop="disconnect"
          @mouseover="closeColor = 'var(--dark)'"
          @mouseleave="closeColor = 'var(--grey)'"
        />
      </div>

      <!-- user impersonated infos -->
      <div class="nav-item">
        <p v-if="impersonating" class="u-txt-sm email">{{ email }}</p>
        <ul class="client-info">
          <li>Nom: {{ name }}</li>
          <li>Type: {{ type }}</li>
          <li>Programmes: {{ programs ? programs.join(', ') : '-' }}</li>
          <li>Territoire: {{ userTerritory }}</li>
        </ul>
      </div>
    </li>

    <UModalImpersonate v-if="showModal" v-model="showModal" @close="showModal = false" />
  </ul>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import IconClose from '@/components/icons/IconClose.vue'
import IconUser from '@/components/icons/IconUser.vue'
import UModalImpersonate from '@/components/layout/modals/Impersonate.vue'
import { logout } from '@/services/user'

export default {
  name: 'NavAdmin',
  components: {
    UModalImpersonate,
    IconClose,
    IconUser,
  },
  data() {
    return {
      showModal: false,
      showClose: false,
      closeColor: 'var(--grey)',
    }
  },
  computed: {
    ...mapGetters('impersonate', ['impersonating']),
    ...mapGetters('user', ['user', 'email', 'programs', 'userType', 'territory']),
    ...mapGetters('responsive', ['max']),
    ...mapGetters('acl', ['can', 'admin']),
    name() {
      return this.user?.first_name + ' ' + this.user?.last_name
    },
    type() {
      if (!this.userType) return '-'
      return this.$t('global.' + this.userType)
    },
    userTerritory() {
      return this.territory?.split('_').join(' ') ?? '-'
    },
    isMobile() {
      return this.max('sm')
    },
  },
  methods: {
    ...mapMutations('acl', ['setRole']),
    onImpersonate() {
      this.showModal = true
    },
    disconnect() {
      logout()
    },
    switchRole() {
      this.setRole('USER_ROLE_NAME')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

ul.nav-admin {
  position: fixed;
  width: $u-nav-width - $u-nav-border-width;
  top: $u-header-height;
  background-color: $grey-lighten;
  padding-bottom: 5px;
  border-bottom: 1px solid $grey-light;
  z-index: 2;
  overflow: hidden;

  &:hover {
    .content-title .switch-view {
      opacity: 1;
    }
  }
  .content-title .switch-view.active {
    opacity: 1;
  }

  @media (max-width: $screen_xs) {
    width: 100vw;
    top: $u-header-height-s;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -16px;
    height: 15px;
    width: 100%;
    background: $white;
    background: linear-gradient(180deg, rgba($white, 1) 20%, rgba($white, 0) 100%);
    // border-bottom: 1px solid $grey-light;
  }

  .content-title {
    min-width: 259px;
  }

  .switch-view {
    display: grid;
    grid-auto-flow: column;
    gap: 0.5em;
    align-items: center;
    color: $grey-dark;
    opacity: 0;
    transition: opacity ease-in-out 300ms;
    .u-txt-xs {
      color: currentColor;
      @include PrimaryFontMedium;
    }
    &:hover {
      color: $grey-darken;
    }
  }

  li {
    &.impersonate {
      .nav-item {
        text-decoration: none;
        display: grid;
        gap: 0.8em;
        grid-template-columns: 1fr;
        align-items: center;
        padding: 0.25em $u-nav-padding-x;
        &:first-child {
          grid-template-columns: auto 1fr auto;
        }
        &.impersonate-btn {
          p {
            color: $grey-dark;
            transition: color ease-in-out 300ms;
          }
          &:hover p {
            color: $dark;
          }
        }

        p {
          @include u-txt-sm;
          @include PrimaryFontMedium;
        }

        .client-info {
          font-size: 0.85rem;
          min-width: 259px;
          li {
            text-transform: capitalize;
          }
        }

        .email {
          background-color: red;
          color: white;
          font-weight: bold;
          padding: 0.2em 1em;
        }
      }
    }
  }
}
</style>
