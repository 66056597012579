export const formatNumber = (number, empty = 'hh') => {
  if (number === null) {
    return empty
  }
  return number < 10 ? `0${number}` : `${number}`
}

export default {
  formatNumber,
}
