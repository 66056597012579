import { support } from '@/helpers/env'
import { config } from '@/services/config'
import { store } from '@/store/vuex/index'

/**
 * Copy text to clipboard
 * @param {string} text
 * @returns Promise
 */
export async function copy(text: string): Promise<void> {
  if (!navigator.clipboard) {
    return Promise.reject(new Error("Property 'navigator.clipboard' is not available."))
  }
  return navigator.clipboard.writeText(text)
}
/**
 * Test if object is empty:
 * object: no key
 * array: no element
 * others: return !object
 * @deprecated
 * @param {*} object
 * @returns
 */
export function empty(object: unknown) {
  if (Array.isArray(object)) {
    return !object.length
  }
  if (object === false) {
    return true
  }
  if (object === null) {
    return true
  }
  if (object === undefined) {
    return true
  }
  if (typeof object === 'object') {
    return Object.keys(object).length === 0
  }
  return false
}
/**
 * check if a route allow support visit
 * @deprecated
 * @param {*} route
 * @returns
 */
export function isSupportRoute(route) {
  return route?.route?.meta?.common || route?.route?.meta?.support
}
/**
 * open url in blank target and keep impersonate
 * @deprecated
 * @param {uri} target
 * @returns
 */
export function openBlank(route) {
  let target = route?.href ?? route
  // si non impersonate ou si lien mailto
  if (!store.getters['impersonate/impersonating'] || target.startsWith('mailto:')) {
    window.open(target, '_blank')
    return
  }
  // si lien absolute => vérifier que c'est vers le même site
  if (target.startsWith('http:') || target.startsWith('https:')) {
    if (!target.startsWith(window.location.protocol + '//' + window.location.host)) {
      window.open(target, '_blank')
      return
    }
  }
  // on rajoute l'user en cours en faisant attention aux hash
  let suffix = ''
  let index = target.lastIndexOf('#')
  if (~index) {
    suffix = target.substring(index)
    target = target.substring(0, index)
  }
  if (!target.includes('?')) {
    target += '?'
  }
  index = target.indexOf('?')
  target =
    target.substring(0, index + 1) +
    `user=${encodeURIComponent(store.getters['user/email'])}&` +
    target.substring(index + 1)
  if (route && support) {
    if (!isSupportRoute(route)) {
      target = `${config('app.root')}${target}`
    }
  }
  window.open(target + suffix, '_blank')
}

export function download(blob: BlobPart, name: string): void {
  const url = URL.createObjectURL(new Blob(['\ufeff', blob])) // needed for UTF-8 encoding
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name) // or any other extension
  link.click()
  URL.revokeObjectURL(url)
}
