export const propsIcon = {
  color: {
    type: String,
    default: 'var(--black)',
  },
  size: {
    type: Number,
    default: 24,
  },
}
