/* eslint-disable @typescript-eslint/no-explicit-any */
import clone from 'clone'
import deepEquals from 'fast-deep-equal'
import { empty } from '@/helpers/misc'
import { firstUpperCase } from '@/helpers/strings'

type StoreModule = {
  mutations?: any
  state?: any
  getters?: any
  actions?: any
}

export function wrap(name: string, store: StoreModule = {}) {
  // Clone the wrapped store module with empty state/getters/actions/mutations if not present
  store = clone(store)
  if (!store.actions) store.actions = {}
  if (!store.mutations) store.mutations = {}
  if (!store.state) store.state = {}
  if (!store.getters) store.getters = {}
  if (typeof store.state === 'function') store.state = store.state()

  // Register each wrapped store module actions prefixed with 'load' into keys variable
  const keys = Object.keys(store.actions ?? {}).filter((name) => name.startsWith('load'))

  // If there are actions prefixed with 'load' in the wrapped store module attach loading mutations and clear() action
  if (keys.length) {
    store.mutations.setLoadingState = (state: { [x: string]: unknown }, { name, value }: any) => (state[name] = value)
    store.mutations.setLoadingErrorState = (state: { [x: string]: unknown }, { name, error }: any) =>
      (state[name] = error)
    store.mutations.setLoadingPromise = (state: { [x: string]: unknown }, { name, value, params }: any) => {
      if (value) value.params = params
      state[name] = value
    }
    store.mutations.clearLoading = (state: { [x: string]: boolean | null }, { name }: { name?: string } = {}) => {
      if (name) {
        name = firstUpperCase(name)
        state[`loading${name}`] = false
        state[`_promiseLoading${name}`] = null
        state[`loadingError${name}`] = null
      } else {
        for (const key in state) {
          if (key.startsWith('loadingError')) {
            state[key] = null
          } else if (key.startsWith('_promiseLoading')) {
            state[key] = null
          } else if (key.startsWith('loading')) {
            state[key] = false
          }
        }
      }
    }
    const old = store.actions.clear
    store.actions.clear = (vuexParameters: { commit: any }, parameters: any) => {
      const { commit } = vuexParameters
      commit('clearLoading')
      if (old) return old(vuexParameters, parameters)
    }
  }

  // Add state/getters/actions to the store module for each action prefixed with 'load'
  keys.forEach((key) => wrapAction(name, key, store))

  return {
    [name]: store,
  }
}

function wrapAction(storeName: string, name: string, store: StoreModule) {
  const action = store.actions[name]
  const suffix = firstUpperCase(name.substring(4)) // remove load prefix
  const loadingKey = `loading${suffix}`
  const promiseKey = `_promiseLoading${suffix}`
  const errorKey = `loadingError${suffix}`

  store.state = Object.assign(store.state, {
    [loadingKey]: false,
    [promiseKey]: null,
    [errorKey]: null,
  })

  store.getters = Object.assign(store.getters, {
    [loadingKey]: (state: { [x: string]: any }) => state[loadingKey],
    [errorKey]: (state: { [x: string]: any }) => state[errorKey],
  })

  store.actions[name] = function (vuexParameters: { commit: any; state: any }, params?: { force: unknown }) {
    // Caches the result of the first action call exept {force: true} is used
    const { commit, state } = vuexParameters
    let force = false
    if (params && typeof params === 'object') {
      force = !!params.force
      delete params.force
      if (empty(params)) params = undefined
    }
    // if parameters changed => force reload
    if (!force && state[promiseKey]) {
      if (!deepEquals(params, state[promiseKey].params)) force = true
    }
    if (force) {
      if (state[promiseKey]) commit('setLoadingPromise', { name: promiseKey, value: null })
      if (state[errorKey]) commit('setLoadingErrorState', { name: errorKey, error: null })
    }
    if (state[promiseKey]) return state[promiseKey]
    commit('clearLoading', { name: suffix })
    commit('setLoadingState', { name: loadingKey, value: true })

    let result = action(vuexParameters, params)
    // Make sure the action always returns a promise
    if (!(result instanceof Promise)) {
      console.error(`${storeName}/${name} must return a promise!`)
      result = Promise.resolve(result)
    }
    commit('setLoadingPromise', { name: promiseKey, value: result, params })

    return result.then(
      (data: unknown) => {
        // Handles cuncurrency
        if (state[promiseKey] !== result) {
          if (state[promiseKey] && deepEquals(params, state[promiseKey].params)) return state[promiseKey]
          return data
        }
        commit('setLoadingState', { name: loadingKey, value: false })
        return data
      },
      (error: Error) => {
        if (state[promiseKey] !== result) {
          if (state[promiseKey] && deepEquals(params, state[promiseKey].params)) return state[promiseKey]
          return error
        }
        // Set loading indicator to false on promise resolution
        commit('setLoadingState', { name: loadingKey, value: false })
        commit('setLoadingErrorState', { name: errorKey, error })
        commit('setLoadingPromise', { name: promiseKey, value: null })
        return Promise.reject(error)
      },
    )
  }
}
