import { Ref, onBeforeUnmount, onMounted, ref, watch } from 'vue'

export function useResizeObserver(element: Ref<Element | undefined>) {
  const clientWidth = ref(0)
  const clientHeight = ref(0)

  let htmlElement: Element | null

  const resizeObserver = new ResizeObserver(() => {
    clientWidth.value = htmlElement!.clientWidth
    clientHeight.value = htmlElement!.clientHeight
  })

  function initElement() {
    htmlElement = (element.value as any)?.$el ?? element.value
    if (!htmlElement || !(htmlElement instanceof Element)) {
      // TODO should throw Error !!!!!!!!!!!!!!!!!!!!!!!!
      htmlElement = null
      clientHeight.value = 0
      clientWidth.value = 0
      return
    }

    resizeObserver.observe(htmlElement)
    clientWidth.value = htmlElement.clientWidth
    clientHeight.value = htmlElement.clientHeight
  }

  onMounted(initElement)

  watch(element, () => {
    if (htmlElement) {
      resizeObserver.unobserve(htmlElement)
    }
    initElement()
  })

  onBeforeUnmount(() => {
    if (!htmlElement) {
      return
    }
    resizeObserver.unobserve(htmlElement)
  })

  return {
    clientWidth,
    clientHeight,
  }
}
