<script setup lang="ts">
import { computed, onBeforeUnmount, provide, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import NavItem from '@/components/layout/Navigation/NavItem.vue'
import UModalImpersonate from '@/components/layout/modals/Impersonate.vue'
import { useLayoutStore } from '@/store/pinia/layout'

const { t } = useI18n()

const store = useStore()
const layoutStore = useLayoutStore()

const showModal = ref(false)
const selected = ref({ route: null })
provide('selected', selected)

const navigationOpen = computed(() => layoutStore.navigationOpen)
const admin = computed(() => store.getters['acl/admin'])
const canImpersonate = computed(() => store.getters['user/canImpersonate'])

onBeforeUnmount(() => document.body.classList.remove('nav-open'))

watch(
  () => navigationOpen.value,
  (value: boolean) => {
    const body = document.body
    if (value && !body.classList.contains('nav-open')) body.classList.add('nav-open')
    else body.classList.remove('nav-open')
  },
  { immediate: true },
)
</script>

<template>
  <transition name="slide" :duration="300" :appear="false">
    <nav v-show="navigationOpen" class="u-navigation" data-tour="menu">
      <div class="nav-content" data-cy="user-menu">
        <div v-if="canImpersonate" @click="showModal = true" class="impersonate u-txt-md">impersonate</div>

        <section class="nav-user">
          <ul class="pt-2">
            <NavItem :to="{ name: 'support.installations' }" :label="t('support.nav.installations')" icon="bilan" />
            <NavItem :to="{ name: 'support.gateways' }" :label="t('support.nav.gateways')" icon="network" />
            <NavItem :to="{ name: 'support.sensors' }" :label="t('support.nav.sensors')" icon="equipment" />
            <NavItem :to="{ name: 'support.map' }" :label="t('support.nav.map')" icon="location" />
            <NavItem :to="{ name: 'support.search_devices' }" :label="t('support.nav.search_devices')" icon="search" />
            <NavItem
              v-if="admin"
              :to="{ name: 'support.energy_appointments' }"
              :label="t('support.nav.energy_appointments')"
              icon="plan-action"
            />
          </ul>
        </section>
      </div>

      <UModalImpersonate v-if="showModal" v-model="showModal" @close="showModal = false" />
    </nav>
  </transition>
</template>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

nav {
  position: sticky;
  top: $u-header-height;
  height: calc(100vh - #{$u-header-height});
  width: $u-support-nav-width;
  min-width: $u-support-nav-width;
  background-color: $white;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  border-right: $u-nav-border-width solid rgba($dark, 0.15);
  .impersonate {
    text-align: center;
    padding: 1em;
    cursor: pointer;
  }
  @media (max-width: $screen_md) {
    position: fixed;
    z-index: $zindex-u-nav;
    box-shadow: 2px 0px 4px rgba($dark, 0.12);
  }
  @media (max-width: $screen_xs) {
    width: 100vw;
    top: $u-header-height-s;
  }

  .nav-content {
    height: 100%;
    width: $u-support-nav-width;
    -ms-overflow-style: none;
    @media (max-width: $screen_xs) {
      width: 100vw;
    }

    section.nav-user {
      display: flex;
      flex-direction: column;
      @media (max-width: $screen_xs) {
        // Trick pour afficher la totalité du menu en mobile lorsque si la barre de recherche du navigateur est affichée
        // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
        height: calc(100vh - calc(100vh - 100%));
      }

      ul {
        border-top: 1px solid var(--grey-light);
      }
    }

    :deep(li.content-title) {
      @include u-txt-sm;
      @include PrimaryFontSemiBold;
      color: $grey;
      padding: 1em $u-nav-padding-x 0.4em $u-nav-padding-x;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.slide-enter-active,
  &.slide-leave-active {
    transition:
      width 300ms ease-in-out,
      min-width 300ms ease-in-out;
    .nav-admin {
      transition:
        width 300ms ease-in-out,
        min-width 300ms ease-in-out;
    }
  }
  &.slide-enter,
  &.slide-leave-to {
    width: 0;
    min-width: 0;
    .nav-admin {
      width: 0 !important;
      min-width: 0;
    }
  }

  &.u-navigation::-webkit-scrollbar {
    display: none;
  }
}
</style>

<i18n lang="json">
{
  "fr": {
    "support": {
      "nav": {
        "installations": "Suivi des installations",
        "gateways": "État des passerelles",
        "sensors": "État des capteurs",
        "map": "Carte des installations",
        "search_devices": "Recherche de matériels",
        "energy_appointments": "Suivi des RDV Energie"
      }
    }
  }
}
</i18n>
