import dayjs from 'dayjs'
import { get, patch, post } from '@/helpers/http'

export const campaignsModule = {
  state: {
    campaigns: {
      draft: {},
      started: {},
      closed: [],
    },
    loadingCampaigns: false,
    loadingReport: false,
    report: null,
    count: 0,
  },

  getters: {
    campaigns(state) {
      return state.campaigns
    },
    loadingCampaigns: (state) => state.loadingCampaigns,
    loadingReport: (state) => state.loadingReport,
    draft(state) {
      if (!Object.values(state.campaigns.draft).length) return null
      return state.campaigns.draft
    },
    started(state) {
      if (!Object.values(state.campaigns.started).length) return null
      return state.campaigns.started
    },
    closed(state) {
      if (!state.campaigns.closed.length) return null
      return state.campaigns.closed
    },
    count: (state) => state.count,
    report(state) {
      return state.report
    },
    upFactors(state) {
      if (!state.report) {
        return null
      }
      if (!Object.keys(state.report?.data?.influencing_factors?.up_factors).length) return null
      return state.report?.data?.influencing_factors?.up_factors
    },
    downFactors(state) {
      if (!state.report) {
        return null
      }
      if (!Object.keys(state.report?.data?.influencing_factors?.down_factors).length) return null
      return state.report?.data?.influencing_factors?.down_factors
    },
    surveyEndingDate(state) {
      return dayjs(state.report?.survey_ending_date).format(`DD/MM/YYYY`)
    },
    surveyRemainingDays(state) {
      return state.report?.survey_remaining_days
    },
  },

  mutations: {
    setLoading(state, { name, isLoading }) {
      state[name] = isLoading
    },
    setCampaigns(state, campaigns) {
      const draft = campaigns.filter((c) => c.is_draft)
      const started = campaigns.filter((c) => c.is_active && c.end)
      const closed = campaigns.filter((c) => c.has_ended).sort((a, b) => dayjs(b.end) - dayjs(a.end))

      state.campaigns = {
        draft: draft.length ? draft[0] : {},
        started: started.length ? started[0] : {},
        closed,
      }
    },

    updateCampaignFeedbacks(state, campaigns) {
      campaigns.forEach((campaign) => {
        const index = state.campaigns.closed.findIndex((c) => c.id === campaign.id)
        if (index > -1) {
          state.campaigns.closed[index].report = Object.assign(
            {},
            state.campaigns.closed[index].report,
            campaign.report,
          )
        }
      })
    },

    /**
     * Pre-create fake draft campaign to get more reactive front-end view
     * for the first time the user add a tip to its new campaign
     */
    createFakeDraft(state, { siteId }) {
      state.campaigns.draft = {
        is_draft: true,
        tips: [],
        site: siteId,
        name: '!! Fake draft campaign',
      }
    },

    updateDraftCampaign(state, { campaign }) {
      state.campaigns.draft = Object.assign({}, state.campaigns.draft, campaign, { tips: state.campaigns.draft.tips })
    },
    startCampaign(state, { campaign }) {
      state.campaigns.started = campaign
      state.campaigns.draft = {}
    },
    closeCampaign(state, { campaign }) {
      state.campaigns.closed.unshift(campaign)
      state.campaigns.started = {}
    },

    addTip(state, { tipId }) {
      const tip = state.campaigns.draft.tips.find((id) => id === tipId)
      if (tip) return
      state.campaigns.draft.tips.push(tipId)
    },
    removeTip(state, { tipId }) {
      const index = state.campaigns.draft.tips.findIndex((id) => id === tipId)
      if (index > -1) {
        state.campaigns.draft.tips.splice(index, 1)
        if (!state.campaigns.draft.tips.length) {
          state.campaigns.draft = {}
        }
      }
    },
    setCount(state, count) {
      state.count = count
    },
    setReport(state, report) {
      state.report = report
    },
    clear(state) {
      state.campaigns = {
        draft: {},
        started: {},
        closed: [],
      }
      state.report = null
    },
  },

  actions: {
    async loadCampaigns({ commit }, { siteId, page = 1 }) {
      commit('setLoading', { name: 'loadingCampaigns', isLoading: true })
      const campaigns = await get('action-plan/site/' + siteId, { page })
      commit('setLoading', { name: 'loadingCampaigns', isLoading: false })
      commit('setCampaigns', campaigns.results)
      commit('setCount', campaigns.count)
    },

    /**
     * Updated admin feedback form response
     */
    async refreshCampaignFeedbacks({ commit }, { campaignIds }) {
      const campaigns = await post('action-plan/refresh-answers/', { ids: campaignIds })
      commit('updateCampaignFeedbacks', campaigns)
    },

    async startCampaign({ commit }, { id, siteId, data }) {
      const campaign = await patch('action-plan/' + id, { ...data, site: siteId }, { retry: true })
      commit('startCampaign', { campaign })
    },

    async closeCampaign({ commit }, { id, siteId, data }) {
      const campaign = await patch('action-plan/' + id, { ...data, site: siteId }, { retry: true })
      commit('closeCampaign', { campaign })
    },

    async addTip({ commit, getters }, { siteId, tipId }) {
      if (!getters.draft) {
        commit('createFakeDraft', { siteId })
      }
      commit('addTip', { tipId })

      try {
        const campaign = await post(
          'action-plan/add-to-action-plan/site/' + siteId + '/tips/' + tipId,
          {},
          { retry: true },
        )
        commit('updateDraftCampaign', { campaign })
      } catch (error) {
        if (getters.draft) {
          commit('removeTip', { tipId })
        }
        return Promise.reject(error)
      }
    },

    async removeTip({ commit }, { siteId, tipId }) {
      commit('removeTip', { tipId })

      try {
        await post('action-plan/remove-from-action-plan/site/' + siteId + '/tips/' + tipId, {}, { retry: true })
      } catch (error) {
        commit('addTip', { tipId })
        return Promise.reject(error)
      }
    },

    async loadReport({ commit }, { reportId }) {
      commit('setLoading', { name: 'loadingReport', isLoading: true })
      try {
        const report = await get('action-plan/report/' + reportId)
        commit('setLoading', { name: 'loadingReport', isLoading: false })
        commit('setReport', report)
      } catch (error) {
        commit('setReport', null)
        commit('setLoading', { name: 'loadingReport', isLoading: false })
        return Promise.reject(error)
      }
    },

    clear({ commit }) {
      commit('clearLoading')
      commit('clear')
    },
  },

  namespaced: true,
}
