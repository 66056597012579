import { get, put } from '@/helpers/http'

let unreadTimeout = null

export const notificationsModule = {
  state: {
    unread: 0,
    notifications: [],
    loadingNotifications: false,
    loadingNotificationUnread: false,
    siteId: null,
    count: 0,
  },

  getters: {
    notifications: (state) => state.notifications,
    loadingNotifications: (state) => state.loadingNotifications,
    loadingNotificationUnread: (state) => state.loadingNotificationUnread,
    hasNew: (state) => !!state.unread,
    unread: (state) => state.unread,
    count: (state) => state.count,
  },

  mutations: {
    setLoading(state, { name, isLoading }) {
      state[name] = isLoading
    },
    set(state, notifications) {
      state.notifications = notifications
    },
    setCount(state, count) {
      state.count = count
    },
    setUnread(state, count) {
      state.unread = count
    },
    setSiteId(state, siteId) {
      state.siteId = siteId
    },
    markAsRead(state, notificationId) {
      const notification = state.notifications.find((n) => n.id === notificationId)
      if (!notification) return
      notification.read = true
      if (state.unread) {
        state.unread--
      }
    },
    clear(state) {
      state.unread = 0
      state.notifications = []
    },
  },

  actions: {
    async loadNotifications({ commit, state }, { siteId, page = 1, alert_type }) {
      if (state.siteId !== siteId) {
        commit('setSiteId', siteId)
        commit('clear')
      }

      commit('setLoading', { name: 'loadingNotifications', isLoading: true })
      let notifications = await get(`alerts/sites/${siteId}/notifications`, { page, alert_type })
      commit('setLoading', { name: 'loadingNotifications', isLoading: false })
      const count = notifications.count
      notifications = notifications.results

      notifications = notifications.filter((n) => {
        return n.type !== 'disconnected_sensor'
      }) // notifications template not ready for this types yet
      commit('set', notifications)
      commit('setCount', count)
    },

    async loadNotificationUnread({ state, commit }, { siteId }) {
      if (state.siteId !== siteId) {
        commit('setSiteId', siteId)
        commit('clear')
      }
      if (!siteId) return

      commit('setLoading', { name: 'loadingNotificationUnread', isLoading: true })
      const res = await get(`alerts/sites/${siteId}/notifications/count`)
      commit('setLoading', { name: 'loadingNotificationUnread', isLoading: false })
      commit('setUnread', res.unread_count)
    },
    async markAsRead({ commit }, { notificationId }) {
      await put(`alerts/notifications/${notificationId}`, { read: true })
      commit('markAsRead', notificationId)
    },

    clear({ commit }) {
      if (unreadTimeout) {
        clearTimeout(unreadTimeout)
        unreadTimeout = null
      }
      commit('clear')
    },
  },

  namespaced: true,
}
