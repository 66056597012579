import { events } from './utils/events'

/**
 *
 * @deprecated use UDialog component instead
 * Show modal
 * @param {string} name component name see App.vue#modals for list
 */
export function modal(name, options = {}) {
  const modal = { component: name, options }
  events.emit('modal', modal)
  return modal.promise
}
/**
 *  * @deprecated use UDialog component instead
 * Shorcut to modal('confirm', options)
 */
export function confirm(options) {
  return modal('confirm', options)
}
