<script setup lang="ts">
import { PropType, computed, useSlots } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import AsyncIcon from '@/components/icons/AsyncIcon.vue'
import UCircleLoader from './UCircleLoader.vue'

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'highlighted'
  | 'tertiary'
  | 'tertiary-secondary'
  | 'tip-primary'
  | 'tip-secondary'
  | 'tip-light-primary'
  | 'tertiary-green'
  | 'tip-primary-green'
type ButtonSize = 'small' | 'normal' | 'large' | 'x-large'

const slots = useSlots()

const props = defineProps({
  text: {
    type: String,
  },
  type: {
    type: String as PropType<ButtonType>,
    required: false,
    default: 'primary',
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: 'normal',
  },
  icon: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
  },
  reversed: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  blank: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'click'): void
}>()

const isLink = computed(() => props.to !== undefined)

const iconSize = computed(() => {
  return {
    small: 20,
    normal: 24,
    large: 32,
    'x-large': 40,
  }[props.size]
})

const fontSize = computed(() => (props.size === 'small' ? 'u-txt-sm' : 'u-txt-base'))

const classes = computed(() => {
  return [
    'u-' + props.type,
    props.size,
    fontSize.value,
    { reversed: props.reversed },
    { 'empty-text': !props.text && !slots.default },
  ]
})

const iconItem = computed(() => (props.icon && props.loading ? 'loading' : props.icon))
const isDisabled = computed(() => (props.loading ? true : props.disabled))
</script>

<template>
  <component
    :is="isLink ? 'router-link' : 'button'"
    :to="to"
    class="u-button"
    :class="classes"
    :disabled="isDisabled"
    :aria-disabled="isDisabled"
    data-unit-test="u-button"
    @click="emit('click')"
  >
    <AsyncIcon v-if="iconItem" :name="iconItem" :size="iconSize" data-unit-test="u-button__icon" color="currentColor" />
    <UCircleLoader v-if="!iconItem && loading" :size="iconSize" class="overlay" data-unit-test="u-button__loader" />
    <p v-if="text || $slots.default" data-unit-test="u-button__text">
      <slot>{{ text }}</slot>
    </p>
  </component>
</template>

<style lang="scss" scoped>
@import '@/scss/_imports.scss';

$shadow: #d0bfac;

a,
button.u-button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @include PrimaryFontExtraBold;
  transition: all ease 300ms;
  border-radius: 50px;
  gap: 8px;
  cursor: pointer;

  &.reversed {
    flex-direction: row-reverse;
  }

  &.u-primary,
  &.u-secondary,
  &.u-tertiary,
  &.u-tertiary-secondary,
  &.u-tertiary-green,
  &.u-ghost,
  &.u-highlighted {
    padding: 12px 32px;
    min-height: 48px;
    &.small {
      padding: 8px 24px;
      min-height: 32px;
    }
    &.empty-text {
      padding: 12px 12px;
      &.small {
        padding: 8px 8px;
      }
    }
  }

  &.u-primary {
    background-color: $dark;
    color: $white;
    &:hover:not(:disabled) {
      color: $dark;
      background-color: $cream;
      box-shadow: 0px 4px 8px rgba($shadow, 0.3);
    }
    &:active:not(:disabled) {
      background-color: var(--cream-dark);
    }
    &:disabled {
      background-color: var(--grey-ligh);
      opacity: inherit;
      cursor: default;
    }
  }
  &.u-secondary {
    color: $dark;
    background-color: $cream;
    border: none;
    &:hover:not(:disabled) {
      background-color: $cream-light;
      box-shadow: 0px 4px 8px rgba($shadow, 0.3);
    }
    &:active:not(:disabled) {
      background-color: var(--cream-dark);
    }
    &:disabled {
      background-color: var(--grey-light);
    }
  }
  &.u-ghost {
    color: $dark;
    border: 2px solid currentColor;
    &:hover:not(:disabled) {
      border: 2px solid var(--cream-dark);
    }
    &:active:not(:disabled) {
      background-color: var(--cream-dark);
    }
  }
  &.u-tertiary {
    color: $dark;
    text-decoration: underline;
    &:hover:not(:disabled) {
      background-color: $cream-light;
      box-shadow: 0px 4px 8px rgba($shadow, 0.3);
      text-decoration: none;
    }
    &:active:not(:disabled) {
      background-color: var(--cream-dark);
      text-decoration: none;
    }
  }
  &.u-tertiary-secondary {
    color: var(--green-darken);
    text-decoration: underline;
    &:hover:not(:disabled) {
      background-color: var(--cream-light);
      box-shadow: 0px 4px 8px rgba($shadow, 0.3);
      text-decoration: none;
    }
    &:active:not(:disabled) {
      background-color: var(--cream-dark);
      text-decoration: none;
    }
  }
  &.u-tertiary-green {
    color: var(--white);
    background-color: var(--green-darken);
    &:hover:not(:disabled) {
      background-color: var(--cream-light);
      color: $dark;
      box-shadow: 0px 4px 8px rgba($shadow, 0.3);
      color: $dark;
      text-decoration: none;
    }
    &:active:not(:disabled) {
      background-color: var(--cream-dark);
    }
  }
  &.u-highlighted {
    color: var(--white);
    background-color: var(--green-darken);
    text-decoration: none;
    &:hover:not(:disabled) {
      background-color: var(--green-dark);
      box-shadow: 0px 4px 8px rgba($shadow, 0.3);
      text-decoration: none;
    }
    &:active:not(:disabled) {
      background-color: var(--green-dark);
      text-decoration: none;
    }
  }

  &[class*='tip'] {
    padding: 6px 32px;
    min-height: 40px;
    @media screen and (max-width: $screen_sm) {
      padding: 2px 32px;
      min-height: 32px;
    }
    &.empty-text {
      padding: 6px 10px;
      @media screen and (max-width: $screen_sm) {
        padding: 2px 6px;
      }
    }
  }
  &.u-tip-light-primary {
    color: $white;
    border: 2px solid currentColor;
    &:active {
      color: $white;
      text-decoration: none;
      border: 2px solid $dark;
      background-color: $dark;
    }
  }
  &.u-tip-primary {
    color: $dark;
    border: 2px solid currentColor;
    &:active {
      color: $white;
      text-decoration: none;
      border: 2px solid $dark;
      background-color: $dark;
    }
  }
  &.u-tip-primary-green {
    color: var(--green-darken);
    border: 2px solid var(--green-darken);
    &:active {
      color: $white;
      text-decoration: none;
      border: 2px solid var(--green-darken);
      background-color: var(--green-darken);
    }
  }
  &.u-tip-secondary {
    color: $dark;
    text-decoration: underline;
    border: 2px solid transparent;
    &:active {
      color: $white;
      text-decoration: none;
      border: 2px solid $dark;
      background-color: $dark;
    }
  }

  &:disabled {
    color: $grey;
    opacity: inherit;
    cursor: default !important;
  }

  .u-icon.overlay {
    position: absolute;
  }
  .u-icon.overlay + p {
    opacity: 0.2;
  }
  > p {
    @include PrimaryFontExtraBold;
  }
}
</style>
